// Overview
import IntroPage from 'screens/documentation/overview/intro';
import FoundationPage from 'screens/documentation/overview/foundation';
import ComponentsPage from 'screens/documentation/overview/components';
import ContentPage from 'screens/documentation/overview/content';

// Get Started
import BeginPage from 'screens/documentation/get-started';

// Cosmos
import AboutPage from 'screens/documentation/cosmos/about';

// Content And Writing
import VoiceAndTonePage from 'screens/documentation/content-and-writing/voice-and-tone';
import CoveragePage from 'screens/documentation/content-and-writing/coverage';
import ProductNamingPage from 'screens/documentation/content-and-writing/product-naming';
import StyleTipsPage from 'screens/documentation/content-and-writing/style-tips';
import InternationalizationPage from 'screens/documentation/content-and-writing/internationalization';

// Foundation
import TypographyPage from 'screens/documentation/foundation/typography';
import IconographyPage from 'screens/documentation/foundation/iconography';
import SpacingPage from 'screens/documentation/foundation/spacing';
import ObjectStylePage from 'screens/documentation/foundation/object-style';
import ColorsPage from 'screens/documentation/foundation/colors';

// Components
import AlertPage from 'screens/documentation/components/alert';
import AvatarPage from 'screens/documentation/components/avatar';
import BreadcrumbPage from 'screens/documentation/components/breadcrumb';
import ButtonGroupPage from 'screens/documentation/components/button-group';
import ButtonPage from 'screens/documentation/components/button';
import CardPage from 'screens/documentation/components/card';
import CheckboxPage from 'screens/documentation/components/checkbox';
import CollapsePage from 'screens/documentation/components/collapse';
import ContentLoaderPage from 'screens/documentation/components/content-loader';
import DateTimePickerPage from 'screens/documentation/components/date-time-picker';
import DropdownPage from 'screens/documentation/components/dropdown';
import EmbedPage from 'screens/documentation/components/embed';
import FileUploadPage from 'screens/documentation/components/file-upload';
import ListGroupPage from 'screens/documentation/components/list-group';
import LoadingPage from 'screens/documentation/components/loading';
import ModalPage from 'screens/documentation/components/modal';
import NavigationPage from 'screens/documentation/components/navigation';
import PaginationPage from 'screens/documentation/components/pagination';
import PopoverPage from 'screens/documentation/components/popover';
import ProgressBarPage from 'screens/documentation/components/progress-bar';
import RadioPage from 'screens/documentation/components/radio';
import RangePage from 'screens/documentation/components/range';
import SelectPage from 'screens/documentation/components/select';
import SidebarPage from 'screens/documentation/components/sidebar';
import SwitchPage from 'screens/documentation/components/switch';
import TablePage from 'screens/documentation/components/table';
import TagPage from 'screens/documentation/components/tag';
import TextFieldPage from 'screens/documentation/components/text-field';
import TooltipPage from 'screens/documentation/components/tooltip';
import UtilitiesPage from 'screens/documentation/components/utilities';

// Others
import AccessibilityPage from 'screens/documentation/others/accessibility/accessibility';

export const appRoutes = [
  {
    path: '',
    name: 'Intro',
    component: IntroPage,
  },
  {
    path: '/get-started',
    name: 'Begin',
    component: BeginPage,
  },
  {
    path: '/foundation',
    name: 'Fundamentos',
    component: FoundationPage,
  },
  {
    path: '/foundation/colors',
    name: 'Cores',
    component: ColorsPage,
  },
  {
    path: '/foundation/spacing-and-grid',
    name: 'Espaçamento e Grid',
    component: SpacingPage,
  },
  {
    path: '/foundation/typography',
    name: 'Tipografia',
    component: TypographyPage,
  },
  {
    path: '/foundation/iconography',
    name: 'Iconografia',
    component: IconographyPage,
  },
  {
    path: '/foundation/object-style',
    name: 'Estido de Objetos',
    component: ObjectStylePage,
  },
  {
    path: '/content-and-writing',
    name: 'Conteúdo e Escrita',
    component: ContentPage,
  },
  {
    path: '/content-and-writing/voice-and-tone',
    name: 'Tom e voz',
    component: VoiceAndTonePage,
  },
  {
    path: '/about',
    name: 'O Cosmos',
    component: AboutPage,
  },
  {
    path: '/content-and-writing/coverage',
    name: 'Abrangência',
    component: CoveragePage,
  },
  {
    path: '/content-and-writing/product-naming',
    name: 'Nomes de produtos',
    component: ProductNamingPage,
  },
  {
    path: '/content-and-writing/style-tips',
    name: 'Dicas de estilos',
    component: StyleTipsPage,
  },
  {
    path: '/content-and-writing/internationalization',
    name: 'Internacionalização',
    component: InternationalizationPage,
  },
  {
    path: '/components',
    name: 'Componentes',
    component: ComponentsPage,
  },
  {
    path: '/components/alert-toast',
    name: 'Alert',
    component: AlertPage,
  },
  {
    path: '/components/avatar',
    name: 'Avatar',
    component: AvatarPage,
  },
  {
    path: '/components/breadcrumb',
    name: 'Breadcrumb',
    component: BreadcrumbPage,
  },
  {
    path: '/components/button',
    name: 'Button',
    component: ButtonPage,
  },
  {
    path: '/components/button-group',
    name: 'Button Group',
    component: ButtonGroupPage,
  },
  {
    path: '/components/card',
    name: 'Card',
    component: CardPage,
  },
  {
    path: '/components/checkbox',
    name: 'Checkbox',
    component: CheckboxPage,
  },
  {
    path: '/components/collapse',
    name: 'Collapse',
    component: CollapsePage,
  },
  {
    path: '/components/content-loader',
    name: 'Content Loader',
    component: ContentLoaderPage,
  },
  {
    path: '/components/date-time-picker',
    name: 'Date/Time Picker',
    component: DateTimePickerPage,
  },
  {
    path: '/components/dropdown',
    name: 'Dropdown',
    component: DropdownPage,
  },
  {
    path: '/components/embed',
    name: 'Embed',
    component: EmbedPage,
  },
  {
    path: '/components/file-upload',
    name: 'File Upload',
    component: FileUploadPage,
  },
  {
    path: '/components/list-group',
    name: 'List Group',
    component: ListGroupPage,
  },
  {
    path: '/components/loading',
    name: 'Loading',
    component: LoadingPage,
  },
  {
    path: '/components/modal',
    name: 'Modal',
    component: ModalPage,
  },
  {
    path: '/components/navigation',
    name: 'Navigation',
    component: NavigationPage,
  },
  {
    path: '/components/pagination',
    name: 'Pagination',
    component: PaginationPage,
  },
  {
    path: '/components/popover',
    name: 'Popover',
    component: PopoverPage,
  },
  {
    path: '/components/progress-bar',
    name: 'Progress Bar',
    component: ProgressBarPage,
  },
  {
    path: '/components/radio',
    name: 'Radio',
    component: RadioPage,
  },
  {
    path: '/components/range',
    name: 'Range',
    component: RangePage,
  },
  {
    path: '/components/select',
    name: 'Select',
    component: SelectPage,
  },
  {
    path: '/components/sidebar',
    name: 'Sidebar',
    component: SidebarPage,
  },
  {
    path: '/components/switch',
    name: 'Switch',
    component: SwitchPage,
  },
  {
    path: '/components/table',
    name: 'Table',
    component: TablePage,
  },
  {
    path: '/components/tag',
    name: 'Tag',
    component: TagPage,
  },
  {
    path: '/components/text-field',
    name: 'Text Field',
    component: TextFieldPage,
  },
  {
    path: '/components/tooltip',
    name: 'Tooltip',
    component: TooltipPage,
  },
  {
    path: '/components/utilities',
    name: 'Utilities',
    component: UtilitiesPage,
  },
  {
    path: '/accessibility',
    name: 'Acessibilidade',
    component: AccessibilityPage,
  },
];
