import React from 'react';
import { Route } from 'react-router-dom';

import { PublicErrorBoundary } from 'components/error';

function PublicRoute({ component, ...props }) {
  const Component = component;

  return (
    <Route
      exact={props.exact}
      path={props.path}
      render={() => (
        <PublicErrorBoundary>
          <Component />
        </PublicErrorBoundary>
      )}
    />
  );
}

export default PublicRoute;
