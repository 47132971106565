import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;

  .copy-element {
    flex-grow: 1;
    width: 100%;
  }

  .copy-icon {
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--easing) var(--timing);
    z-index: 1;
  }

  &.copy-inline {
    display: inline-flex;
    flex-direction: row;
  }

  &.copy-absolute {
    .copy-icon {
      position: absolute;
      right: 0;
      top: var(--spacer-2);
    }
  }

  &.copy-white {
    .copy-icon {
      color: var(--white);
    }
  }

  &:hover {
    .copy-icon {
      opacity: 1;
      pointer-events: all;
    }
  }
`;
