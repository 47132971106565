import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-areas: 'render';
  grid-template-columns: 1fr;

  @media screen and (min-width: 1200px) {
    grid-template-areas: 'config render' 'code code';
    grid-template-columns: 200px auto;
    grid-template-rows: auto auto;

    ${({ expandedSpecs }) =>
      expandedSpecs &&
      `
      grid-template-areas: 'config render specs' 'code code code';
      grid-template-columns: 200px auto 200px;

      ${SpecsPanel} {
        display: block;
      }
    `}

    ${({ fullScreen }) =>
      fullScreen &&
      `
      height: 100%;
      grid-template-rows: calc(100% - 192px) 192px;
    `}

    ${({ hasConfigurations }) =>
      !hasConfigurations &&
      `
      grid-template-areas: 'render' 'code';
      grid-template-columns: 1fr;
    `}
  }
`;

export const ConfigurationPanel = styled.div`
  background: #ffffff;
  border-bottom: 1px solid #e6e9ed;
  display: none;
  grid-area: config;
  height: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar-track {
    background-color: $gray-200;
    background-color: var(--gray-200);
    border: 0;
    height: $spacer-2;
    height: var(--spacer-2);
    width: $spacer-2;
    width: var(--spacer-2);
  }

  &::-webkit-scrollbar {
    background-color: $gray-400;
    background-color: var(--gray-400);
    border: 0;
    height: $spacer-2;
    height: var(--spacer-2);
    width: $spacer-2;
    width: var(--spacer-2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-400;
    background-color: var(--gray-400);
    border-radius: $border-radius-sm;
    border-radius: var(--border-radius-sm);
    height: $spacer-2;
    height: var(--spacer-2);
    width: $spacer-2;
    width: var(--spacer-2);
  }

  @media screen and (min-width: 1200px) {
    display: block;

    ${({ hasConfigurations }) =>
      !hasConfigurations &&
      `
      display: none;
    `}
  }
`;

export const RenderPanel = styled.div`
  align-items: center;
  background: #ffffff;
  display: flex;
  grid-area: render;
  height: auto;
  justify-content: center;
  min-height: 500px;
  padding: 16px;
  position: relative;
  transition: 0.3s ease;

  @media screen and (min-width: 1200px) {
    border-bottom: 1px solid #e6e9ed;
    border-left: 1px solid #e6e9ed;
    border-right: 1px solid #e6e9ed;

    hot-card {
      width: 244px;
    }

    hot-progress,
    hot-collapse,
    hot-list-group {
      width: 360px;
    }

    .hot-form__input-range {
      width: 360px;
    }

    .hot-table {
      width: 450px;
    }
  }
`;

export const ComponentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const InteractionPanel = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 16px;
  width: 100%;
  z-index: 2;

  @media screen and (min-width: 1200px) {
    left: 16px;
    width: auto;
  }
`;

export const SpecsPanel = styled.div`
  background: #ffffff;
  display: none;
  grid-area: specs;
  height: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar-track {
    background-color: $gray-200;
    background-color: var(--gray-200);
    border: 0;
    height: $spacer-2;
    height: var(--spacer-2);
    width: $spacer-2;
    width: var(--spacer-2);
  }

  &::-webkit-scrollbar {
    background-color: $gray-400;
    background-color: var(--gray-400);
    border: 0;
    height: $spacer-2;
    height: var(--spacer-2);
    width: $spacer-2;
    width: var(--spacer-2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-400;
    background-color: var(--gray-400);
    border-radius: $border-radius-sm;
    border-radius: var(--border-radius-sm);
    height: $spacer-2;
    height: var(--spacer-2);
    width: $spacer-2;
    width: var(--spacer-2);
  }

  @media screen and (min-width: 1200px) {
    border-bottom: 1px solid #e6e9ed;
  }
`;

export const CodePanel = styled.div`
  background: #e6e9ed;
  border: 1px solid #e6e9ed;
  display: none;
  grid-area: code;
  padding: 24px 32px;
  height: auto;
  max-height: 192px;
  z-index: 10;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar-track {
    background-color: $gray-200;
    background-color: var(--gray-200);
    border: 0;
    height: $spacer-2;
    height: var(--spacer-2);
    width: $spacer-2;
    width: var(--spacer-2);
  }

  &::-webkit-scrollbar {
    background-color: $gray-400;
    background-color: var(--gray-400);
    border: 0;
    height: $spacer-2;
    height: var(--spacer-2);
    width: $spacer-2;
    width: var(--spacer-2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-400;
    background-color: var(--gray-400);
    border-radius: $border-radius-sm;
    border-radius: var(--border-radius-sm);
    height: $spacer-2;
    height: var(--spacer-2);
    width: $spacer-2;
    width: var(--spacer-2);
  }

  @media screen and (min-width: 1200px) {
    display: block;
  }
`;

export const SandBox = styled.section`
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e6e9ed;
  overflow: hidden;
  position: relative;

  ${({ backgroundColor }) =>
    backgroundColor === 'dark' &&
    `
    ${RenderPanel} {
      background-color: var(--gray-600);
    }
  `}

  ${({ fullScreen }) =>
    fullScreen &&
    `
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 100;
  `}
`;
