import React from 'react';
import { WebSelect } from 'web-components';

export default function SandboxSelectVariation({ variation, handleChange }) {
  return (
    <WebSelect
      className="hot-form"
      selectProps={{
        onChange: (e) => handleChange(e, 'select', variation.priority),
        className: 'hot-select',
        id: `variation-${variation.slug}`,
      }}
      labelProps={{
        htmlFor: `variation-${variation.slug}`,
        title: variation.name,
      }}
      options={variation.items.map((item, index) => {
        const optionProps = { value: JSON.stringify(item), title: item.name };

        if (item.selected) {
          optionProps.selected = true;
        }

        return optionProps;
      })}
    />
  );
}
