/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import styleShadowRoot from 'utils/style-shadow-root';

export default function Select({
  className,
  selectProps = {},
  labelProps = {},
  options = [],
}) {
  const ref = useRef();

  useEffect(() => {
    if (!!ref && !!selectProps.onChange) {
      ref.current.addEventListener('change', (e) =>
        selectProps.onChange(e.detail.value),
      );
      styleShadowRoot(ref.current, [
        {
          path: ['.hot-form__input'],
          style: 'border: none;',
        },
      ]);
    }
  }, [ref]);

  return (
    <div className={className}>
      <label
        htmlFor={labelProps.htmlFor}
        {...labelProps}
        className="_text-1 _text-gray-500"
      >
        {labelProps.title}
      </label>
      <hot-select
        className={selectProps.className}
        id={selectProps.id}
        ref={ref}
      >
        {options.map((item, index) => {
          return (
            <hot-select-option key={index} value={item.value} {...item}>
              {item.title}
            </hot-select-option>
          );
        })}
      </hot-select>
    </div>
  );
}
