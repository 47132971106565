import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { astrounautImage, iconPlayImage } from '../intro-images';

import * as S from './intro-banner-style';
import { useSelector } from 'react-redux';

export default function IntroBanner({ content }) {
  const { data: language } = useSelector((state) => state.language);

  return (
    <S.Section>
      <div className="hot-container _px-5 _px-lg-8">
        <S.Content>
          <S.ContentWrapper>
            <S.Wrapper>
              <S.Title>{content.title}</S.Title>
              <S.Text>{content.text}</S.Text>
            </S.Wrapper>
            <S.Actions>
              <S.CardLink as={Link} to="/cosmos/documentation/get-started">
                <S.Card>
                  <S.CardContent>
                    <S.CardIcon
                      backgroundColor="purple-lightest"
                      iconColor={'purple'}
                    >
                      <img src={iconPlayImage} alt="" />
                    </S.CardIcon>
                    <S.CardText>{language.begin.title}</S.CardText>
                  </S.CardContent>
                  <div className="_d-flex _align-items-center _justify-content-center _text-gray-500">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </S.Card>
              </S.CardLink>
              <S.CardLink as={Link} to="/cosmos/documentation/about">
                <S.Card>
                  <S.CardContent>
                    <S.CardIcon
                      backgroundColor="brand-secondary-lightest"
                      iconColor={'brand-secondary-light'}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </S.CardIcon>
                    <S.CardText>{language.cosmos_the_design_system}</S.CardText>
                  </S.CardContent>
                  <div className="_d-flex _align-items-center _justify-content-center _text-gray-500">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </S.Card>
              </S.CardLink>
            </S.Actions>
          </S.ContentWrapper>
          <S.OrnamentWrapper>
            <S.Ornament src={astrounautImage} />
          </S.OrnamentWrapper>
        </S.Content>
      </div>
    </S.Section>
  );
}
