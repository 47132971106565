import React, { useState } from 'react';
import DeveloperPage from './get-started-developer';
import DesignPage from './get-started-design';

import { useSelector } from 'react-redux';

export default function BeginPage() {
  const { data: language } = useSelector((state) => state.language);

  const [selected, setSelected] = useState('developer');

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <h1 className="_font-weight-bold _mb-6">{language.begin.title}</h1>
      <div className="hot-row">
        <div className="hot-col-12 hot-col-sm-9">
          <hot-nav class="hot-nav--tabs _mb-8">
            <hot-nav-item
              class={`${
                selected === 'developer' ? 'hot-nav__item--active' : ''
              }`}
              onClick={() => setSelected('developer')}
            >
              {language.begin.developer.title}
            </hot-nav-item>
            <hot-nav-item
              class={`${selected === 'design' ? 'hot-nav__item--active' : ''}`}
              onClick={() => setSelected('design')}
            >
              {language.begin.design.title}
            </hot-nav-item>
          </hot-nav>
        </div>
      </div>

      {selected === 'developer' && (
        <DeveloperPage developer={language.begin.developer}></DeveloperPage>
      )}

      {selected === 'design' && (
        <DesignPage design={language.begin.design}></DesignPage>
      )}
    </div>
  );
}
