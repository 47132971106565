import React from 'react';

import { WebRadio } from 'web-components';

export default function SandboxRadioVariation({
  component,
  variation,
  handleChange,
  selected,
}) {
  const isChecked = (item) => {
    const selectedProperties = Object.keys(selected);
    let checked = false;

    selectedProperties.map((selectedProperty) => {
      if (
        item.parentSlug === selectedProperty &&
        selected[selectedProperty].slug === item.slug
      ) {
        checked = true;
      }

      return selectedProperty;
    });

    return checked;
  };

  return (
    <>
      {variation.items.map((item, index) => {
        return (
          <WebRadio
            key={index}
            className="hot-form hot-form--custom hot-form--radio"
            inputProps={{
              onChange: () => handleChange(item, 'radio', variation.priority),
              className: 'hot-form__input',
              id: `${variation.slug}-${item.slug}`,
              name: `${component.slug}-${variation.slug}`,
              checked: isChecked(item),
            }}
            labelProps={{
              className: 'hot-form__label',
              htmlFor: `${variation.slug}-${item.slug}`,
              title: item.name,
            }}
          />
        );
      })}
    </>
  );
}
