import React from 'react';
import { useLocation } from 'react-router-dom';
import { Banner, OtherDocumentations } from 'components';

const otherDocumentations = [
  {
    link: '/',
    image: '',
    title: 'Cores',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipin elit, sed do eiusmod tempor incididunt ut.',
  },
];

export default function AccessibilityPage() {
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <h1 className="_font-weight-bold _text-8">Acessibilidade</h1>
      <div className="hot-row">
        <div className="hot-col-md-9">
          <p className="_mb-5 _mb-lg-8">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco.
          </p>
        </div>
      </div>

      <div className="hot-row">
        <div className="hot-col-md-9">
          <Banner link={bannerLink} />
        </div>
      </div>

      <OtherDocumentations documentations={otherDocumentations} />
    </div>
  );
}
