import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  Banner,
  ExampleCard,
  FoundationMenu,
  OtherDocumentations,
  SandBox,
} from 'components';

import {
  ButtonGroupUsageImage,
  ButtonGroupGuidelinesImage,
  ButtonGroupGuidelinesTwoImage,
  ButtonGroupSizesImage,
  ButtonGroupVsDropdownCorrectImage,
  ButtonGroupVsDropdownIncorrectImage,
} from './button-group-images';

export default function ButtonGroupPage(data) {
  const component = data.components.buttonGroup;
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const titles = [
    component.usage.title,
    component.guidelines.title,
    component.sizes.title,
    component.dropdownSplitVsButtonGroup.title,
    component.writing.title,
  ];

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8"> {component.title} </h1>
          <p className="_pb-7 _mb-0">{component.subhead}</p>
        </div>
      </div>

      <div className="hot-row _pt-7 _pb-7">
        <div className="hot-col">
          <SandBox slug="button-group" translates={component} />
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section id={component.usage.title} className="_pt-7 _pb-7">
            <h2 className=" _font-weight-bold _mb-3 _text-4">
              {component.usage.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.usage.text}</p>
            <img
              src={ButtonGroupUsageImage}
              alt=""
              className="_max-w-full _mb-6"
            />
            <p className="_mb-0">{component.usage.secondText}</p>
          </section>

          <section id={component.guidelines.title} className="_pt-7 _pb-7">
            <h2 className=" _font-weight-bold _mb-3 _text-4">
              {component.guidelines.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.guidelines.text}</p>
            <div className="hot-row">
              <div className="hot-col-6">
                <img
                  src={ButtonGroupGuidelinesImage}
                  alt=""
                  className="_max-w-full"
                />
              </div>
              <div className="hot-col-6">
                <img
                  src={ButtonGroupGuidelinesTwoImage}
                  alt=""
                  className="_max-w-full"
                />
              </div>
            </div>
          </section>

          <section id={component.sizes.title} className="_pt-7 _pb-7">
            <h2 className=" _font-weight-bold _mb-3 _text-4">
              {component.sizes.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.sizes.text}</p>
            <img src={ButtonGroupSizesImage} alt="" className="_max-w-full" />
          </section>

          <section
            id={component.dropdownSplitVsButtonGroup.title}
            className="_pt-7 _pb-7"
          >
            <h2 className=" _font-weight-bold _mb-3 _text-4">
              {component.dropdownSplitVsButtonGroup.title}
            </h2>
            <p className="_mb-0 _pb-5">
              {component.dropdownSplitVsButtonGroup.text}
            </p>
            <div className="hot-row">
              <div className="hot-col-6">
                <ExampleCard
                  className="_h-full _pt-0"
                  variation="danger"
                  label={component.dropdownSplitVsButtonGroup.incorrect.tip}
                >
                  <img
                    src={ButtonGroupVsDropdownIncorrectImage}
                    alt=""
                    className="_max-w-full _mb-5"
                  />
                </ExampleCard>
              </div>
              <div className="hot-col-6">
                <ExampleCard
                  className="_h-full _pt-0"
                  variation="success"
                  label={component.dropdownSplitVsButtonGroup.correct.tip}
                >
                  <img
                    src={ButtonGroupVsDropdownCorrectImage}
                    alt=""
                    className="_max-w-full _mb-5"
                  />
                </ExampleCard>
              </div>
            </div>
          </section>

          <section id={component.writing.title} className="_pt-7 _pb-7">
            <h2 className=" _font-weight-bold _mb-3 _text-4">
              {component.writing.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.writing.text}</p>
            <ul className="_pl-4 _mb-5">
              {component.writing.rules.map((rule, index) => (
                <li key={index}> {rule} </li>
              ))}
            </ul>
            <p className="_mb-0">{component.writing.tip}</p>
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={component.otherDocumentations} />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </div>
  );
}
