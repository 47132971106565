import React from 'react';
import Scrollspy from 'react-scrollspy';
import MenuBlock from './foundation-menu-styles';

export default function FoundationMenu({ content }) {
  return (
    <div
      className="_position-sticky _py-8"
      style={{ top: 'var(--structure-header-height)' }}
    >
      <MenuBlock>
        <ul>
          <Scrollspy items={content} currentClassName="active">
            {content.map((item, index) => {
              return (
                <li key={index}>
                  <a href={'#' + item} id={item}>
                    <span>{item}</span>
                  </a>
                </li>
              );
            })}
          </Scrollspy>
        </ul>
      </MenuBlock>
    </div>
  );
}
