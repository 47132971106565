import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  Banner,
  FoundationMenu,
  OtherDocumentations,
  SandBox,
} from 'components';

import {
  datePickerDefaultImage,
  datePickerRangeImage,
  timePickerImage,
} from './date-time-picker-images';

export default function ProgressBarPage(data) {
  const component = data.components.datetimepicker;
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const titles = [component.datePicker.title, component.timePicker.title];

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8"> {component.title} </h1>
          <p className="_pb-7 _mb-0">{component.subhead}</p>
        </div>
      </div>

      <div className="hot-row _pt-7 _pb-7">
        <div className="hot-col">
          <SandBox slug="date-time-picker" translates={component} />
        </div>
      </div>
      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section id={component.datePicker.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.datePicker.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.datePicker.text}</p>

            <article>
              <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                {component.datePicker.default.title}
              </h3>
              <p className="_mb-6">{component.datePicker.default.text}</p>
              <img
                src={datePickerDefaultImage}
                alt=""
                className="_max-w-full _mb-5"
              />
            </article>

            <article>
              <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                {component.datePicker.range.title}
              </h3>
              <p className="_mb-6">{component.datePicker.range.text}</p>
              <img
                src={datePickerRangeImage}
                alt=""
                className="_max-w-full _mb-5"
              />
            </article>
          </section>

          <section id={component.timePicker.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.timePicker.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.timePicker.text}</p>
            <img src={timePickerImage} alt="" className="_max-w-full" />
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={component.otherDocumentations} />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </div>
  );
}
