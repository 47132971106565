import React from 'react';
import { useSelector } from 'react-redux';

import IntroBanner from './intro-banner';
import IntroContent from './intro-content';
import IntroPrinciples from './intro-principles';

export default function Intro() {
  const { data: language } = useSelector((state) => state.language);
  const { banner, content, principles } = language.intro;

  return (
    <>
      <IntroBanner content={banner} />
      <IntroContent content={content} />
      <IntroPrinciples content={principles} />
    </>
  );
}
