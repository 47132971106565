import React, { useEffect } from 'react';

import { WebSelect } from 'web-components';

import RadioVariation from './../sandbox-configuration/sandbox-radio-variation';
import CheckboxVariation from './../sandbox-configuration/sandbox-checkbox-variation';
import SelectVariation from './../sandbox-configuration/sandbox-select-variation';

import * as S from './sandbox-configuration-mobile-styles';

export default function SandboxConfigurationMobile({
  component,
  content,
  handleComponentChange,
  handleVariationChange,
  selectedVariations,
  variations,
  expandedConfiguration,
  setExpandedConfiguration,
  title,
}) {
  const sidebarProps = {};

  const hotSidebar = document.querySelector(
    '#sandboxConfigurationMobileSidebar',
  );

  if (expandedConfiguration) {
    sidebarProps.open = true;
  }

  const _renderComponent = ({ type, ...variation }) => {
    switch (type) {
      case 'radio':
        return (
          <RadioVariation
            component={component}
            variation={variation}
            handleChange={handleVariationChange}
            selected={selectedVariations}
          />
        );

      case 'select':
        return (
          <SelectVariation
            variation={variation}
            handleChange={handleVariationChange}
            selected={selectedVariations}
          />
        );

      case 'checkbox':
        return (
          <CheckboxVariation
            component={component}
            variation={variation}
            handleChange={handleVariationChange}
          />
        );

      default:
        return (
          <SelectVariation
            variation={variation}
            handleChange={handleVariationChange}
            selected={selectedVariations}
          />
        );
    }
  };

  useEffect(() => {
    if (!hotSidebar) {
      return;
    }

    const shadowRoot = hotSidebar.shadowRoot;

    if (!shadowRoot) {
      return;
    }

    const style = document.createElement('style');

    style.innerHTML = `
          :host hot-sidebar-concrete {
            width: 200px;
          }

          hot-sidebar-body {
            overflow-y: auto;
          }
        `;

    shadowRoot.appendChild(style);

    return () => {};
  }, [hotSidebar]);

  useEffect(() => {
    if (!hotSidebar) {
      return;
    }

    hotSidebar.addEventListener('close', () => {
      setExpandedConfiguration(false);
    });
  }, [hotSidebar, setExpandedConfiguration]);

  return (
    <hot-sidebar
      class="hot-sidebar--left"
      id="sandboxConfigurationMobileSidebar"
      {...sidebarProps}
    >
      <hot-sidebar-header>
        <S.LabelWrapper>
          <S.Label> {title} </S.Label>
        </S.LabelWrapper>
      </hot-sidebar-header>
      <hot-sidebar-body style={{ overflowY: 'auto' }}>
        <>
          {content.items.length > 1 && (
            <S.Item>
              <WebSelect
                className="hot-form variation-item"
                selectProps={{
                  className: 'hot-content-select',
                  id: content.slug,
                  onChange: handleComponentChange,
                }}
                labelProps={{
                  htmlFor: content.slug,
                  title: content.name,
                }}
                options={content.items.map((item) => {
                  const optionProps = {
                    value: JSON.stringify(item),
                    title: item.name,
                  };

                  if (item.selected) {
                    optionProps.selected = true;
                  }

                  return optionProps;
                })}
              />
            </S.Item>
          )}

          {variations.map((variation, index) => {
            return <S.Item key={index}>{_renderComponent(variation)}</S.Item>;
          })}
        </>
      </hot-sidebar-body>
    </hot-sidebar>
  );
}
