import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

import { bootstrap } from 'service/auth';

import { Loader } from 'components/loader';
import { PrivateErrorBoundary } from 'components/error';
import { useSelector } from 'react-redux';

function PrivateRoute(props) {
  return (
    <Route
      exact={props.exact}
      path={props.path}
      render={() => <PrivateBootstrap {...props} />}
    />
  );
}

function PrivateBootstrap(props) {
  const { user } = useSelector((state) => state.auth);
  const Component = props.component;

  useEffect(() => {
    bootstrap.start();
  }, []);

  return user ? (
    <PrivateErrorBoundary>
      <Component />
    </PrivateErrorBoundary>
  ) : (
    <Loader />
  );
}

export default PrivateRoute;
