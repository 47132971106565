export const AuthTypes = {
  SET_AUTH: 'SET_AUTH',
};

export const setAuth = (data) => {
  return {
    type: AuthTypes.SET_AUTH,
    payload: data,
  };
};
