import { produce } from 'immer';
import { LanguageTypes } from './actions';

const INITIAL_STATE = {
  localization: '',
  data: null,
};

const Languages = (state = INITIAL_STATE, actions) => {
  return produce(state, (draft) => {
    switch (actions.type) {
      case LanguageTypes.SET_LANGUAGE: {
        const { payload } = actions;
        draft.localization = payload.localization;
        draft.data = payload.data;
        break;
      }

      default:
        break;
    }
  });
};

export default Languages;
