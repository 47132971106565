import React from 'react';
import { Link } from 'react-router-dom';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Section } from './intro-content-style';

import {
  acessibilityImage,
  componentsImage,
  contentImage,
  foundationImage,
} from '../intro-images';

const images = {
  acessibilityImage,
  componentsImage,
  contentImage,
  foundationImage,
};

export default function IntroContent({ content }) {
  return (
    <Section className="_pb-7">
      <div className="hot-container _px-5 _px-lg-8">
        <div className="hot-row">
          {content.cards.map((card, index) => (
            <div className="hot-col-md-4 _mb-6" key={index}>
              <Link
                to={card.link}
                title={card.title}
                className="_text-decoration-none"
              >
                <hot-card class="hot-card--hover _h-full _p-6 _rounded-lg">
                  <hot-card-header class="_d-flex _justify-content-center">
                    <img
                      src={images[card.image]}
                      alt={card.title}
                      className="_max-w-full"
                    />
                  </hot-card-header>
                  <hot-card-body class="">
                    <h2 className="_text-gray-700 _font-weight-bold _text-4 _text-center _mb-4">
                      {card.title}
                    </h2>
                    <p className="_text-gray-500 _mb-0 _text-center _mb-7">
                      {card.text}
                    </p>
                    <div className="_d-flex _align-items-center _justify-content-center _py-4 _text-gray-500">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                  </hot-card-body>
                </hot-card>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}
