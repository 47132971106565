import styled from 'styled-components';

export const Code = styled.code`
  color: #191c1f;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  padding: 0;
`;
