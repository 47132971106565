import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Banner, FoundationMenu, OtherDocumentations } from 'components';
import { WebCard } from 'web-components';
import { Container } from './product-naming-style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';

export default function ProductNamingPage() {
  const { data: language } = useSelector((state) => state.language);

  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const [titles] = useState([
    language.productsName.consciousNames.title,
    language.productsName.descriptiveNames.title,
    language.productsName.linkWithTheBrand.title,
    language.productsName.hotmartForNaming.title,
    language.productsName.platformVsHotmart.title,
    language.productsName.afiliadProducerandCoProducer.title,
    language.productsName.membersArea.title,
    language.productsName.blueprint.title,
    language.productsName.checkoutBuilder.title,
    language.productsName.socialDrm.title,
    language.productsName.hotLinks.title,
    language.productsName.hotlLeads.title,
    language.productsName.listBoss.title,
    language.productsName.lead.title,
  ]);

  return (
    <Container className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8">
            {' '}
            {language.productsName.title}{' '}
          </h1>
          <p className="_pb-7 _mb-0">{language.productsName.subhead}</p>
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section
            className="_pt-7 _pb-7"
            id={language.productsName.consciousNames.title}
          >
            <div className="_mb-5">
              <h2 className="_font-weight-bold _text-4">
                {language.productsName.consciousNames.title}
              </h2>
              <p className="_mb-0">
                {language.productsName.consciousNames.text}
              </p>
            </div>
          </section>

          <section
            className="_pt-7"
            id={language.productsName.descriptiveNames.title}
          >
            <div className="_mb-5">
              <h2 className="_font-weight-bold _text-4">
                {language.productsName.descriptiveNames.title}
              </h2>
              <h3 className="_font-weight-bold _text-2 _mb-3">
                {language.productsName.descriptiveNames.text}
              </h3>
            </div>
            <ul>
              {language.productsName.descriptiveNames.tips.map((tip, index) => (
                <li key={index}>{tip}</li>
              ))}
            </ul>
            <p className="_mb-5">
              {language.productsName.descriptiveNames.examples.text}
            </p>
            <div className="_mb-8">
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {
                          language.productsName.descriptiveNames.examples
                            .correct.text
                        }
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {
                            language.productsName.descriptiveNames.examples
                              .correct.tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {
                          language.productsName.descriptiveNames.examples
                            .incorrect.text
                        }
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {
                            language.productsName.descriptiveNames.examples
                              .incorrect.tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>

          <section className="_mb-7">
            <p className="_mb-5">
              {language.productsName.descriptiveNames.avoid.text}
            </p>
            <div className="_mb-8">
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {
                          language.productsName.descriptiveNames.avoid.correct
                            .text
                        }
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {
                            language.productsName.descriptiveNames.avoid.correct
                              .tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {
                          language.productsName.descriptiveNames.avoid.incorrect
                            .text
                        }
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {
                            language.productsName.descriptiveNames.avoid
                              .incorrect.tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>
          <section
            className="_mb-8"
            id={language.productsName.linkWithTheBrand.title}
          >
            <p className="_mb-5 _font-weight-bold">
              {language.productsName.linkWithTheBrand.title}
            </p>
            <p className="_mb-5">
              {language.productsName.linkWithTheBrand.text}
            </p>
            <div className="hot-row">
              <div className="hot-col-lg-6">
                <WebCard className="correct">
                  <hot-card-header />
                  <hot-card-body>
                    <p className="_text-2 _text-center">
                      {language.productsName.linkWithTheBrand.correct.text}
                    </p>
                  </hot-card-body>
                  <hot-card-footer>
                    <div className="tip-wrapper">
                      <div className="tip-icon">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="_text-white"
                        />
                      </div>
                      <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                        {language.productsName.linkWithTheBrand.correct.tip}
                      </p>
                    </div>
                  </hot-card-footer>
                </WebCard>
              </div>
              <div className="hot-col-lg-6">
                <WebCard className="incorrect _h-full">
                  <hot-card-header />
                  <hot-card-body>
                    <p className="_text-2 _text-center">
                      {language.productsName.linkWithTheBrand.incorrect.text}
                    </p>
                  </hot-card-body>
                  <hot-card-footer>
                    <div className="tip-wrapper">
                      <div className="tip-icon">
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="_text-white"
                        />
                      </div>
                      <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                        {language.productsName.linkWithTheBrand.incorrect.tip}
                      </p>
                    </div>
                  </hot-card-footer>
                </WebCard>
              </div>
            </div>
          </section>
          <section id={language.productsName.hotmartForNaming.title}>
            <p className="_mb-5 _font-weight-bold">
              {language.productsName.hotmartForNaming.title}
            </p>
            <p className="_mb-5">
              {language.productsName.hotmartForNaming.firstText}
            </p>
            <p className="_mb-5 _font-weight-bold">
              {language.productsName.hotmartForNaming.secondText}
            </p>
            <ul className="_mb-5">
              {language.productsName.hotmartForNaming.tips.map((tip, index) => (
                <li key={index}>{tip}</li>
              ))}
            </ul>
            <div className="_pt-7 _pb-7">
              <blockquote>
                {language.productsName.hotmartForNaming.tip}
              </blockquote>
            </div>
          </section>
          <section id={language.productsName.platformVsHotmart.title}>
            <p className="_mb-5 _font-weight-bold">
              {language.productsName.platformVsHotmart.title}
            </p>
            <p className="_mb-5">
              {language.productsName.platformVsHotmart.text}
            </p>

            <div className="_mb-8">
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.productsName.platformVsHotmart.correct.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {language.productsName.platformVsHotmart.correct.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.productsName.platformVsHotmart.incorrect.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {
                            language.productsName.platformVsHotmart.incorrect
                              .tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>
          <section
            id={language.productsName.afiliadProducerandCoProducer.title}
          >
            <p className="_mb-5 _font-weight-bold">
              {language.productsName.afiliadProducerandCoProducer.title}
            </p>
            <p className="_mb-5">
              {language.productsName.afiliadProducerandCoProducer.text}
            </p>

            <div className="_mb-8">
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {
                          language.productsName.afiliadProducerandCoProducer
                            .correct.text
                        }
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {
                            language.productsName.afiliadProducerandCoProducer
                              .correct.tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {
                          language.productsName.afiliadProducerandCoProducer
                            .incorrect.text
                        }
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {
                            language.productsName.afiliadProducerandCoProducer
                              .incorrect.tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>
          <section id={language.productsName.membersArea.title}>
            <p className="_mb-5 _font-weight-bold">
              {language.productsName.membersArea.title}
            </p>
            <p className="_mb-5">{language.productsName.membersArea.text}</p>

            <div className="_mb-8">
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.productsName.membersArea.correct.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {language.productsName.membersArea.correct.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.productsName.membersArea.incorrect.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {language.productsName.membersArea.incorrect.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>
          <section className="_mb-7" id={language.productsName.blueprint.title}>
            <p className="_mb-5 _font-weight-bold">
              {language.productsName.blueprint.title}
            </p>
            <p className="_mb-5">{language.productsName.blueprint.text}</p>
            <div className="_mb-8">
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.productsName.blueprint.correct.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {language.productsName.blueprint.correct.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.productsName.blueprint.incorrect.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {language.productsName.blueprint.incorrect.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>
          <section id={language.productsName.checkoutBuilder.title}>
            <p className="_mb-5 _font-weight-bold">
              {language.productsName.checkoutBuilder.title}
            </p>
            <p className="_mb-5">
              {language.productsName.checkoutBuilder.text}
            </p>
            <div className="_mb-8">
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.productsName.checkoutBuilder.correct.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {language.productsName.checkoutBuilder.correct.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.productsName.checkoutBuilder.incorrect.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {language.productsName.checkoutBuilder.incorrect.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>
          <section className="_mb-7" id={language.productsName.socialDrm.title}>
            <p className="_mb-5 _font-weight-bold">
              {language.productsName.socialDrm.title}
            </p>
            <p className="_mb-5">{language.productsName.socialDrm.text}</p>
            <div className="_mb-8">
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.productsName.socialDrm.correct.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {language.productsName.socialDrm.correct.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.productsName.socialDrm.incorrect.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {language.productsName.socialDrm.incorrect.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>
          <section className="_mb-7" id={language.productsName.hotLinks.title}>
            <p className="_mb-5 _font-weight-bold">
              {language.productsName.hotLinks.title}
            </p>
            <p className="_mb-5">{language.productsName.hotLinks.text}</p>
            <div className="_mb-8">
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.productsName.hotLinks.correct.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {language.productsName.hotLinks.correct.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.productsName.hotLinks.incorrect.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {language.productsName.hotLinks.incorrect.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>
          <section className="_mb-7" id={language.productsName.hotlLeads.title}>
            <p className="_mb-5 _font-weight-bold">
              {language.productsName.hotlLeads.title}
            </p>
            <p className="_mb-5">{language.productsName.hotlLeads.text}</p>
            <div className="_mb-8">
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.productsName.hotlLeads.correct.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {language.productsName.hotlLeads.correct.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.productsName.hotlLeads.incorrect.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {language.productsName.hotlLeads.incorrect.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>
          <section className="_mb-7" id={language.productsName.listBoss.title}>
            <p className="_mb-5 _font-weight-bold">
              {language.productsName.listBoss.title}
            </p>
            <p className="_mb-5">{language.productsName.listBoss.text}</p>
            <div className="_mb-8">
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.productsName.listBoss.correct.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {language.productsName.listBoss.correct.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.productsName.listBoss.incorrect.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {language.productsName.listBoss.incorrect.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>

          <section className="_pb-7" id={language.productsName.lead.title}>
            <p className="_mb-5 _font-weight-bold">
              {language.productsName.lead.title}
            </p>
            <p className="_mb-5">{language.productsName.lead.text}</p>
            <div className="_mb-0">
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.productsName.lead.correct.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {language.productsName.lead.correct.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.productsName.lead.incorrect.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {language.productsName.lead.incorrect.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations
            documentations={language.productsName.otherDocumentations}
          />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </Container>
  );
}
