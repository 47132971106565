import { all, takeLatest, call, put } from 'redux-saga/effects';
import { LanguageTypes, setLanguage } from './actions';
import { api } from 'service/api';

function* getLanguage({ payload }) {
  try {
    const { data } = yield call(
      api.get,
      `${process.env.PUBLIC_URL}/mock/language/${
        payload || navigator.language.toLocaleLowerCase()
      }.json`,
    );
    yield put(setLanguage({ data, localization: payload }));
  } catch (err) {}
}

export default all([takeLatest(LanguageTypes.GET_LANGUAGE, getLanguage)]);
