import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  Banner,
  FoundationMenu,
  OtherDocumentations,
  CopyPasteContainer,
} from 'components';

function returnContent(title, value, item) {
  let content = undefined;
  switch (title) {
    case 'Body':
      content = <p className={'_' + value.code}>{item.text}</p>;
      break;
    case 'Heading':
      content = <h6 className={'_' + value.code}>{item.text}</h6>;
      break;
    case 'Display':
      content = <h6 className={'_' + value.code}>{item.text}</h6>;
      break;
    default:
      break;
  }
  return content;
}

export default function TypographyPage() {
  const { data: language } = useSelector((state) => state.language);

  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const typography = language.typography;
  const scale = typography.scale;
  const scaleItems = scale.items;
  const familyTypography = typography.familyTypography;
  const font = typography.font;
  const line = typography.line;
  // const link = typography.link;
  // const utitlities = typography.utilities;
  const otherDocumentations = typography.otherDocumentations;

  const titles = [scale.title, familyTypography.title, font.title, line.title];
  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col-7">
          <h1 className="_font-weight-bold _text-8"> {typography.title} </h1>
          <p className="_pb-7 _mb-0">{typography.subtitle}</p>
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <div className="_pt-7 _pb-7" id={scale.title}>
            <h2 className="_font-weight-bold _text-4 _mb-8">{scale.title}</h2>
            {scaleItems.map((item, index) => {
              return (
                <div className="_mb-8" key={index}>
                  <h3 className="_font-weight-bold _text-2">{item.title}</h3>
                  <p>{item.subtitle}</p>
                  {item.content.map((value, index) => {
                    return (
                      <hot-card class="_mb-1 _rounded-lg" key={index}>
                        <hot-card-body>
                          <div className="hot-row">
                            <div className="hot-col-md-8">
                              <p className="_font-weight-bold _mb-5 _mb-lg-8">
                                {value.title}
                              </p>
                              <div>
                                {returnContent(item.title, value, item)}
                              </div>
                              <div>
                                <CopyPasteContainer
                                  iconInlinePosition
                                  key={index}
                                  content={`--${value.code}`}
                                >
                                  <code className="_text-nowrap">
                                    {'--' + value.code}
                                  </code>
                                </CopyPasteContainer>
                              </div>
                            </div>
                            <div className="hot-col-md-4">
                              <div className="hot-row">
                                <div className="hot-col-6 hot-col-md-12">
                                  <small className="_mb-0 _text-gray-400">
                                    Size
                                  </small>
                                  <p className="_font-weight-bold">
                                    {value.size}
                                  </p>
                                </div>
                                <div className="hot-col-6 hot-col-md-12">
                                  <small className="_mb-0 _text-gray-400">
                                    Weight
                                  </small>
                                  <p className="_font-weight-bold">
                                    {value.weight}
                                  </p>
                                </div>
                                <div className="hot-col-6 hot-col-md-12">
                                  <small className="_mb-0 _text-gray-400">
                                    Line height
                                  </small>
                                  <p className="_font-weight-bold">
                                    {value.line}
                                  </p>
                                </div>
                                <div className="hot-col-6 hot-col-md-12">
                                  <small className="_mb-0 _text-gray-400">
                                    Letter spacing
                                  </small>
                                  <p className="_font-weight-bold _mb-0">
                                    {value.letter}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </hot-card-body>
                      </hot-card>
                    );
                  })}
                </div>
              );
            })}
          </div>

          <div className="_pt-7 _pb-7" id={familyTypography.title}>
            <h2 className="_font-weight-bold _text-4">
              {familyTypography.title}
            </h2>
            <p>{familyTypography.subtitle}</p>

            <div
              className="_d-flex _align-items-center _justify-content-center"
              style={{ userSelect: 'none' }}
            >
              <h6
                className="_font-weight-bold _mr-4"
                style={{ fontSize: '15rem' }}
              >
                Aa
              </h6>
              <div>
                <h6 className="_font-weight-bold _text-8">
                  {familyTypography.content}
                </h6>
                <p>
                  <span className="_text-4 _font-weight-light _mr-4">
                    Light 300
                  </span>
                  <span className="_text-4 _font-weight _mr-4">
                    Regular 400
                  </span>
                  <span className="_text-4 _font-weight-bold _mr-4">
                    Bold 700
                  </span>
                </p>
              </div>
            </div>

            <hot-collapse>
              {familyTypography.collapse}
              <div slot="collapsed">
                <table className="hot-table hot-table--card _mb-0">
                  <thead>
                    <tr>
                      <th>Classe CSS</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="_bg-white">
                        <CopyPasteContainer
                          iconInlinePosition
                          content="--font-family"
                        >
                          <code className="_text-nowrap">--font-family</code>
                        </CopyPasteContainer>
                      </td>
                      <td className="_bg-white">
                        "Nunito Sans", -apple-system, blinkmacsystemfont, "Segoe
                        UI", helvetica, arial, sans-serif, "Apple Color Emoji",
                        "Segoe UI Emoji", "Segoe UI Symbol"
                      </td>
                    </tr>
                    <tr>
                      <td className="_bg-white">
                        <CopyPasteContainer
                          iconInlinePosition
                          content="--font-family-monospace"
                        >
                          <code className="_text-nowrap">
                            --font-family-monospace
                          </code>
                        </CopyPasteContainer>
                      </td>
                      <td className="_bg-white">
                        sfmono-regular, menlo, monaco, consolas, "Liberation
                        Mono", "Courier New", monospace
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </hot-collapse>
          </div>

          <div className="_pt-7 _pb-7" id={font.title}>
            <h2 className="_font-weight-bold _text-4">{font.title}</h2>
            <table className="hot-table hot-table--card">
              <thead>
                <tr>
                  <th>{font.table.class}</th>
                  <th>{font.table.value}</th>
                  <th>{font.table.example}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer
                      iconInlinePosition
                      content="--font-weight-light"
                    >
                      <code className="_text-nowrap">--font-weight-light</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">Light 300</td>
                  <td className="_bg-white">
                    <span className="_font-weight-light">{font.text}</span>
                  </td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer
                      iconInlinePosition
                      content="--font-weight"
                    >
                      <code className="_text-nowrap">--font-weight</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">Regular 400</td>
                  <td className="_bg-white">
                    <span className="_font-weight">{font.text}</span>
                  </td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer
                      iconInlinePosition
                      content="--font-weight-bold"
                    >
                      <code className="_text-nowrap">--font-weight-bold</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">Bold 700</td>
                  <td className="_bg-white">
                    <span className="_font-weight-bold">{font.text}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="_pt-7 _pb-7" id={line.title}>
            <h2 className="_font-weight-bold _text-4">{line.title}</h2>
            <table className="hot-table hot-table--card _mb-0">
              <thead>
                <tr>
                  <th>{line.table.class}</th>
                  <th>{line.table.value}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer
                      iconInlinePosition
                      content="--font-weight-bold"
                    >
                      <code className="_text-nowrap">--line-height-sm</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">100%</td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer
                      iconInlinePosition
                      content="--line-height"
                    >
                      <code className="_text-nowrap">--line-height</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">120%</td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer
                      iconInlinePosition
                      content="--line-height-lg"
                    >
                      <code className="_text-nowrap">--line-height-lg</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">150%</td>
                </tr>
              </tbody>
            </table>
          </div>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={otherDocumentations} />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </div>
  );
}
