import React from 'react';

export default function Checkbox({
  className,
  inputProps = {},
  labelProps = {},
}) {
  return (
    <div className={className}>
      <input
        type="checkbox"
        onChange={inputProps.onChange}
        className={inputProps.className}
        id={inputProps.id}
        name={inputProps.name}
        {...inputProps}
      />
      <label
        className={labelProps.className}
        htmlFor={labelProps.htmlFor}
        {...labelProps}
      >
        {labelProps.title}
      </label>
    </div>
  );
}
