import styled from 'styled-components';

import doIcon from 'assets/images/icons/do.svg';
import dontIcon from 'assets/images/icons/dont.svg';

export const Container = styled.div`
  hot-card {
    &.correct,
    &.incorrect {
      border-bottom-width: 8px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &.correct {
      border-bottom-color: var(--green);
      .card-tip {
        &::before {
          background-image: url(${doIcon});
        }
      }
    }

    &.incorrect {
      border-bottom-color: var(--red);
      .card-tip {
        &::before {
          background-image: url(${dontIcon});
        }
      }
    }

    .card-text {
      text-align: center;
    }

    hot-card-footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .card-tip {
      align-items: center;
      display: inline-flex;
      justify-content: center;
      padding-left: 32px;
      position: relative;
      text-align: center;

      &::before {
        content: '';
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 100%;
        height: 20px;
        left: 0;
        position: absolute;
        top: 0;
        width: 20px;
      }
    }

    hot-card-body {
      padding: var(--spacer-5);
    }
  }
`;
