import React, { useCallback } from 'react';
import { copyToClipboard, callToast } from 'utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';

import { Container } from './copy-paste-container-styled';

export default function CopyPasteContainer({
  children,
  withoutIcon,
  iconInlinePosition,
  iconAbsolutePosition,
  colorWhite,
  content = '',
  className = '',
  renderToastCopiedText = true,
  toastVariation,
}) {
  const handleCopy = useCallback(() => {
    copyToClipboard(content);

    if (renderToastCopiedText) {
      callToast(
        'hot-toast',
        {
          message: `Conteúdo copiado: ${content}`,
          position: 'top right',
        },
        toastVariation,
      );

      return;
    }

    callToast(
      'hot-toast',
      {
        message: `Conteúdo copiado`,
        position: 'top right',
      },
      toastVariation,
    );
  }, [content, renderToastCopiedText, toastVariation]);

  return (
    <Container
      className={`
      ${iconAbsolutePosition ? 'copy-absolute ' : ''}
      ${iconInlinePosition ? 'copy-inline ' : ''}
      ${colorWhite ? 'copy-white ' : ''}
      ${className}`}
      onClick={handleCopy}
    >
      <div className="copy-element">{children}</div>
      {!withoutIcon && (
        <FontAwesomeIcon icon={faCopy} className="_mx-2 copy-icon" />
      )}
      <hot-toast class="global-toast" />
    </Container>
  );
}
