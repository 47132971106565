import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { JsonSidebar, getPropValueByString } from 'utils';
import { authService } from 'service/auth';
import * as LanguageActions from 'store/modules/language/actions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSignOut } from '@fortawesome/pro-regular-svg-icons';

import '@hotmart/cosmos/dist/menu';
import '@hotmart/cosmos/dist/menu-item';
import '@hotmart/cosmos/dist/header';

const languages = [
  {
    name: 'Português',
    localization: 'pt_BR',
  },
  {
    name: 'English',
    localization: 'en',
  },
  // {
  //   name: 'Español',
  //   localization: 'es',
  // },
  // {
  //   name: 'Français',
  //   localization: 'fr',
  // },
];

function Sidebar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [width] = useState(window.innerWidth);
  const [props, setProps] = useState({});

  const { localization, data: languageSource } = useSelector(
    (state) => state.language,
  );
  const activeLanguage = useMemo(() => {
    if (localization) {
      return languages.find(
        (language) => language.localization === localization,
      );
    }

    return {
      name: 'Português',
      localization: 'pt_BR',
    };
  }, [localization]);

  const changeLanguage = useCallback(
    (lang) => {
      dispatch(LanguageActions.getLanguage(lang));
    },
    [dispatch],
  );

  useEffect(() => {
    const isMobile = width <= 1200;

    if (isMobile) {
      setProps({});
      return;
    }

    setProps({
      open: true,
    });

    return () => {};
  }, [width]);

  return (
    <hot-menu
      slot="menu"
      class="hot-menu hot-application--pinned hot-application--no-product-name"
      {...props}
    >
      <div slot="product-name">Cosmos Design System</div>
      <hot-header slot="header" class="hot-application--animated-logo">
        <Link slot="logo-link" to="/cosmos/documentation" className="logo-link">
          Hotmart
        </Link>
        <div className="_d-flex _mr-3" slot="header-actions">
          <hot-dropdown>
            <hot-menu-item slot="button">{activeLanguage.name}</hot-menu-item>

            {languages.map((language, index) => (
              <hot-dropdown-menu-item
                slot="menu"
                onClick={() => changeLanguage(language.localization)}
                key={index}
              >
                {language.name}
              </hot-dropdown-menu-item>
            ))}
          </hot-dropdown>
          <hot-menu-item
            onClick={() => {
              authService.signoutRedirect();
            }}
          >
            <FontAwesomeIcon icon={faSignOut} className="fa-lg" />
          </hot-menu-item>
        </div>
      </hot-header>
      {JsonSidebar.map((content, index) => {
        const menuItemProps = {};

        if (content.url === location.pathname) {
          menuItemProps.active = 'true';
        }

        return content.items ? (
          <hot-menu-item key={index}>
            {getPropValueByString(languageSource, content.name)}
            {content.items.map((subItem, index) => {
              const subItemProps = {};

              if (subItem.url === location.pathname) {
                subItemProps.active = 'true';
              }

              return (
                <NavLink
                  className="nav-link__submenu"
                  slot="collapsed"
                  to={subItem.url}
                  key={index}
                  exact
                >
                  <hot-menu-item {...subItemProps}>
                    <span>
                      {getPropValueByString(languageSource, subItem.name)}
                    </span>
                  </hot-menu-item>
                </NavLink>
              );
            })}
          </hot-menu-item>
        ) : (
          <NavLink
            to={content.url}
            key={index}
            className="nav-link"
            activeClassName="active"
            exact
          >
            <hot-menu-item key={index} {...menuItemProps}>
              {getPropValueByString(languageSource, content.name)}
            </hot-menu-item>
          </NavLink>
        );
      })}
    </hot-menu>
  );
}

export default Sidebar;
