import styled from 'styled-components';

export const BannerSection = styled.div`
  background: linear-gradient(270deg, #98a4ae, 50%, #e8ecf0);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 0;
`;

export const BannerText = styled.p`
  color: var(--gray-900);
  text-align: center;
  max-width: 400px;
`;

export const BannerOrnamentWrapper = styled.div`
  width: calc(100% + 150px);
  margin-left: -75px;
  margin-top: 0;

  @media screen and (min-width: 992px) {
    margin-top: -100px;
  }

  @media screen and (min-width: 1400px) {
    margin-top: -200px;
  }
`;

export const BannerOrnament = styled.img`
  max-width: 100%;
`;
