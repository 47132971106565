import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 250px 250px;
  grid-template-rows: 86px 86px;
  gap: 24px;
`;

export const Card = styled.div`
  background-color: var(--white);
  border-radius: 8px;
  padding: 16px 24px 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardLink = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ImageWrapper = styled.div`
  background: var(--brand-primary-lightest);
  border-radius: 50%;
  height: 54px;
  width: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;

  svg {
    height: 24px !important;
    width: 24px !important;
    color: var(--brand-primary);
  }
`;

export const Text = styled.p`
  margin: 0;
  color: var(--gray-600);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
`;

export const Icon = styled.div`
  height: 10px;
  width: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.00364 0.37565V0.646872C1.00364 0.867843 1.27903 1 1.5 1H9.38319L0.959445 9.30893C0.804765 9.46361 0.826862 9.70668 0.959445 9.83926L1.18042 10.0602C1.3351 10.2149 1.55607 10.2149 1.71075 10.0602L10.0192 1.744V9.62538C10.0192 9.84635 10.1739 10.001 10.3949 10.001H10.6439C10.8649 10.001 11.0195 9.84635 11.0195 9.62538V0.37565C11.0195 0.15468 10.8649 0 10.6439 0H1.37929C1.15832 0 1.00364 0.15468 1.00364 0.37565Z' fill='%235981E3'/%3E%3C/svg%3E%0A");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
`;
