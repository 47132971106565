import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import * as images from './other-documentations-images';

function OtherDocumentations({ documentations }) {
  const { data: language } = useSelector((state) => state.language);
  return (
    <div className="_pt-7 _pb-7">
      <h2 className="_font-weight-bold _mb-8 _text-4">
        {language.otherDocumentations}
      </h2>
      <div className="hot-row">
        {documentations.map((item, index) => (
          <div
            className="hot-col-12 hot-col-md-6 hot-col-xl-4 _mb-5 _mb-xl-0"
            key={index}
          >
            <hot-card class="hot-card--hover _h-full _rounded-lg">
              <hot-card-header class="_d-flex _align-items-center _justify-content-center _bg-white _p-0">
                <img
                  src={images[item.image]}
                  alt={item.title}
                  className="_max-w-full"
                />
              </hot-card-header>
              <hot-card-body class="_p-5">
                <h6 className="_font-weight-bold">
                  <Link
                    to={item.link}
                    className="_stretched-link _text-decoration-none _text-gray-700"
                  >
                    {item.title}
                  </Link>
                </h6>
                <p className="_mb-0 _text-1 _text-gray-500">{item.text}</p>
              </hot-card-body>
            </hot-card>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OtherDocumentations;
