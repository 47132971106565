function callToast(
  selector = 'hot-toast',
  params = { message: '', position: 'top right' },
  variation = 'custom',
) {
  const hotToast = document.querySelector(selector);
  if (hotToast) {
    switch (variation) {
      case 'info':
        hotToast.info(params);
        break;
      case 'success':
        hotToast.success(params);
        break;
      case 'danger':
        hotToast.danger(params);
        break;
      case 'warning':
        hotToast.warning(params);
        break;
      case 'custom':
        hotToast.custom(params);
        break;
      default:
        hotToast.info(params);
        break;
    }
  }
}

export default callToast;
