import React from 'react';

import { WebSelect } from 'web-components';

import RadioVariation from './sandbox-radio-variation';
import CheckboxVariation from './sandbox-checkbox-variation';
import SelectVariation from './sandbox-select-variation';

import * as S from './sandbox-configuration-styles';

export default function SandboxConfiguration({
  component,
  content,
  handleComponentChange,
  handleVariationChange,
  selectedVariations,
  variations,
}) {
  const _renderComponent = ({ type, ...variation }) => {
    switch (type) {
      case 'radio':
        return (
          <RadioVariation
            component={component}
            variation={variation}
            handleChange={handleVariationChange}
            selected={selectedVariations}
          />
        );

      case 'select':
        return (
          <SelectVariation
            variation={variation}
            handleChange={handleVariationChange}
            selected={selectedVariations}
          />
        );

      case 'checkbox':
        return (
          <CheckboxVariation
            component={component}
            variation={variation}
            handleChange={handleVariationChange}
          />
        );

      default:
        return (
          <SelectVariation
            variation={variation}
            handleChange={handleVariationChange}
            selected={selectedVariations}
          />
        );
    }
  };

  return (
    <>
      {content.items.length > 1 && (
        <S.Item>
          <WebSelect
            className="hot-form variation-item"
            selectProps={{
              className: 'hot-content-select',
              id: content.slug,
              onChange: handleComponentChange,
            }}
            labelProps={{
              htmlFor: content.slug,
              title: content.name,
            }}
            options={content.items.map((item) => {
              const optionProps = {
                value: JSON.stringify(item),
                title: item.name,
              };

              if (item.selected) {
                optionProps.selected = true;
              }

              return optionProps;
            })}
          />
        </S.Item>
      )}

      {variations.map((variation, index) => {
        return <S.Item key={index}>{_renderComponent(variation)}</S.Item>;
      })}
    </>
  );
}
