import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './modules/root-reducer';
import rootSaga from './modules/root-saga';

const persistConfig = {
  key: 'hotmart-cosmos',
  storage,
  whitelist: ['language'],
};

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const enhancer = applyMiddleware(...middlewares);

const store = createStore(persistReducer(persistConfig, rootReducer), enhancer);

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { store, persistor };
