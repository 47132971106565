import React from 'react';

export default function Radio({ className, inputProps = {}, labelProps = {} }) {
  return (
    <div className={className}>
      <input
        type="radio"
        onChange={inputProps.onChange}
        className={inputProps.className}
        id={inputProps.id}
        name={inputProps.name}
        {...inputProps}
      />
      <label
        className={labelProps.className}
        htmlFor={labelProps.htmlFor}
        {...labelProps}
      >
        {labelProps.title}
      </label>
    </div>
  );
}
