import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { Container } from './button-style';
import { WebCard } from 'web-components';

import {
  Banner,
  SandBox,
  OtherDocumentations,
  FoundationMenu,
} from 'components';

import {
  PrimaryImage,
  SecondaryImage,
  TertiaryImage,
  DefaultImage,
  CallToActionImage,
  DangerImage,
  CustomImage,
  DisabledImage,
  LoadingImage,
  IconLeftImage,
  IconRightImage,
  SmallImage,
  MediumImage,
  LargeImage,
  ButtonDoExample1Image,
  ButtonDoExample2Image,
  ButtonDontExample1Image,
  ButtonDontExample2Image,
} from './button-images';

const Content = ({ data: language }) => {
  const component = language.components.button;
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const titles = [
    component.variations.title,
    component.usage.title,
    component.icons.title,
    component.sizes.title,
    component.microcopy.title,
    component.otherDocumentations.title,
  ];

  return (
    <Container>
      <div className="hot-container _p-5 _p-lg-8">
        <div className="hot-row">
          <div className="hot-col">
            <h1 className="_font-weight-bold _text-8"> {component.title} </h1>
            <p className="_pb-7 _mb-0">{component.subhead}</p>
          </div>
        </div>

        <div className="hot-row _pt-7 _pb-7">
          {component.characteristics.map((characteristic, index) => (
            <div className="hot-col-3" key={index}>
              <strong className="_d-block _pb-4">{characteristic.label}</strong>
              <p className="_pr-8 _mb-0">{characteristic.text}</p>
            </div>
          ))}
        </div>

        <div className="hot-row _pt-7 _pb-7">
          <div className="hot-col">
            <SandBox slug="button" translates={component} />
          </div>
        </div>

        <div className="hot-row _d-flex _justify-content-between _mt-8">
          <main className="hot-col-12 hot-col-sm-9">
            <section id={component.variations.title}>
              <h2 className="_font-weight-bold _pb-5 _mb-0 _text-4">
                {component.variations.title}
              </h2>
              <article>
                <h3 className="_font-weight-bold _mb-3 _pt-7 _text-2">
                  {component.variations.primary.title}
                </h3>
                <p className="_mb-6">{component.variations.primary.text}</p>
                <img
                  src={PrimaryImage}
                  alt={component.variations.primary.title}
                  className="_max-w-full _mb-5"
                />
              </article>

              <article>
                <h3 className="_font-weight-bold _mb-3 _pt-7 _text-2">
                  {component.variations.secondary.title}
                </h3>
                <p className="_mb-6">{component.variations.secondary.text}</p>
                <img
                  src={SecondaryImage}
                  alt={component.variations.secondary.title}
                  className="_max-w-full _mb-5"
                />
              </article>

              <article>
                <h3 className="_font-weight-bold _mb-3 _pt-7 _text-2">
                  {component.variations.tertiary.title}
                </h3>
                <p className="_mb-6">{component.variations.tertiary.text}</p>
                <img
                  src={TertiaryImage}
                  alt={component.variations.tertiary.title}
                  className="_max-w-full _mb-5"
                />
              </article>

              <article>
                <h3 className="_font-weight-bold _mb-3 _pt-7 _text-2">
                  {component.variations.default.title}
                </h3>
                <p className="_mb-6">{component.variations.default.text}</p>
                <img
                  src={DefaultImage}
                  alt={component.variations.default.title}
                  className="_max-w-full _mb-5"
                />
              </article>

              <article>
                <h3 className="_font-weight-bold _mb-3 _pt-7 _text-2">
                  {component.variations.cta.title}{' '}
                </h3>
                <p className="_mb-6">{component.variations.cta.text}</p>
                <img
                  src={CallToActionImage}
                  alt={component.variations.cta.title}
                  className="_max-w-full _mb-5"
                />
              </article>

              <article>
                <h3 className="_font-weight-bold _mb-3 _pt-7 _text-2">
                  {component.variations.danger.title}
                </h3>
                <p className="_mb-6">{component.variations.danger.text}</p>
                <img
                  src={DangerImage}
                  alt={component.variations.danger.title}
                  className="_max-w-full _mb-5"
                />
              </article>

              <article>
                <h3 className="_font-weight-bold _mb-3 _pt-7 _text-2">
                  {component.variations.custom.title}
                </h3>
                <p className="_mb-6">{component.variations.custom.text}</p>
                <img
                  src={CustomImage}
                  alt={component.variations.custom.title}
                  className="_max-w-full _mb-5"
                />
              </article>
            </section>

            <section className="_pt-7 _pb-7" id={component.usage.title}>
              <h2 className="_font-weight-bold _pb-5 _mb-0 _text-4">
                {component.usage.title}
              </h2>
              <article className="_d-flex _mb-8">
                <img
                  className="hot-col-6 _border _p-0 _rounded-lg"
                  src={DisabledImage}
                  alt={component.usage.disabled.title}
                />
                <div className="_ml-7">
                  <h3 className="_font-weight-bold _mb-3 _text-2">
                    {component.usage.disabled.title}
                  </h3>
                  <p>{component.usage.disabled.text}</p>
                </div>
              </article>
              <article className="_d-flex">
                <img
                  className="hot-col-6 _border _p-0 _rounded-lg"
                  src={LoadingImage}
                  alt={component.usage.loading.title}
                />
                <div className="_ml-7">
                  <h3 className="_font-weight-bold _mb-3 _text-2">
                    {component.usage.loading.title}
                  </h3>
                  <p>{component.usage.loading.text}</p>
                </div>
              </article>
            </section>

            <section className="_pt-7 _pb-7" id={component.icons.title}>
              <h2 className="_font-weight-bold _pb-5 _mb-0 _text-4">
                {component.icons.title}
              </h2>
              <article className="_d-flex _mb-8">
                <img
                  className="hot-col-6 _border _p-0 _rounded-lg"
                  src={IconLeftImage}
                  alt={component.icons.variations.left.title}
                />
                <div className="_ml-7">
                  <h3 className="_font-weight-bold _mb-3 _text-2">
                    {component.icons.variations.left.title}
                  </h3>
                  <p>{component.icons.variations.left.text}</p>
                </div>
              </article>
              <article className="_d-flex">
                <img
                  className="hot-col-6 _border _p-0 _rounded-lg"
                  src={IconRightImage}
                  alt={component.icons.variations.right.title}
                />
                <div className="_ml-7">
                  <h3 className="_font-weight-bold _mb-3 _text-2">
                    {component.icons.variations.right.title}
                  </h3>
                  <p>{component.icons.variations.right.text}</p>
                </div>
              </article>
            </section>

            <section className="_pt-7 _pb-7" id={component.sizes.title}>
              <h2 className="_font-weight-bold _pb-5 _mb-0 _text-4">
                {component.sizes.title}
              </h2>
              <article className="_d-flex _mb-8">
                <img
                  className="hot-col-6 _border _p-0 _rounded-lg"
                  src={SmallImage}
                  alt={component.sizes.variations.small.title}
                />
                <div className="_ml-7">
                  <h3 className="_font-weight-bold _mb-3 _text-2">
                    {component.sizes.variations.small.title}
                  </h3>
                  <p>{component.sizes.variations.small.text}</p>
                </div>
              </article>
              <article className="_d-flex _mb-8">
                <img
                  className="hot-col-6 _border _p-0 _rounded-lg"
                  src={MediumImage}
                  alt={component.sizes.variations.medium.title}
                />
                <div className="_ml-7">
                  <h3 className="_font-weight-bold _mb-3 _text-2">
                    {component.sizes.variations.medium.title}
                  </h3>
                  <p>{component.sizes.variations.medium.text}</p>
                </div>
              </article>
              <article className="_d-flex">
                <img
                  className="hot-col-6 _border _p-0 _rounded-lg"
                  src={LargeImage}
                  alt={component.sizes.variations.large.title}
                />
                <div className="_ml-7">
                  <h3 className="_font-weight-bold _mb-3 _text-2">
                    {component.sizes.variations.large.title}
                  </h3>
                  <p>{component.sizes.variations.large.text}</p>
                </div>
              </article>
            </section>

            <section className="_pt-7 _pb-7" id={component.microcopy.title}>
              <h2 className="_font-weight-bold _pb-5 _mb-0 _text-4">
                {component.microcopy.title}
              </h2>
              <p className="_mb-6">{component.microcopy.firstText}</p>
              <p className="_mb-8">{component.microcopy.secondText}</p>

              <p className="_mb-5">{component.microcopy.firstTip}</p>
              <div className="_mb-8">
                <div className="hot-row">
                  <div className="hot-col-lg-6">
                    <WebCard className="correct">
                      <hot-card-header />
                      <hot-card-body>
                        <img
                          src={ButtonDoExample1Image}
                          alt=""
                          className="_max-w-full"
                        />
                      </hot-card-body>
                      <hot-card-footer>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {component.microcopy.do}
                        </p>
                      </hot-card-footer>
                    </WebCard>
                  </div>
                  <div className="hot-col-lg-6">
                    <WebCard className="incorrect _h-full">
                      <hot-card-header />
                      <hot-card-body>
                        <img
                          src={ButtonDontExample1Image}
                          alt=""
                          className="_max-w-full"
                        />
                      </hot-card-body>
                      <hot-card-footer>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {component.microcopy.dont}
                        </p>
                      </hot-card-footer>
                    </WebCard>
                  </div>
                </div>
              </div>
              <div>
                <p className="_mb-5">{component.microcopy.secondTip}</p>
              </div>
              <div>
                <div className="hot-row">
                  <div className="hot-col-lg-6">
                    <WebCard className="correct">
                      <hot-card-header />
                      <hot-card-body>
                        <img
                          src={ButtonDoExample2Image}
                          alt=""
                          className="_max-w-full"
                        />
                      </hot-card-body>
                      <hot-card-footer>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {component.microcopy.do}
                        </p>
                      </hot-card-footer>
                    </WebCard>
                  </div>
                  <div className="hot-col-lg-6">
                    <WebCard className="incorrect _h-full">
                      <hot-card-header />
                      <hot-card-body>
                        <img
                          src={ButtonDontExample2Image}
                          alt=""
                          className="_max-w-full"
                        />
                      </hot-card-body>
                      <hot-card-footer>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {component.microcopy.dont}
                        </p>
                      </hot-card-footer>
                    </WebCard>
                  </div>
                </div>
              </div>
            </section>

            <section className="_pt-7 _pb-7">
              <div className="hot-row _justify-content-between _mb-7">
                <div className="hot-col-6">
                  <h3 className="_mb-5 _font-weight-bold _text-2">
                    {component.tips.continuity.title}
                  </h3>
                  <p>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      color="var(--green)"
                      className="_mr-4 fa-lg"
                    />
                    {component.tips.continuity.do}
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      color="var(--red)"
                      className="_mr-4 fa-lg"
                    />
                    {component.tips.continuity.dont}
                  </p>
                </div>
                <div className="hot-col-6">
                  <h3 className="_mb-5 _font-weight-bold _text-2">
                    {component.tips.sendingData.title}
                  </h3>
                  <p>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      color="var(--green)"
                      className="_mr-4 fa-lg"
                    />
                    {component.tips.sendingData.do}
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      color="var(--red)"
                      className="_mr-4 fa-lg"
                    />
                    {component.tips.sendingData.dont}
                  </p>
                </div>
              </div>

              <div className="hot-row _justify-content-between _mb-7">
                <div className="hot-col-6">
                  <h3 className="_mb-5 _font-weight-bold _text-2">
                    {component.tips.removingEntries.title}
                  </h3>
                  <p>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      color="var(--green)"
                      className="_mr-4 fa-lg"
                    />
                    {component.tips.removingEntries.do}
                    <small
                      className="_d-block _ml-7"
                      style={{ color: 'var(--gray-500)' }}
                    >
                      {component.tips.removingEntries.text}
                    </small>
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      color="var(--red)"
                      className="_mr-4 fa-lg"
                    />
                    {component.tips.removingEntries.dont}
                  </p>
                </div>
                <div className="hot-col-6">
                  <h3 className="_mb-5 _font-weight-bold _text-2">
                    {component.tips.editingEntries.title}
                  </h3>
                  <p>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      color="var(--green)"
                      className="_mr-4 fa-lg"
                    />
                    {component.tips.editingEntries.do}
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      color="var(--red)"
                      className="_mr-4 fa-lg"
                    />
                    {component.tips.editingEntries.dont}
                  </p>
                </div>
              </div>

              <div className="hot-row _justify-content-between _mb-7">
                <div className="hot-col-6">
                  <h3 className="_mb-5 _font-weight-bold _text-2">
                    {component.tips.enteringHotmart.title}
                  </h3>
                  <p>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      color="var(--green)"
                      className="_mr-4 fa-lg"
                    />
                    {component.tips.enteringHotmart.do}
                    <small
                      className="_d-block _ml-7"
                      style={{ color: 'var(--gray-500)' }}
                    >
                      {component.tips.enteringHotmart.text}{' '}
                      <strong>
                        <Link to="/cosmos/documentacao/fundacao/tipografia">
                          {component.tips.enteringHotmart.link}
                        </Link>
                      </strong>
                    </small>
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      color="var(--red)"
                      className="_mr-4 fa-lg"
                    />
                    {component.tips.enteringHotmart.dont}
                  </p>
                </div>
                <div className="hot-col-6">
                  <h3 className="_mb-5 _font-weight-bold _text-2">
                    {component.tips.leavingHotmart.title}
                  </h3>
                  <p>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      color="var(--green)"
                      className="_mr-4 fa-lg"
                    />
                    {component.tips.leavingHotmart.do}
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      color="var(--red)"
                      className="_mr-4 fa-lg"
                    />
                    {component.tips.leavingHotmart.dont}
                  </p>
                </div>
              </div>
            </section>

            <Banner link={bannerLink} />

            <OtherDocumentations
              documentations={component.otherDocumentations}
            />
          </main>
          <aside className="hot-col-sm-2 _d-none _d-sm-block">
            <FoundationMenu content={titles} />
          </aside>
        </div>
      </div>
    </Container>
  );
};

const ButtonPage = (data) => {
  return <Content data={data} />;
};

export default ButtonPage;
