import React from 'react';
import parser from 'html-react-parser';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Banner, FoundationMenu, OtherDocumentations } from 'components';
import { WebCard } from 'web-components';
import { Container } from './voice-and-tone-style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';

export default function VoiceAndTonePage() {
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const {
    data: { voicetone },
  } = useSelector((state) => state.language);

  const titles = [
    voicetone.voice.anchor,
    voicetone.tone.anchor,
    voicetone.tonevoicepractice.anchor,
    voicetone.secondToneVoicePractice.anchor,
  ];

  return (
    <Container className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8"> {voicetone.title} </h1>
          <p className="_pb-7 _mb-0">{voicetone.subtitle}</p>
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section className="_pt-7 _pb-7">
            <p className="_mb-5">{voicetone.text.first}</p>
            <p className="_mb-5">{voicetone.text.second}</p>
            <p className="_mb-0">{voicetone.text.third}</p>
          </section>

          <section className="_pt-7 _pb-7" id={voicetone.voice.anchor}>
            <div className="_mb-5">
              <h2 className="_font-weight-bold _text-4 _mb-3">
                {voicetone.voice.title}
              </h2>
              <p>{parser(voicetone.voice.subtitle)}</p>
            </div>
            <div>
              <div>
                <h3 className="_font-weight-bold _text-2 _mb-3">
                  {voicetone.voice.inspiration.title}
                </h3>
                <p className="_mb-5">{voicetone.voice.inspiration.text}</p>
              </div>
              <div>
                <h3 className="_font-weight-bold _text-2 _mb-3">
                  {voicetone.voice.didactic.title}
                </h3>
                <p className="_mb-5">{voicetone.voice.didactic.text}</p>
              </div>
              <div>
                <h3 className="_font-weight-bold _text-2 _mb-3">
                  {voicetone.voice.objective.title}
                </h3>
                <p className="_mb-5">{voicetone.voice.objective.text}</p>
              </div>
              <div>
                <h3 className="_font-weight-bold _text-2 _mb-3">
                  {voicetone.voice.affectionate.title}
                </h3>
                <p className="_mb-0">{voicetone.voice.affectionate.text}</p>
              </div>
            </div>
          </section>

          <section className="_pt-7 _pb-7" id={voicetone.tone.anchor}>
            <div className="_mb-8">
              <h2 className="_font-weight-bold _text-4">
                {voicetone.tone.title}
              </h2>
              <p>{parser(voicetone.tone.subtitle)}</p>
            </div>
            <div>
              <p className="_mb-5 _font-weight-bold">
                {voicetone.tone.text.first}
              </p>
              <p className="_mb-5">{voicetone.tone.text.second}</p>
              <p className="_font-weight-bold _mb-0">
                {voicetone.tone.text.third}
              </p>
            </div>
          </section>

          <section
            className="_pt-7 _pb-7"
            id={voicetone.tonevoicepractice.anchor}
          >
            <div className="_mb-8">
              <h2 className="_font-weight-bold _text-4">
                {voicetone.tonevoicepractice.title}
              </h2>
            </div>

            <section className="_mb-6">
              <p className="_mb-6">
                {parser(voicetone.tonevoicepractice.positive.text)}
              </p>
              <div className="hot-row _mb-8">
                <div className="hot-col-lg-6">
                  <WebCard className="correct">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="card-text _text-gray-700">
                        {voicetone.tonevoicepractice.positive.correct.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-1 _text-gray-600">
                          {voicetone.tonevoicepractice.positive.correct.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="card-text _font-italic">
                        <i>
                          {voicetone.tonevoicepractice.positive.incorrect.text}
                        </i>
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-1 _text-gray-600">
                          {voicetone.tonevoicepractice.positive.incorrect.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </section>
            <section className="_mb-6">
              <p className="_mb-6">
                {parser(voicetone.tonevoicepractice.neutral.text)}
              </p>
              <div className="hot-row _mb-8">
                <div className="hot-col-lg-6">
                  <WebCard className="correct">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="card-text _text-gray-700">
                        {voicetone.tonevoicepractice.neutral.correct.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-1 _text-gray-600">
                          {voicetone.tonevoicepractice.neutral.correct.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="card-text _font-italic">
                        <i>
                          {voicetone.tonevoicepractice.neutral.incorrect.text}
                        </i>
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-1 _text-gray-600">
                          {voicetone.tonevoicepractice.neutral.incorrect.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </section>
            <section>
              <p className="_mb-6">
                {parser(voicetone.tonevoicepractice.negative.text)}
              </p>
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="card-text _text-gray-700">
                        {voicetone.tonevoicepractice.negative.correct.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-1 _text-gray-600">
                          {voicetone.tonevoicepractice.negative.correct.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="card-text _font-italic">
                        <i>
                          {voicetone.tonevoicepractice.negative.incorrect.text}
                        </i>
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-1 _text-gray-600">
                          {voicetone.tonevoicepractice.negative.incorrect.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </section>
          </section>

          <section
            className="_pt-7 _pb-7"
            id={voicetone.secondToneVoicePractice.anchor}
          >
            <div className="_mb-7">
              <h2 className="_font-weight-bold _text-4">
                {voicetone.secondToneVoicePractice.title}
              </h2>
            </div>
            <section className="_pb_7">
              <div>
                <div className="_mb-7">
                  <h3 className="_font-weight-bold _text-2 _mb-3">
                    {voicetone.secondToneVoicePractice.longtail.title}
                  </h3>
                  <p className="_mb-5">
                    {voicetone.secondToneVoicePractice.longtail.text}
                  </p>
                </div>
                <div>
                  <h3 className="_font-weight-bold _text-2 _mb-3">
                    {voicetone.secondToneVoicePractice.advanced.title}
                  </h3>
                  <p className="_mb-0">
                    {voicetone.secondToneVoicePractice.advanced.text}
                  </p>
                </div>
              </div>
            </section>
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={voicetone.otherDocumentations} />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </Container>
  );
}
