import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { WebCard } from 'web-components';
import { Container } from './iconography-style';

import iconsFeatures from 'assets/images/foundation/iconography/icon-features.png';
import iconsPaddingException from 'assets/images/foundation/iconography/icon-padding-exception.png';
import iconsPadding from 'assets/images/foundation/iconography/icon-padding.png';
import iconSizes from 'assets/images/foundation/iconography/icon-sizes.png';
import iconsNewIcons from 'assets/images/foundation/iconography/icon-new-icons.png';
import iconslibrary from 'assets/images/foundation/iconography/icon-figma-library.png';
import iconfontawesome from 'assets/images/foundation/iconography/icon-fontawesome.png';
import iconsColors from 'assets/images/foundation/iconography/icon-colors.png';
import iconsShapes from 'assets/images/foundation/iconography/icon-shapes.png';
import iconsAlignment from 'assets/images/foundation/iconography/icon-proportion-alignment.png';

import { Banner, FoundationMenu, OtherDocumentations } from 'components';

export default function Developer() {
  const { data: language } = useSelector((state) => state.language);

  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const iconography = language.iconography.use;
  const whyUseIt = iconography.content.whyUseIt;
  const principle = iconography.content.principles;
  const traces = iconography.content.traces;
  const sizes = iconography.content.sizes;
  const padding = iconography.content.padding;
  const library = iconography.content.library;
  const newIcons = iconography.content.newIcons;
  const FontAwesome = iconography.content.FontAwesome;
  const basicGuidelines = iconography.content.basicGuidelines;
  const otherDocumentations = iconography.otherDocumentations;

  const titles = [
    whyUseIt.title,
    principle.title,
    traces.title,
    sizes.title,
    padding.title,
    basicGuidelines.title,
    library.title,
    newIcons.title,
    FontAwesome.title,
  ];

  return (
    <Container>
      <div className="hot-container">
        <div className="hot-row _justify-content-between">
          <div className="hot-col-12 hot-col-sm-9">
            <div className="_pt-6 _pb-2" id={whyUseIt.title}>
              <h2 className="_font-weight-bold _text-4">{whyUseIt.title}</h2>
              <div className="hot-row _pt-4 _pb-4">
                {whyUseIt.characteristics.map((characteristic, index) => (
                  <div className="hot-col-lg-6 hot-col-sm-12" key={index}>
                    <strong className="_d-block _pb-4">
                      {characteristic.label}
                    </strong>
                    <p className="_pr-4 _mb-5">{characteristic.text}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="_pt-6" id={principle.title}>
              <h2 className="_font-weight-bold _text-4">{principle.title}</h2>
              <div className="_pt-4">
                {principle.characteristics.map((characteristic, index) => (
                  <div className="_mb-6" key={index}>
                    <h3 className="_font-weight-bold _text-2 _mb-3">
                      {characteristic.label}
                    </h3>
                    <p>{characteristic.text}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="_pt-8 _pb-6" id={traces.title}>
              <h2 className="_font-weight-bold _text-4 _mb-3">
                {traces.title}
              </h2>
              <div className="_mb-6">
                <p>{traces.subtitle}</p>
              </div>
              <p>
                <img
                  src={iconsFeatures}
                  className="_w-full"
                  alt="grid-centered"
                />
              </p>
            </div>

            <div className="_pt-6 _pb-6" id={sizes.title}>
              <h2 className="_font-weight-bold _text-4 _mb-3">{sizes.title}</h2>
              <div className="_mb-6">
                <p>{sizes.subtitle}</p>
              </div>
              <p>
                <img src={iconSizes} className="_w-full" alt="grid-centered" />
              </p>
            </div>

            <div className="_pt-6 _pb-6" id={padding.title}>
              <h2 className="_font-weight-bold _text-4 _mb-3">
                {padding.title}
              </h2>
              <div className="_mb-6">
                <p>{padding.subtitle.accepted}</p>
              </div>
              <div className="_mb-6">
                <p>
                  <img
                    src={iconsPadding}
                    className="_w-full"
                    alt="grid-centered"
                  />
                </p>
              </div>
              <div className="_mb-6">
                <p>{padding.subtitle.exception}</p>
              </div>
              <p>
                <img
                  src={iconsPaddingException}
                  className="_w-full"
                  alt="grid-centered"
                />
              </p>
            </div>

            <section className="_pt-6 _pb-6" id={basicGuidelines.title}>
              <h2 className="_font-weight-bold _pb-0 _mb-3 _text-4">
                {basicGuidelines.title}
              </h2>
              <div className="_mb-7">
                <p>{basicGuidelines.subtitle}</p>
              </div>

              <article className="hot-row _d-flex _mb-6">
                <img
                  className="hot-col-lg-6 hot-col-sm-12 _border _p-0 _rounded-lg"
                  src={iconsAlignment}
                  alt={basicGuidelines.title}
                />
                <div className="_ml-0 _ml-lg-7 _mt-4 _mt-sm-0 hot-col-lg-5 hot-col-sm-12">
                  <h3 className="_font-weight-bold _mb-3 _text-2">
                    {basicGuidelines.proportionAlignment.label}
                  </h3>
                  <p>{basicGuidelines.proportionAlignment.description}</p>
                </div>
              </article>

              <article className="hot-row _d-flex _mb-6">
                <div className="hot-col-lg-6 hot-col-sm-12 _border _p-0 _rounded-lg">
                  <WebCard className="incorrect _h-full">
                    <hot-card-body className="_p-0">
                      <img
                        src={iconsColors}
                        alt={basicGuidelines.shapes.label}
                        className="_max-w-full"
                      />
                    </hot-card-body>
                    <hot-card-footer>
                      <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                        {basicGuidelines.shapes.dont}
                      </p>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="_ml-0 _ml-lg-7 _mt-4 _mt-sm-0 hot-col-lg-5 hot-col-sm-12">
                  <h3 className="_font-weight-bold _mb-3 _text-2">
                    {basicGuidelines.shapes.label}
                  </h3>
                  <p>{basicGuidelines.shapes.description}</p>
                </div>
              </article>

              <article className="hot-row _d-flex _d-flex">
                <div className="hot-col-lg-6 hot-col-sm-12 _border _p-0 _rounded-lg">
                  <WebCard className="incorrect _h-full">
                    <hot-card-body className="_p-0">
                      <img
                        src={iconsShapes}
                        alt={basicGuidelines.colors.label}
                        className="_max-w-full"
                      />
                    </hot-card-body>
                    <hot-card-footer>
                      <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                        {basicGuidelines.colors.dont}
                      </p>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="_ml-0 _ml-lg-7 _mt-4 _mt-sm-0 hot-col-lg-5 hot-col-sm-12">
                  <h3 className="_font-weight-bold _mb-3 _text-2">
                    {basicGuidelines.colors.label}
                  </h3>
                  <p>{basicGuidelines.colors.description}</p>
                </div>
              </article>
            </section>

            <div className="_pt-8 _pb-6" id={library.title}>
              <h2 className="_font-weight-bold _text-4 _mb-3">
                {library.title}
              </h2>
              <div className="_mb-6">
                <p>{library.subtitle}</p>
              </div>
              <div className="_mb-6">
                <p>
                  <img
                    src={iconslibrary}
                    className="_w-full"
                    alt="grid-centered"
                  />
                </p>
              </div>
              <div>
                <p>{library.description}</p>
              </div>
            </div>

            <div className="_pt-6 _pb-6" id={newIcons.title}>
              <h2 className="_font-weight-bold _text-4 _mb-3">
                {newIcons.title}
              </h2>
              <div className="_mb-6">
                <p>{newIcons.subtitle}</p>
              </div>
              <p>
                <img
                  src={iconsNewIcons}
                  className="_w-full"
                  alt="grid-centered"
                />
              </p>
            </div>

            <div className="_pt-6 _pb-6 _mb-4" id={FontAwesome.title}>
              <h2 className="_font-weight-bold _text-4 _mb-3">
                {FontAwesome.title}
              </h2>
              <div className="_mb-6">
                <p>{FontAwesome.subtitle}</p>
              </div>
              <p>
                <img
                  src={iconfontawesome}
                  className="_w-full"
                  alt="grid-centered"
                />
              </p>
            </div>
            <Banner link={bannerLink} />
            <OtherDocumentations documentations={otherDocumentations} />
          </div>
          <aside className="hot-col-md-2 _d-none _d-md-block">
            <FoundationMenu content={titles} />
          </aside>
        </div>
      </div>
    </Container>
  );
}
