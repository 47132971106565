function groupBy(array, key) {
  return array.reduce(
    (acc, cur) => ({
      ...acc,
      [cur[key]]: (acc[cur[key]] || []).concat(cur),
    }),
    [],
  );
}

export default groupBy;
