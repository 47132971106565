import React from 'react';
import parser from 'html-react-parser';
import { useLocation } from 'react-router-dom';

import {
  Banner,
  ExampleCard,
  FoundationMenu,
  OtherDocumentations,
  SandBox,
} from 'components';

import {
  radioUsageImage,
  radioCompareRadioImage,
  radioCompareCheckboxImage,
  radioCompareSwitchImage,
  radioWithoutTextImage,
  radioSpacingImage,
  radioDisabledImage,
  radioSelectVsRadioAvoidImage,
  radioSelectVsRadioPreferImage,
} from './radio-images';

const comparisonImages = {
  radioCompareRadioImage,
  radioCompareCheckboxImage,
  radioCompareSwitchImage,
};

export default function Radio(data) {
  const component = data.components.radio;
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const titles = [
    component.usage.title,
    component.checkboxVsRadioVsSwitch.title,
    component.selectVsRadio.title,
    component.withoutText.title,
    component.spacing.title,
    component.disabled.title,
    component.writing.title,
  ];

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8"> {component.title} </h1>
          <p className="_pb-7 _mb-0">{component.subhead}</p>
        </div>
      </div>

      <div className="hot-row _pt-7 _pb-7">
        <div className="hot-col">
          <SandBox slug="radio" translates={component} />
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section id={component.usage.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.usage.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.usage.text}</p>
            <img src={radioUsageImage} alt="" className="_max-w-full" />
          </section>

          <section
            id={component.checkboxVsRadioVsSwitch.title}
            className="_pt-7 _pb-7"
          >
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.checkboxVsRadioVsSwitch.title}
            </h2>
            <p className="_mb-0 _pb-5">
              {component.checkboxVsRadioVsSwitch.text}
            </p>

            {component.checkboxVsRadioVsSwitch.items.map((item, index) => (
              <article key={index}>
                <div className="hot-row">
                  <div className="hot-col-6">
                    <img
                      src={comparisonImages[item.image]}
                      alt=""
                      className="_max-w-full _mb-5"
                    />
                  </div>
                  <div className="hot-col-6">
                    <h3 className="_font-weight-bold _text-2 _mb-3">
                      {item.title}
                    </h3>
                    <p>{item.text}</p>
                  </div>
                </div>
              </article>
            ))}
          </section>

          <section id={component.selectVsRadio.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.selectVsRadio.title}
            </h2>
            <p className="_mb-0 _pb-5">
              {parser(component.selectVsRadio.text)}
            </p>
            <div className="hot-row _mb-7">
              <div className="hot-col-6">
                <ExampleCard
                  className="_h-full"
                  variation="warning"
                  label={component.selectVsRadio.avoid.tip}
                >
                  <img
                    src={radioSelectVsRadioAvoidImage}
                    alt=""
                    className="_max-w-full _mb-5"
                  />
                </ExampleCard>
              </div>
              <div className="hot-col-6">
                <ExampleCard
                  className="_h-full"
                  variation="success"
                  label={component.selectVsRadio.prefer.tip}
                >
                  <img
                    src={radioSelectVsRadioPreferImage}
                    alt=""
                    className="_max-w-full _mb-5"
                  />
                </ExampleCard>
              </div>
            </div>
          </section>

          <section id={component.withoutText.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.withoutText.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.withoutText.text}</p>
            <img src={radioWithoutTextImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.spacing.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.spacing.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.spacing.text}</p>
            <img src={radioSpacingImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.disabled.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.disabled.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.disabled.text}</p>
            <img src={radioDisabledImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.writing.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.writing.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.writing.text}</p>
            <ul className="_pl-4 _mb-0">
              {component.writing.rules.map((rule, index) => (
                <li key={index}> {rule} </li>
              ))}
            </ul>
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={component.otherDocumentations} />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </div>
  );
}
