import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faCode,
  faExpandArrows,
  faRulerCombined,
  faSlidersH,
} from '@fortawesome/pro-light-svg-icons';

export default function SandboxInteractions({
  isMobile,
  hasConfigurations,
  hasSpecifications,
  toggleExpandedConfiguration,
  toggleExpandedCode,
  toggleExpandedSpecs,
  toggleFullScreen,
}) {
  return (
    <hot-button-group>
      {isMobile && hasConfigurations && (
        <hot-tooltip content="Modificações" position="bottom">
          <button className="hot-button" onClick={toggleExpandedConfiguration}>
            <FontAwesomeIcon icon={faSlidersH} />
          </button>
        </hot-tooltip>
      )}

      {isMobile && (
        <hot-tooltip content="Código" position="bottom">
          <button className="hot-button" onClick={toggleExpandedCode}>
            <FontAwesomeIcon icon={faCode} />
          </button>
        </hot-tooltip>
      )}

      {hasSpecifications && (
        <hot-tooltip content="Especificações" position="bottom">
          <button className="hot-button" onClick={toggleExpandedSpecs}>
            <FontAwesomeIcon icon={faRulerCombined} />
          </button>
        </hot-tooltip>
      )}

      <hot-tooltip content="Expandir" position="bottom">
        <button className="hot-button" onClick={toggleFullScreen}>
          <FontAwesomeIcon icon={faExpandArrows} />
        </button>
      </hot-tooltip>
    </hot-button-group>
  );
}
