import React from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

import {
  Banner,
  FoundationMenu,
  OtherDocumentations,
  SandBox,
} from 'components';

import {
  tableUsageImage,
  tableVariationDefaultImage,
  tableVariationCardImage,
  tableSubComponentsImage,
  tableStripeImage,
  tableHoverImage,
  tableSizeDefaultImage,
  tableSizeCardImage,
  tableResponsiveSmallerScreensImage,
  tableResponsiveExtensiveTablesImage,
} from './table-images';

export default function TablePage(data) {
  const component = data.components.table;
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const titles = [
    component.usage.title,
    component.variations.title,
    component.subComponents.title,
    component.stripe.title,
    component.hover.title,
    component.sizes.title,
    component.responsive.title,
  ];

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8"> {component.title} </h1>
          <p className="_pb-7 _mb-0">{component.subhead}</p>
        </div>
      </div>

      <div className="hot-row _pt-7 _pb-7">
        <div className="hot-col">
          <SandBox slug="table" translates={component} />
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section id={component.usage.title} className="_pt-7 _pb-7">
            <h2 className=" _font-weight-bold _mb-3 _text-4">
              {component.usage.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.usage.text}</p>
            <img src={tableUsageImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.variations.title} className="_pt-7 _pb-7">
            <h2 className=" _font-weight-bold _mb-3 _text-4">
              {component.variations.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.variations.text}</p>

            <article>
              <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                {component.variations.default.title}
              </h3>
              <p className="_mb-6">{component.variations.default.text}</p>
              <img
                src={tableVariationDefaultImage}
                alt=""
                className="_max-w-full _mb-5"
              />
            </article>

            <article>
              <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                {component.variations.card.title}
              </h3>
              <p className="_mb-6">{component.variations.card.text}</p>
              <img
                src={tableVariationCardImage}
                alt=""
                className="_max-w-full _mb-5"
              />
            </article>
          </section>

          <section id={component.subComponents.title} className="_pt-7 _pb-7">
            <h2 className=" _font-weight-bold _mb-3 _text-4">
              {component.subComponents.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.subComponents.text}</p>
            <img src={tableSubComponentsImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.stripe.title} className="_pt-7 _pb-7">
            <h2 className=" _font-weight-bold _mb-3 _text-4">
              {component.stripe.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.stripe.text}</p>
            <img src={tableStripeImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.hover.title} className="_pt-7 _pb-7">
            <h2 className=" _font-weight-bold _mb-3 _text-4">
              {component.hover.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.hover.text}</p>
            <img src={tableHoverImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.sizes.title} className="_pt-7 _pb-7">
            <h2 className=" _font-weight-bold _mb-3 _text-4">
              {component.sizes.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.sizes.text}</p>

            <article>
              <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                {component.sizes.default.title}
              </h3>
              <p className="_mb-6">{component.sizes.default.text}</p>
              <img
                src={tableSizeDefaultImage}
                alt=""
                className="_max-w-full _mb-5"
              />
            </article>

            <article>
              <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                {component.sizes.card.title}
              </h3>
              <p className="_mb-6">{component.sizes.card.text}</p>
              <img
                src={tableSizeCardImage}
                alt=""
                className="_max-w-full _mb-0"
              />
            </article>
          </section>

          <section id={component.responsive.title} className="_pt-7 _pb-7">
            <h2 className=" _font-weight-bold _mb-3 _text-4">
              {component.responsive.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.responsive.smallerScreens}</p>
            <img
              src={tableResponsiveSmallerScreensImage}
              alt=""
              className="_max-w-full _mb-5"
            />
            <p className="_mb-0 _pb-5">
              {component.responsive.extensiveTables}
            </p>
            <img
              src={tableResponsiveExtensiveTablesImage}
              alt=""
              className="_max-w-full _mb-6"
            />

            <hot-alert role="alert" class="hot-alert--warning _mb-0">
              <div className="_d-flex _align-items-start _justify-content-start">
                <div className="_d-flex _align-items-center _justify-content-center _mt-1 _mr-4">
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                </div>
                <div>{component.responsive.tip}</div>
              </div>
            </hot-alert>
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={component.otherDocumentations} />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </div>
  );
}
