import React from 'react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import {
  faCheck,
  faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Label, LabelIcon, LabelWrapper, Wrapper } from './example-card-style';

export default function ExampleCard({ variation, children, label, className }) {
  return (
    <Wrapper variation={variation}>
      <hot-card class={`_rounded-lg ${className}`}>
        <hot-card-body>{children}</hot-card-body>
        <hot-card-footer>
          <LabelWrapper>
            <LabelIcon variation={variation}>
              {variation === 'warning' && (
                <FontAwesomeIcon icon={faExclamationTriangle} />
              )}
              {variation === 'success' && <FontAwesomeIcon icon={faCheck} />}
              {variation === 'danger' && <FontAwesomeIcon icon={faTimes} />}
            </LabelIcon>
            <Label variation={variation}>{label}</Label>
          </LabelWrapper>
        </hot-card-footer>
      </hot-card>
    </Wrapper>
  );
}
