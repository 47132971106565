import styled from 'styled-components';

export const Section = styled.section`
  @media (min-width: 1200px) {
    .hot-container {
      max-width: 1200px;
    }
  }

  @media (min-width: 1700px) {
    .hot-container {
      max-width: 1200px;
    }
  }
`;
