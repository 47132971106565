import { produce } from 'immer';
import { AuthTypes } from './actions';

const INITIAL_STATE = {
  user: null,
};

const Auth = (state = INITIAL_STATE, actions) => {
  return produce(state, (draft) => {
    switch (actions.type) {
      case AuthTypes.SET_AUTH: {
        const { payload } = actions;
        draft.user = payload;
        break;
      }

      default:
        break;
    }
  });
};

export default Auth;
