import React from 'react';
import { useSelector } from 'react-redux';

function Banner({ link }) {
  const { data: language } = useSelector((state) => state.language);
  const { banner } = language;
  return (
    <div className="_mt-3 _pt-7">
      <div className="_bg-white _rounded _p-8 _mt-7 _mb-7">
        <h2 className="_font-weight-bold _text-4">{banner.text}</h2>
        <p className="_mb-7">
          {banner.subtext}{' '}
          <span role="img" aria-label="Yellow Heart Emoji">
            💛{' '}
          </span>
        </p>
        <a
          href={link}
          className="hot-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          {banner.button}
        </a>
      </div>
    </div>
  );
}

export default Banner;
