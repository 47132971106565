import styled from 'styled-components';

export const Item = styled.div`
  padding: 16px 24px;

  &:first-child {
    padding: 24px 24px;
  }

  &:not(:first-child) {
    border-top: 1px solid var(--component-border-color);
  }
`;
