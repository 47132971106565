function cleanHtmlCode(html = '') {
  let cleanHTML = html;

  // class=''
  cleanHTML = cleanHTML.replace(`class=''`, ``);
  cleanHTML = cleanHTML.replace(`class=' '`, ``);
  cleanHTML = cleanHTML.replace(`class='  '`, ``);
  cleanHTML = cleanHTML.replace(`class='   '`, ``);
  cleanHTML = cleanHTML.replace(`class=' `, `class='`);
  cleanHTML = cleanHTML.replace(`class='  `, `class='`);
  cleanHTML = cleanHTML.replace(`class='   `, `class='`);
  cleanHTML = cleanHTML.replace(`class='    `, `class='`);

  // ''
  cleanHTML = cleanHTML.replace(`' '`, `''`);
  cleanHTML = cleanHTML.replace(`'  '`, `''`);
  cleanHTML = cleanHTML.replace(`'   '`, `''`);
  cleanHTML = cleanHTML.replace(`'    '`, `''`);

  // =''
  cleanHTML = cleanHTML.replace(`=' '`, `=''`);

  // '
  cleanHTML = cleanHTML.replace(` ' `, `'`);

  // >
  cleanHTML = cleanHTML.replace(` >`, `>`);
  cleanHTML = cleanHTML.replace(` >`, `>`);

  // ><
  cleanHTML = cleanHTML.replace(`> <`, `><`);

  // '><
  cleanHTML = cleanHTML.replace(`'> <`, `'><`);

  // />
  cleanHTML = cleanHTML.replace(` /> `, `/>`);

  // '>
  cleanHTML = cleanHTML.replace(`  '>`, `'>`);
  cleanHTML = cleanHTML.replace(`   '>`, `'>`);
  cleanHTML = cleanHTML.replace(`    '>`, `'>`);
  cleanHTML = cleanHTML.replace(`' >`, `'>`);
  cleanHTML = cleanHTML.replace(`'  >`, `'>`);
  cleanHTML = cleanHTML.replace(`'   >`, `'>`);
  cleanHTML = cleanHTML.replace(`'    >`, `'>`);
  cleanHTML = cleanHTML.replace(` '>`, `'>`);

  return cleanHTML;
}

export default cleanHtmlCode;
