export const LanguageTypes = {
  GET_LANGUAGE: 'GET_LANGUAGE',
  SET_LANGUAGE: 'SET_LANGUAGE',
};

export const getLanguage = (language) => {
  return {
    type: LanguageTypes.GET_LANGUAGE,
    payload: language,
  };
};

export const setLanguage = (data) => {
  return {
    type: LanguageTypes.SET_LANGUAGE,
    payload: data,
  };
};
