import React from 'react';

import { Construction, SandBox } from 'components';

export default function EmbedPage(data) {
  const component = data.components.embed;

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8"> {component.title} </h1>
          <p className="_pb-7 _mb-0">{component.subhead}</p>
        </div>
      </div>

      <div className="hot-row _pt-7 _pb-7">
        <div className="hot-col">
          <SandBox slug="embed" translates={component} />
        </div>
      </div>

      <Construction />
    </div>
  );
}
