import React from 'react';
import parse from 'html-react-parser';

import { useState, useMemo, useEffect } from 'react';

import {
  JsonComponents as components,
  removeRepeatFromArray,
  cleanHtmlCode,
} from 'utils';

import SandboxConfiguration from './sandbox-configuration';
import SandboxSpecs from './sandbox-specs';
import SandboxConfigurationMobile from './sandbox-configuration-mobile';
import SandboxSpecsMobile from './sandbox-specs-mobile';
import SandboxCode from './sandbox-code';
import SandboxCodeMobile from './sandbox-code-mobile';
import SandboxInteractions from './sandbox-interactions';

import * as S from './sandbox-styles';

export default function SandBox({ slug, translates }) {
  const [expandedSpecs, setExpandedSpecs] = useState(false);
  const [expandedCode, setExpandedCode] = useState(false);
  const [expandedConfiguration, setExpandedConfiguration] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const [backgroundColor, setBackgroundColor] = useState('default');
  const [variations, setVariations] = useState({});
  const [imports, setImports] = useState([]);

  const component = useMemo(() => {
    return components.find((component) => component['slug'] === slug);
  }, [slug]);

  const isMobile = useMemo(() => {
    return width <= 1200;
  }, [width]);

  const [contentActive, setContentActive] = useState(
    component.variations.content.items[0],
  );

  const [contentActiveItems] = useState(
    component.variations.items.filter((item) =>
      item.targetContentSlug.includes(contentActive.slug),
    ),
  );

  const hasConfigurations = useMemo(() => {
    return contentActiveItems.length > 0;
  }, [contentActiveItems]);

  const renderedComponent = useMemo(() => {
    let html = contentActive.html;

    // Replace modifiers
    Object.keys(variations).forEach((key) => {
      const componentModifiers = variations[key].componentModifiers || [];

      componentModifiers.forEach((modifier) => {
        html = html.replace(
          `{{${modifier.type}-modifier}}`,
          `${modifier.value}`,
        );
      });
    });

    // Not used modifiers
    const modifierKeys = html.match(/\{\{.*?\}\}/g, '') || [];

    modifierKeys.forEach((modifier) => {
      html = html.replace(modifier, '');
    });

    // Translations
    if (!!translates) {
      Object.keys(translates).forEach((key) => {
        html = html.replace(`[[${key}]]`, translates[key]);
      });
    }

    html = cleanHtmlCode(html);

    return html;
  }, [contentActive, translates, variations]);

  const renderedCode = useMemo(() => {
    let html = '';

    imports.map((item) => {
      return (html += `${item}\n`);
    });

    html += '\n';
    html += renderedComponent;

    return html;
  }, [imports, renderedComponent]);

  const hasSpecifications = useMemo(() => {
    let hasTokens = false;

    const keys = Object.keys(variations);

    keys.map((key) => {
      const variation = variations[key];

      if (variation.tokens.length) {
        hasTokens = true;

        return true;
      }

      return false;
    });

    return hasTokens;
  }, [variations]);

  const toggleFullScreen = () => {
    if (fullScreen) {
      document.body.style.overflow = 'scroll';
    } else {
      document.body.style.overflow = 'hidden';
    }

    setFullScreen(!fullScreen);
  };

  const toggleExpandedCode = () => {
    setExpandedCode(!expandedCode);
  };

  const toggleExpandedConfiguration = () => {
    setExpandedConfiguration(!expandedConfiguration);
  };

  const toggleExpandedSpecs = () => {
    setExpandedSpecs(!expandedSpecs);
  };

  const handleComponentConfiguration = (value, type, priority) => {
    const item = type === 'select' ? JSON.parse(value) : value;

    setVariations((prevState) => {
      const newState = {
        ...prevState,
        [item.parentSlug]: { ...item, priority, selected: true },
      };

      if (type === 'checkbox' && !value.isChecked) {
        delete newState[item.parentSlug];
      }

      return newState;
    });
  };

  const handleComponentChange = (ev) => {
    setContentActive(JSON.parse(ev));
  };

  const triggerComponent = (slug) => {
    switch (slug) {
      case 'sidebar':
        return (
          <button
            className="hot-button"
            onClick={() => document.querySelector('hot-sidebar').openSidebar()}
          >
            {`Exibir ${slug} `}
          </button>
        );
      case 'modal':
        return (
          <button
            className="hot-button"
            onClick={() => document.querySelector('hot-modal').openModal()}
          >
            {`Exibir ${slug}`}
          </button>
        );

      default:
        return '';
    }
  };

  useEffect(() => {
    function addSelectedImports() {
      let currentImports = contentActive.imports;

      const variationImports = Object.keys(variations).reduce(
        (acc, cur) => [...acc, ...variations[cur].imports],
        [],
      );

      const repeatedRemoved = removeRepeatFromArray(
        currentImports,
        variationImports,
      );

      setImports(repeatedRemoved);
    }

    addSelectedImports();
  }, [variations, contentActive]);

  useEffect(() => {
    switch (slug) {
      case 'button':
        if (variations?.type?.slug === 'custom') {
          setBackgroundColor('dark');
        }
        break;

      default:
        break;
    }

    return () => {
      setBackgroundColor('default');
    };
  }, [variations, slug]);

  useEffect(() => {
    let selectedVariations = {};

    contentActiveItems.map((modifier) => {
      modifier.items.map((item) => {
        if (item.selected) {
          selectedVariations[item.parentSlug] = {
            ...item,
            selected: true,
          };
        }

        return item;
      });

      return modifier;
    });

    setVariations(selectedVariations);

    return () => {};
  }, [contentActiveItems]);

  useEffect(() => {
    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [setWidth]);

  return (
    <S.SandBox backgroundColor={backgroundColor} fullScreen={fullScreen}>
      <S.Grid
        fullScreen={fullScreen}
        expandedSpecs={expandedSpecs}
        expandedCode={expandedCode}
        expandedConfiguration={expandedConfiguration}
        hasConfigurations={hasConfigurations}
      >
        <S.ConfigurationPanel hasConfigurations={hasConfigurations}>
          <SandboxConfiguration
            component={component}
            content={component.variations.content}
            handleComponentChange={handleComponentChange}
            handleVariationChange={handleComponentConfiguration}
            variations={contentActiveItems}
            selectedVariations={variations}
          />
        </S.ConfigurationPanel>
        <S.SpecsPanel>
          <SandboxSpecs variations={variations} />
        </S.SpecsPanel>
        <S.RenderPanel>
          <S.InteractionPanel>
            <SandboxInteractions
              isMobile={isMobile}
              hasConfigurations={hasConfigurations}
              hasSpecifications={hasSpecifications}
              toggleExpandedConfiguration={toggleExpandedConfiguration}
              toggleExpandedCode={toggleExpandedCode}
              toggleExpandedSpecs={toggleExpandedSpecs}
              toggleFullScreen={toggleFullScreen}
            />
          </S.InteractionPanel>
          <S.ComponentWrapper>
            {triggerComponent(slug)}
            {parse(renderedComponent)}
          </S.ComponentWrapper>
        </S.RenderPanel>
        <S.CodePanel>
          <SandboxCode
            renderedCode={renderedCode}
            imports={imports}
            renderedComponent={renderedComponent}
          />
        </S.CodePanel>
      </S.Grid>

      {isMobile && (
        <SandboxConfigurationMobile
          expandedConfiguration={expandedConfiguration}
          component={component}
          content={component.variations.content}
          handleComponentChange={handleComponentChange}
          handleVariationChange={handleComponentConfiguration}
          variations={contentActiveItems}
          selectedVariations={variations}
          setExpandedConfiguration={setExpandedConfiguration}
          title={component.name}
        />
      )}

      {isMobile && (
        <SandboxSpecsMobile
          expandedSpecs={expandedSpecs}
          setExpandedSpecs={setExpandedSpecs}
          variations={variations}
          title={component.name}
        />
      )}

      {isMobile && (
        <SandboxCodeMobile
          imports={imports}
          renderedCode={renderedCode}
          renderedComponent={renderedComponent}
          expandedCode={expandedCode}
          setExpandedCode={setExpandedCode}
        />
      )}
    </S.SandBox>
  );
}
