import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  Banner,
  ExampleCard,
  FoundationMenu,
  OtherDocumentations,
  SandBox,
} from 'components';

import {
  selectUsageImage,
  selectSizesImage,
  selectMultipleImage,
  selectGroupingImage,
  selectDockedMenuImage,
  selectCompareSelectImage,
  selectCompareRadioImage,
} from './select-images';

export default function SelectPage(data) {
  const component = data.components.select;
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const titles = [
    component.usage.title,
    component.selectVsRadio.title,
    component.multiple.title,
    component.grouping.title,
    component.dockedMenu.title,
    component.sizes.title,
  ];

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8"> {component.title} </h1>
          <p className="_pb-7 _mb-0">{component.subhead}</p>
        </div>
      </div>

      <div className="hot-row _pt-7 _pb-7">
        <div className="hot-col">
          <SandBox slug="select" translates={component} />
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section id={component.usage.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.usage.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.usage.text}</p>
            <img src={selectUsageImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.selectVsRadio.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.selectVsRadio.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.selectVsRadio.text}</p>
            <div className="hot-row _mb-7">
              <div className="hot-col-6">
                <ExampleCard
                  className="_h-full"
                  variation="success"
                  label={component.selectVsRadio.prefer.tip}
                >
                  <img
                    src={selectCompareRadioImage}
                    alt=""
                    className="_max-w-full _mb-5"
                  />
                </ExampleCard>
              </div>
              <div className="hot-col-6">
                <ExampleCard
                  className="_h-full"
                  variation="warning"
                  label={component.selectVsRadio.avoid.tip}
                >
                  <img
                    src={selectCompareSelectImage}
                    alt=""
                    className="_max-w-full _mb-5"
                  />
                </ExampleCard>
              </div>
            </div>
          </section>

          <section id={component.multiple.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.multiple.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.multiple.text}</p>
            <img src={selectMultipleImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.grouping.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.grouping.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.grouping.text}</p>
            <img src={selectGroupingImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.dockedMenu.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.dockedMenu.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.dockedMenu.text}</p>
            <img src={selectDockedMenuImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.sizes.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.sizes.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.sizes.text}</p>
            <img src={selectSizesImage} alt="" className="_max-w-full" />
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={component.otherDocumentations} />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </div>
  );
}
