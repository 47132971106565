import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  Banner,
  FoundationMenu,
  OtherDocumentations,
  SandBox,
} from 'components';

import {
  navigationIconImage,
  navigationUsageImage,
  navigationVariationPillImage,
  navigationVariationTabImage,
} from './navigation-images';

export default function Navigation(data) {
  const component = data.components.navigation;
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const titles = [
    component.usage.title,
    component.variations.title,
    component.icon.title,
    component.writing.title,
  ];

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8"> {component.title} </h1>
          <p className="_pb-7 _mb-0">{component.subhead}</p>
        </div>
      </div>

      <div className="hot-row _pt-7 _pb-7">
        <div className="hot-col">
          <SandBox slug="navigation" translates={component} />
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section id={component.usage.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.usage.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.usage.text}</p>
            <img src={navigationUsageImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.variations.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.variations.title}
            </h2>
            <p>{component.variations.text}</p>

            <article>
              <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                {component.variations.tab.title}
              </h3>
              <p className="_mb-6">{component.variations.tab.text}</p>
              <img
                src={navigationVariationTabImage}
                alt=""
                className="_max-w-full _mb-5"
              />
            </article>

            <article>
              <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                {component.variations.pill.title}
              </h3>
              <p className="_mb-6">{component.variations.pill.text}</p>
              <img
                src={navigationVariationPillImage}
                alt=""
                className="_max-w-full _mb-5"
              />
            </article>
          </section>

          <section id={component.icon.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.icon.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.icon.text}</p>
            <img src={navigationIconImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.writing.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.writing.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.writing.text}</p>
            <ul className="_pl-4 _mb-0">
              {component.writing.rules.map((rule, index) => (
                <li key={index}> {rule} </li>
              ))}
            </ul>
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={component.otherDocumentations} />
        </div>
        <aside className="hot-col-sm-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </div>
  );
}
