import React from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

import {
  Banner,
  FoundationMenu,
  OtherDocumentations,
  SandBox,
} from 'components';

import { modalUsageImage, modalCompositionImage } from './modal-images';

export default function Radio(data) {
  const component = data.components.modal;
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const titles = [component.usage.title, component.composition.title];

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8"> {component.title} </h1>
          <p className="_pb-7 _mb-0">{component.subhead}</p>
        </div>
      </div>

      <div className="hot-row _pt-7 _pb-7">
        <div className="hot-col">
          <SandBox slug="modal" translates={component} />
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section id={component.usage.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.usage.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.usage.text}</p>
            <img src={modalUsageImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.composition.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.composition.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.composition.text}</p>

            <img
              src={modalCompositionImage}
              alt=""
              className="_max-w-full _mb-6"
            />

            <hot-alert role="alert" class="hot-alert--warning _mb-0">
              <div className="_d-flex _align-items-start _justify-content-start">
                <div className="_d-flex _align-items-center _justify-content-center _mt-1 _mr-4">
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                </div>
                <div>{component.composition.tip}</div>
              </div>
            </hot-alert>
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={component.otherDocumentations} />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </div>
  );
}
