import styled from 'styled-components';

const MenuBlock = styled.div`
  ul {
    font-size: var(--text-1);
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin: 0 0 var(--spacer-2);

    &.active {
      a {
        font-weight: var(--font-weight-bold);
        border-color: var(--gray-700);
      }
    }
  }

  a {
    border-left: 1px solid transparent;
    color: var(--gray-700);
    display: flex;
    margin-bottom: var(--spacer-2);
    padding-left: var(--spacer-3);
    text-decoration: none;

    span {
      display: block;
    }
  }
`;

export default MenuBlock;
