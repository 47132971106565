import React from 'react';
import parser from 'html-react-parser';
import { useSelector } from 'react-redux';

import {
  BannerOrnament,
  BannerOrnamentWrapper,
  BannerSection,
  BannerText,
} from './about-style';

import planetImage from 'assets/images/about/planet.svg';
import designSystemImage from 'assets/images/about/design-system.svg';
import valueImage from 'assets/images/about/value.svg';

export default function AboutPage() {
  const {
    data: { about },
  } = useSelector((state) => state.language);

  return (
    <>
      <BannerSection className="_mb-7">
        <BannerText className="_text-3 _font-weight-bold">
          {about.banner.text}
        </BannerText>
        <BannerOrnamentWrapper>
          <BannerOrnament src={planetImage} />
        </BannerOrnamentWrapper>
      </BannerSection>

      <section className="_pt-7 _pb-7">
        <div className="hot-container _px-5 _px-lg-8">
          <div className="hot-row">
            <div className="hot-col">
              <h1 className="_font-weight-bold _text-8">
                {' '}
                {about.head.title}{' '}
              </h1>
              <p className="_mb-0">{about.head.text}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="_pt-7 _pb-7">
        <div className="hot-container _px-5 _px-lg-8">
          <div className="hot-row _justify-content-between">
            <div className="hot-col-12 hot-col-md-8">
              <h2 className="_font-weight-bold _text-5">
                {about.designSystem.title}
              </h2>
              <p className="_mb-6 _mb-md-0">
                {parser(about.designSystem.text)}
              </p>
            </div>
            <div className="hot-col-12 hot-col-md-3">
              <div className="_d-flex _align-items-center _justify-content-center">
                <div className="_rounded-circle _bg-white _d-inline-flex _align-items-center _justify-content-center">
                  <img src={designSystemImage} alt="" className="_mw-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="_pt-7 _pb-7">
        <div className="hot-container _px-5 _px-lg-8 _pb-5 _pb-lg-8">
          <div className="hot-row _justify-content-between">
            <div className="hot-col-12 hot-col-md-8">
              <h2 className="_font-weight-bold _text-5">{about.value.title}</h2>
              <p className="_mb-6 _mb-md-0">{parser(about.value.text)}</p>
            </div>
            <div className="hot-col-12 hot-col-md-3 hot-col-md-order-first">
              <div className="_d-flex _align-items-center _justify-content-center">
                <div className="_rounded-circle _bg-white _d-inline-flex _align-items-center _justify-content-center">
                  <img src={valueImage} alt="" className="_mw-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
