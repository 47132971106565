import React from 'react';
import parser from 'html-react-parser';
import { useLocation } from 'react-router-dom';

import {
  Banner,
  SandBox,
  OtherDocumentations,
  FoundationMenu,
} from 'components';

import {
  dangerVariationImage,
  infoVariationImage,
  neutralVariationImage,
  successVariationImage,
  warningVariationImage,
  alertPositionImage,
  toastPositionImage,
  fixedPersistenceImage,
  dismissablePersistenceImage,
  alertComplexityImage,
} from './alert-images';

const Content = ({ data: language }) => {
  const component = language.components.alert;
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const titles = [
    component.alertVsToast.title,
    component.variations.title,
    component.persistence.title,
    component.positions.title,
    component.complexity.title,
  ];

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div>
        <div className="hot-row">
          <div className="hot-col">
            <h1 className="_font-weight-bold _text-8"> {component.title} </h1>
            <p className="_pb-7 _mb-0">{component.subhead}</p>
          </div>
        </div>

        <div className="hot-row _pt-7 _pb-7">
          {component.characteristics.map((characteristic, index) => (
            <div className="hot-col-12 hot-col-md-3 _mt-4 _mt-md-0" key={index}>
              <strong className="_d-block _pb-4">{characteristic.label}</strong>
              <p className="_pr-8 _mb-0">{characteristic.text}</p>
            </div>
          ))}
        </div>

        <div className="hot-row _pt-7 _pb-7">
          <div className="hot-col">
            <SandBox
              slug="alert-toast"
              translates={language.components.alert}
            />
          </div>
        </div>

        <div className="hot-row _justify-content-between">
          <main className="hot-col-12 hot-col-sm-9">
            <section id={component.alertVsToast.title} className="_pt-7 _pb-7">
              <h2 className="_font-weight-bold _pb-5 _mb-0 _text-4">
                {component.alertVsToast.title}
              </h2>

              <div className="hot-row _mb-5">
                <div className="hot-col-6">
                  <p className="_mb-0">{component.alertVsToast.head.alert}</p>
                </div>
                <div className="hot-col-6">
                  <p className="_mb-0">{component.alertVsToast.head.toast}</p>
                </div>
              </div>

              {component.alertVsToast.rows.map((row, index) => (
                <hot-card key={index} class="_mb-2 _rounded-lg">
                  <hot-card-body>
                    <div className="hot-row">
                      <div className="hot-col-6">
                        <p>{row.alert}</p>
                      </div>
                      <div className="hot-col-6">
                        <p>{row.toast}</p>
                      </div>
                    </div>
                  </hot-card-body>
                </hot-card>
              ))}
            </section>

            <section id={component.variations.title} className="_pt-7 _pb-7">
              <h2 className="_font-weight-bold _mb-3 _text-4">
                {component.variations.title}
              </h2>
              <p>{component.variations.text}</p>

              <article>
                <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                  {component.variations.info.title}
                </h3>
                <p className="_mb-6">{component.variations.info.text}</p>
                <img
                  src={infoVariationImage}
                  alt=""
                  className="_max-w-full _mb-5"
                />
              </article>

              <article>
                <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                  {component.variations.success.title}
                </h3>
                <p className="_mb-6">{component.variations.success.text}</p>
                <img
                  src={successVariationImage}
                  alt=""
                  className="_max-w-full _mb-5"
                />
              </article>

              <article>
                <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                  {component.variations.warning.title}
                </h3>
                <p className="_mb-6">{component.variations.warning.text}</p>
                <img
                  src={warningVariationImage}
                  alt=""
                  className="_max-w-full _mb-5"
                />
              </article>

              <article>
                <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                  {component.variations.danger.title}
                </h3>
                <p className="_mb-6">{component.variations.danger.text}</p>
                <img
                  src={dangerVariationImage}
                  alt=""
                  className="_max-w-full _mb-5"
                />
              </article>

              <article>
                <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                  {component.variations.neutral.title}
                </h3>
                <p className="_mb-6">{component.variations.neutral.text}</p>
                <img
                  src={neutralVariationImage}
                  alt=""
                  className="_max-w-full"
                />
              </article>
            </section>

            <section id={component.persistence.title} className="_pt-7 _pb-7">
              <h2 className="_font-weight-bold _mb-7 _text-4">
                {component.persistence.title}
              </h2>
              <div className="hot-row _pt-5">
                <div className="hot-col-6">
                  <h3 className="_pb-3 _font-weight-bold _text-2">
                    {component.persistence.fixed.label}
                  </h3>
                  <p>{parser(component.persistence.fixed.text)}</p>
                  <img
                    src={fixedPersistenceImage}
                    alt=""
                    className="_max-w-full"
                  />
                </div>
                <div className="hot-col-6">
                  <h3 className="_pb-3 _font-weight-bold _text-2">
                    {component.persistence.dismissable.label}
                  </h3>
                  <p>{component.persistence.dismissable.text}</p>
                  <img
                    src={dismissablePersistenceImage}
                    alt=""
                    className="_max-w-full"
                  />
                </div>
              </div>
            </section>

            <section id={component.positions.title} className="_pt-7 _pb-7">
              <h2 className="_font-weight-bold _mb-5 _text-4">
                {component.positions.title}
              </h2>

              <article>
                <h3 className="_font-weight-bold _text-2 _pt-7 _pb-3">
                  {component.positions.alert.title}
                </h3>
                <p>{component.positions.alert.text}</p>
                <img
                  src={alertPositionImage}
                  alt="Primary Button"
                  className="_max-w-full _mb-5"
                />
              </article>

              <article>
                <h3 className="_font-weight-bold _text-2 _pt-7 _pb-3">
                  {component.positions.toast.title}
                </h3>
                <p>{component.positions.toast.text}</p>
                <img
                  src={toastPositionImage}
                  alt="Primary Button"
                  className="_max-w-full _mb-5"
                />
              </article>
            </section>

            <section id={component.complexity.title} className="_pt-7 _pb-7">
              <h2 className="_font-weight-bold _mb-3 _text-4">
                {component.complexity.title}
              </h2>
              <p className="_mb-0 _pb-5">{component.complexity.text}</p>
              <img
                src={alertComplexityImage}
                alt="Primary Button"
                className="_max-w-full _mt-7"
              />
            </section>

            <Banner link={bannerLink} />

            <OtherDocumentations
              documentations={component.otherDocumentations}
            />
          </main>
          <aside className="hot-col-sm-2 _d-none _d-sm-block">
            <FoundationMenu content={titles} />
          </aside>
        </div>
      </div>
    </div>
  );
};

const AlertPage = (data) => {
  return <Content data={data} />;
};

export default AlertPage;
