import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  SandBox,
  FoundationMenu,
  Banner,
  OtherDocumentations,
} from 'components';

import {
  switchUsageImage,
  switchCompareCheckboxImage,
  switchCompareImage,
  switchCompareRadioImage,
  switchMultilevelImage,
  switchSpacingImage,
  switchDisabledImage,
} from './switch-images';

const comparisonImages = {
  switchCompareImage,
  switchCompareCheckboxImage,
  switchCompareRadioImage,
};

export default function Switch(data) {
  const component = data.components.switch;
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const titles = [
    component.usage.title,
    component.checkboxVsRadioVsSwitch.title,
    component.multilevelSwitch.title,
    component.spacing.title,
    component.disabled.title,
    component.writing.title,
  ];

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div>
        <h1 className="_font-weight-bold _text-8"> {component.title} </h1>
        <p className="_pb-7 _mb-0">{component.subhead}</p>
      </div>

      <div className="hot-row _pt-7 _pb-7">
        <div className="hot-col">
          <SandBox slug="switch" translates={component} />
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section id={component.usage.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.usage.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.usage.text}</p>
            <img src={switchUsageImage} alt="" className="_max-w-full _mb-5" />
          </section>

          <section
            id={component.checkboxVsRadioVsSwitch.title}
            className="_pt-7 _pb-7"
          >
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.checkboxVsRadioVsSwitch.title}
            </h2>
            <p className="_mb-0 _pb-5">
              {component.checkboxVsRadioVsSwitch.text}
            </p>

            {component.checkboxVsRadioVsSwitch.items.map((item, index) => (
              <article key={index}>
                <div className="hot-row">
                  <div className="hot-col-6">
                    <img
                      src={comparisonImages[item.image]}
                      alt=""
                      className="_max-w-full _mb-5"
                    />
                  </div>
                  <div className="hot-col-6">
                    <h3 className="_font-weight-bold _text-2 _mb-3">
                      {item.title}
                    </h3>
                    <p>{item.text}</p>
                  </div>
                </div>
              </article>
            ))}
          </section>

          <section
            id={component.multilevelSwitch.title}
            className="_pt-7 _pb-7"
          >
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.multilevelSwitch.title}
            </h2>
            <img
              src={switchMultilevelImage}
              alt=""
              className="_max-w-full _mb-5"
            />
            <p className="_mb-0">{component.multilevelSwitch.text}</p>
          </section>

          <section id={component.spacing.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.spacing.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.spacing.text}</p>
            <img
              src={switchSpacingImage}
              alt=""
              className="_max-w-full _mb-5"
            />
          </section>

          <section id={component.disabled.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.disabled.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.disabled.text}</p>
            <img
              src={switchDisabledImage}
              alt=""
              className="_max-w-full _mb-5"
            />
          </section>

          <section id={component.writing.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.writing.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.writing.text}</p>
            <ul className="_pl-4 _mb-0">
              {component.writing.rules.map((rule, index) => (
                <li key={index}> {rule} </li>
              ))}
            </ul>
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={component.otherDocumentations} />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </div>
  );
}
