import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faTimesCircle,
  faExclamationTriangle,
  faCheckCircle,
  faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';

import {
  Banner,
  FoundationMenu,
  OtherDocumentations,
  CopyPasteContainer,
} from 'components';

export default function ColorsPage() {
  const { data: language } = useSelector((state) => state.language);

  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const color = language.colors;
  const semantic = color.semantic;
  const system = color.system;
  const auxiliaries = color.auxiliaries;
  const neutral = color.neutral;
  const brand = color.brand;
  const otherDocumentations = color.otherDocumentations;

  const titles = [
    semantic.title,
    system.title,
    auxiliaries.title,
    neutral.title,
    brand.title,
  ];

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8"> {color.title} </h1>
          <p className="_pb-7 _mb-0">{color.subtitle}</p>
        </div>
      </div>
      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <div className="_pt-7 _pb-7" id={semantic.title}>
            <h2 className="_font-weight-bold _text-4">{semantic.title}</h2>
            <p>{semantic.subtitle}</p>
            <div className="hot-row">
              <div className="hot-col-6 hot-col-xl-3 _mb-4 _mb-xl-0">
                <hot-card class="_flex-column _text-center _bg-primary-lightest _border-primary-light _px-4 _py-8 _rounded-lg">
                  <hot-card-body class="_pb-0">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="var(--blue)"
                      className="_text-blue h1 _mb-0"
                      alt={semantic.card.error}
                    />
                  </hot-card-body>
                  <hot-card-footer class="_px-0">
                    <p className="_text-primary-dark _text-1 _mb-0">Blue</p>
                    <p className="_font-weight-bold _text-primary-dark _mb-0">
                      {semantic.card.information}
                    </p>
                  </hot-card-footer>
                </hot-card>
              </div>
              <div className="hot-col-6 hot-col-xl-3 _mb-4 _mb-xl-0">
                <hot-card class="_flex-column _text-center _bg-green-lightest _border-green-light _px-4 _py-8">
                  <hot-card-body class="_pb-0">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      color="var(--success)"
                      className="_text-green h1 _mb-0"
                      alt={semantic.card.success}
                    />
                  </hot-card-body>
                  <hot-card-footer class="_px-0">
                    <p className="_text-green-dark _text-1 _mb-0">Green</p>
                    <p className="_font-weight-bold _text-green-dark _mb-0">
                      {semantic.card.success}
                    </p>
                  </hot-card-footer>
                </hot-card>
              </div>
              <div className="hot-col-6 hot-col-xl-3">
                <hot-card class="_flex-column _text-center _bg-yellow-lightest _border-yellow-light _px-4 _py-8">
                  <hot-card-body class="_pb-0">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      color="var(--yellow)"
                      className="_text-yellow h1 _mb-0"
                      alt={semantic.card.attention}
                    />
                  </hot-card-body>
                  <hot-card-footer class="_px-0">
                    <p className="_text-yellow-dark _text-1 _mb-0">Yellow</p>
                    <p className="_font-weight-bold _text-yellow-dark _mb-0">
                      {semantic.card.attention}
                    </p>
                  </hot-card-footer>
                </hot-card>
              </div>
              <div className="hot-col-6 hot-col-xl-3">
                <hot-card class="_flex-column _text-center _bg-red-lightest _border-red-light _px-4 _py-8">
                  <hot-card-body class="_pb-0">
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      color="var(--red)"
                      className="_text-red h1 _mb-0"
                      alt={semantic.card.error}
                    />
                  </hot-card-body>
                  <hot-card-footer class="_px-0">
                    <p className="_text-red-dark _text-1 _mb-0">Red</p>
                    <p className="_font-weight-bold  _text-red-dark _mb-0">
                      {semantic.card.error}
                    </p>
                  </hot-card-footer>
                </hot-card>
              </div>
            </div>
          </div>

          <div className="_pt-7 _pb-7" id={system.title}>
            <h2 className="_font-weight-bold _text-4">{system.title}</h2>
            <div className="_mb-8">
              <h3 className="_font-weight-bold _text-2">Blue</h3>
              <p>{system.blue}</p>
              <hot-card>
                <div className="hot-row">
                  <div className="hot-col-md-6 _pr-md-0">
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      className="_h-full"
                      content="--blue"
                    >
                      <hot-card class="_rounded-0 _bg-blue _h-full">
                        <hot-card-header>
                          <p className="_text-white">--blue</p>
                          <p className="_text-white _mb-0">#5981E3</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                  </div>
                  <div className="hot-col-md-6 _pl-md-0">
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--blue-lightest"
                    >
                      <hot-card class="_rounded-0 _bg-blue-lightest">
                        <hot-card-header>
                          <p className="_text-black">--blue-lightest</p>
                          <p className="_text-black _mb-0">#F0F4FF</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--blue-lighter"
                    >
                      <hot-card class="_rounded-0 _bg-blue-lighter">
                        <hot-card-header>
                          <p className="_text-black">--blue-lighter</p>
                          <p className="_text-black _mb-0">#BED1FF</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--blue-light"
                    >
                      <hot-card class="_rounded-0 _bg-blue-light">
                        <hot-card-header>
                          <p className="_text-black">--blue-light</p>
                          <p className="_text-black _mb-0">#89A8F8</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      content="--blue-dark"
                    >
                      <hot-card class="_rounded-0 _bg-blue-dark">
                        <hot-card-header>
                          <p className="_text-white">--blue-dark</p>
                          <p className="_text-white _mb-0">#355CC0</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      content="--blue-darker"
                    >
                      <hot-card class="_rounded-0 _bg-blue-darker">
                        <hot-card-header>
                          <p className="_text-white">--blue-darker</p>
                          <p className="_text-white _mb-0">#253F82</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                  </div>
                </div>
              </hot-card>
            </div>
            <div className="_mb-8">
              <h3 className="_font-weight-bold _text-2">Green</h3>
              <p>{system.green}</p>
              <hot-card>
                <div className="hot-row">
                  <div className="hot-col-md-6 _pr-md-0">
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      className="_h-full"
                      content="--green"
                    >
                      <hot-card class="_rounded-0 _bg-green _h-full">
                        <hot-card-header>
                          <p className="_text-white">--green</p>
                          <p className="_text-white _mb-0">#009D43</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                  </div>
                  <div className="hot-col-md-6 _pl-md-0">
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--green-lightest"
                    >
                      <hot-card class="_rounded-0 _bg-green-lightest">
                        <hot-card-header>
                          <p className="_text-black">--green-lightest</p>
                          <p className="_text-black _mb-0">#EDFFF5</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--green-lighter"
                    >
                      <hot-card class="_rounded-0 _bg-green-lighter">
                        <hot-card-header>
                          <p className="_text-black">--green-lighter</p>
                          <p className="_text-black _mb-0">#99E9BB</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--green-light"
                    >
                      <hot-card class="_rounded-0 _bg-green-light">
                        <hot-card-header>
                          <p className="_text-black">--green-light</p>
                          <p className="_text-black _mb-0">#4ACC82</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      content="--green-dark"
                    >
                      <hot-card class="_rounded-0 _bg-green-dark">
                        <hot-card-header>
                          <p className="_text-white">--green-dark</p>
                          <p className="_text-white _mb-0">#006E2F</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      content="--green-darker"
                    >
                      <hot-card class="_rounded-0 _bg-green-darker">
                        <hot-card-header>
                          <p className="_text-white">--green-darker</p>
                          <p className="_text-white _mb-0">#005122</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                  </div>
                </div>
              </hot-card>
            </div>
            <div className="_mb-8">
              <h3 className="_font-weight-bold _text-2">Yellow</h3>
              <p>{system.yellow}</p>
              <hot-card>
                <div className="hot-row">
                  <div className="hot-col-md-6 _pr-md-0">
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      className="_h-full"
                      content="--yellow"
                    >
                      <hot-card class="_rounded-0 _bg-yellow _h-full">
                        <hot-card-header>
                          <p className="_text-white">--yellow</p>
                          <p className="_text-white _mb-0">#EFBA0f</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                  </div>
                  <div className="hot-col-md-6 _pl-md-0">
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--yellow-lightest"
                    >
                      <hot-card class="_rounded-0 _bg-yellow-lightest">
                        <hot-card-header>
                          <p className="_text-black">--yellow-lightest</p>
                          <p className="_text-black _mb-0">#FFFAEB</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--yellow-lighter"
                    >
                      <hot-card class="_rounded-0 _bg-yellow-lighter">
                        <hot-card-header>
                          <p className="_text-black">--yellow-lighter</p>
                          <p className="_text-black _mb-0">#F9E298</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--yellow-light"
                    >
                      <hot-card class="_rounded-0 _bg-yellow-light">
                        <hot-card-header>
                          <p className="_text-black">--yellow-light</p>
                          <p className="_text-black _mb-0">#FBD458</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      content="--yellow-dark"
                    >
                      <hot-card class="_rounded-0 _bg-yellow-dark">
                        <hot-card-header>
                          <p className="_text-white">--yellow-dark</p>
                          <p className="_text-white _mb-0">#A47C00</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      content="--yellow-darker"
                    >
                      <hot-card class="_rounded-0 _bg-yellow-darker">
                        <hot-card-header>
                          <p className="_text-white">--yellow-darker</p>
                          <p className="_text-white _mb-0">#6C5200</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                  </div>
                </div>
              </hot-card>
            </div>
            <div className="_mb-8">
              <h3 className="_font-weight-bold _text-2">Red</h3>
              <p>{system.red}</p>
              <hot-card>
                <div className="hot-row">
                  <div className="hot-col-md-6 _pr-md-0">
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      className="_h-full"
                      content="--red"
                    >
                      <hot-card class="_rounded-0 _bg-red _h-full">
                        <hot-card-header>
                          <p className="_text-white">--red</p>
                          <p className="_text-white _mb-0">#D6342C</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                  </div>
                  <div className="hot-col-md-6 _pl-md-0">
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--red-lightest"
                    >
                      <hot-card class="_rounded-0 _bg-red-lightest">
                        <hot-card-header>
                          <p className="_text-black">--red-lightest</p>
                          <p className="_text-black _mb-0">#FFF0F0</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--red-lighter"
                    >
                      <hot-card class="_rounded-0 _bg-red-lighter">
                        <hot-card-header>
                          <p className="_text-black">--red-lighter</p>
                          <p className="_text-black _mb-0">#F9CAC8</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--red-light"
                    >
                      <hot-card class="_rounded-0 _bg-red-light">
                        <hot-card-header>
                          <p className="_text-black">--red-light</p>
                          <p className="_text-black _mb-0">#E37570</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      content="--red-dark"
                    >
                      <hot-card class="_rounded-0 _bg-red-dark">
                        <hot-card-header>
                          <p className="_text-white">--red-dark</p>
                          <p className="_text-white _mb-0">#A81A0A</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      content="--red-darker"
                    >
                      <hot-card class="_rounded-0 _bg-red-darker">
                        <hot-card-header>
                          <p className="_text-white">--red-darker</p>
                          <p className="_text-white _mb-0">#830D00</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                  </div>
                </div>
              </hot-card>
            </div>
          </div>

          <div className="_pt-7 _pb-7" id={auxiliaries.title}>
            <h2 className="_font-weight-bold _text-4">{auxiliaries.title}</h2>
            <div className="_mb-8">
              <h3 className="_font-weight-bold _text-2">Purple</h3>
              <p>{system.purple}</p>
              <hot-card>
                <div className="hot-row">
                  <div className="hot-col-md-6 _pr-md-0">
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      className="_h-full"
                      content="--purple"
                    >
                      <hot-card class="_rounded-0 _bg-purple _h-full">
                        <hot-card-header>
                          <p className="_text-white">--purple</p>
                          <p className="_text-white _mb-0">#7C5EE2</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                  </div>
                  <div className="hot-col-md-6 _pl-md-0">
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--purple-lightest"
                    >
                      <hot-card class="_rounded-0 _bg-purple-lightest">
                        <hot-card-header>
                          <p className="_text-black">--purple-lightest</p>
                          <p className="_text-black _mb-0">#F6F2FF</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--purple-lighter"
                    >
                      <hot-card class="_rounded-0 _bg-purple-lighter">
                        <hot-card-header>
                          <p className="_text-black">--purple-lighter</p>
                          <p className="_text-black _mb-0">#D3C6FD</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--purple-light"
                    >
                      <hot-card class="_rounded-0 _bg-purple-light">
                        <hot-card-header>
                          <p className="_text-black">--purple-light</p>
                          <p className="_text-black _mb-0">#AC93FC</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      content="--purple-dark"
                    >
                      <hot-card class="_rounded-0 _bg-purple-dark">
                        <hot-card-header>
                          <p className="_text-white">--purple-dark</p>
                          <p className="_text-white _mb-0">#5A38C6</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      content="--purple-darker"
                    >
                      <hot-card class="_rounded-0 _bg-purple-darker">
                        <hot-card-header>
                          <p className="_text-white">--purple-darker</p>
                          <p className="_text-white _mb-0">#4727B0</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                  </div>
                </div>
              </hot-card>
            </div>
            <div className="_mb-0">
              <h3 className="_font-weight-bold _text-2">Cyan</h3>
              <p>{auxiliaries.cyan}</p>
              <hot-card>
                <div className="hot-row">
                  <div className="hot-col-md-6 _pr-md-0">
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      className="_h-full"
                      content="--cyan"
                    >
                      <hot-card class="_rounded-0 _bg-cyan _h-full">
                        <hot-card-header>
                          <p className="_text-white">--cyan</p>
                          <p className="_text-white _mb-0">#51C1C3</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                  </div>
                  <div className="hot-col-md-6 _pl-md-0">
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--cyan-lightes"
                    >
                      <hot-card class="_rounded-0 _bg-cyan-lightest">
                        <hot-card-header>
                          <p className="_text-black">--cyan-lightest</p>
                          <p className="_text-black _mb-0">#EDFFFF</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--cyan-lighter"
                    >
                      <hot-card class="_rounded-0 _bg-cyan-lighter">
                        <hot-card-header>
                          <p className="_text-black">--cyan-lighter</p>
                          <p className="_text-black _mb-0">#A2EAEA</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--cyan-light"
                    >
                      <hot-card class="_rounded-0 _bg-cyan-light">
                        <hot-card-header>
                          <p className="_text-black">--cyan-light</p>
                          <p className="_text-black _mb-0">#7CDBDB</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      content="--cyan-dark"
                    >
                      <hot-card class="_rounded-0 _bg-cyan-dark">
                        <hot-card-header>
                          <p className="_text-white">--cyan-dark</p>
                          <p className="_text-white _mb-0">#0A9090</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      content="--cyan-darker"
                    >
                      <hot-card class="_rounded-0 _bg-cyan-darker">
                        <hot-card-header>
                          <p className="_text-white">--cyan-darker</p>
                          <p className="_text-white _mb-0">#066262</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                  </div>
                </div>
              </hot-card>
            </div>
          </div>

          <div className="_pt-7 _pb-7" id={neutral.title}>
            <h2 className="_font-weight-bold _text-4">{neutral.title}</h2>
            <p>{neutral.subtitle}</p>
            <hot-card>
              <div className="hot-row">
                <div className="hot-col">
                  <CopyPasteContainer
                    toastVariation="custom"
                    iconAbsolutePosition
                    content="--white"
                  >
                    <hot-card class="_rounded-0 _bg-white">
                      <hot-card-header>
                        <p className="_text-black">--white</p>
                        <p className="_text-black _mb-0">#FFFFFF</p>
                      </hot-card-header>
                    </hot-card>
                  </CopyPasteContainer>
                  <CopyPasteContainer
                    toastVariation="custom"
                    iconAbsolutePosition
                    content="--gray-100"
                  >
                    <hot-card class="_rounded-0 _bg-gray-100">
                      <hot-card-header>
                        <p className="_text-black">--gray-100</p>
                        <p className="_text-black _mb-0">#FAFBFC</p>
                      </hot-card-header>
                    </hot-card>
                  </CopyPasteContainer>
                  <CopyPasteContainer
                    toastVariation="custom"
                    iconAbsolutePosition
                    content="--gray-200"
                  >
                    <hot-card class="_rounded-0 _bg-gray-200">
                      <hot-card-header>
                        <p className="_text-black">--gray-200</p>
                        <p className="_text-black _mb-0">#E6E9ED</p>
                      </hot-card-header>
                    </hot-card>
                  </CopyPasteContainer>
                  <CopyPasteContainer
                    toastVariation="custom"
                    iconAbsolutePosition
                    content="--gray-300"
                  >
                    <hot-card class="_rounded-0 _bg-gray-300">
                      <hot-card-header>
                        <p className="_text-black">--gray-300</p>
                        <p className="_text-black _mb-0">#C9CED4</p>
                      </hot-card-header>
                    </hot-card>
                  </CopyPasteContainer>
                  <CopyPasteContainer
                    toastVariation="custom"
                    iconAbsolutePosition
                    content="--gray-400"
                  >
                    <hot-card class="_rounded-0 _bg-gray-400">
                      <hot-card-header>
                        <p className="_text-black">--gray-400</p>
                        <p className="_text-black _mb-0">#9EA4AC</p>
                      </hot-card-header>
                    </hot-card>
                  </CopyPasteContainer>
                  <CopyPasteContainer
                    toastVariation="custom"
                    colorWhite
                    iconAbsolutePosition
                    content="--gray-500"
                  >
                    <hot-card class="_rounded-0 _bg-gray-500">
                      <hot-card-header>
                        <p className="_text-white">--gray-500</p>
                        <p className="_text-white _mb-0">#707780</p>
                      </hot-card-header>
                    </hot-card>
                  </CopyPasteContainer>
                  <CopyPasteContainer
                    toastVariation="custom"
                    colorWhite
                    iconAbsolutePosition
                    content="--gray-600"
                  >
                    <hot-card class="_rounded-0 _bg-gray-600">
                      <hot-card-header>
                        <p className="_text-white">--gray-600</p>
                        <p className="_text-white _mb-0">#464B52</p>
                      </hot-card-header>
                    </hot-card>
                  </CopyPasteContainer>
                  <CopyPasteContainer
                    toastVariation="custom"
                    colorWhite
                    iconAbsolutePosition
                    content="--gray-700"
                  >
                    <hot-card class="_rounded-0 _bg-gray-700">
                      <hot-card-header>
                        <p className="_text-white">--gray-700</p>
                        <p className="_text-white _mb-0">#32363B</p>
                      </hot-card-header>
                    </hot-card>
                  </CopyPasteContainer>
                  <CopyPasteContainer
                    toastVariation="custom"
                    colorWhite
                    iconAbsolutePosition
                    content="--gray-800"
                  >
                    <hot-card class="_rounded-0 _bg-gray-800">
                      <hot-card-header>
                        <p className="_text-white">--gray-800</p>
                        <p className="_text-white _mb-0">#282C2F</p>
                      </hot-card-header>
                    </hot-card>
                  </CopyPasteContainer>
                  <CopyPasteContainer
                    toastVariation="custom"
                    colorWhite
                    iconAbsolutePosition
                    content="--gray-900"
                  >
                    <hot-card class="_rounded-0 _bg-gray-900">
                      <hot-card-header>
                        <p className="_text-white">--gray-900</p>
                        <p className="_text-white _mb-0">#191C1F</p>
                      </hot-card-header>
                    </hot-card>
                  </CopyPasteContainer>
                  <CopyPasteContainer
                    toastVariation="custom"
                    colorWhite
                    iconAbsolutePosition
                    content="--black"
                  >
                    <hot-card class="_rounded-0 _bg-black">
                      <hot-card-header>
                        <p className="_text-white">--black</p>
                        <p className="_text-white _mb-0">#000000</p>
                      </hot-card-header>
                    </hot-card>
                  </CopyPasteContainer>
                </div>
              </div>
            </hot-card>
          </div>

          <div className="_pt-7 _pb-7" id={brand.title}>
            <h2 className="_font-weight-bold _text-4">{brand.title}</h2>
            <div className="_mb-8">
              <h3 className="_font-weight-bold _text-2">Brand Primary</h3>
              <p>{brand.primary}</p>
              <hot-card>
                <div className="hot-row">
                  <div className="hot-col-md-6 _pr-md-0">
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      className="_h-full"
                      content="--brand-primary"
                    >
                      <hot-card class="_rounded-0 _bg-brand-primary _h-full">
                        <hot-card-header>
                          <p className="_text-white">--brand-primary</p>
                          <p className="_text-white _mb-0">#EF4E23</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                  </div>
                  <div className="hot-col-md-6 _pl-md-0">
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--brand-primary-lightest"
                    >
                      <hot-card class="_rounded-0 _bg-brand-primary-lightest">
                        <hot-card-header>
                          <p className="_text-black">
                            --brand-primary-lightest
                          </p>
                          <p className="_text-black _mb-0">#FDECE7</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--brand-primary-lighter"
                    >
                      <hot-card class="_rounded-0 _bg-brand-primary-lighter">
                        <hot-card-header>
                          <p className="_text-black">--brand-primary-lighter</p>
                          <p className="_text-black _mb-0">#FF9377</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      content="--brand-primary-light"
                    >
                      <hot-card class="_rounded-0 _bg-brand-primary-light">
                        <hot-card-header>
                          <p className="_text-white">--brand-primary-light</p>
                          <p className="_text-white _mb-0">#FE734F</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      content="--brand-primary-dark"
                    >
                      <hot-card class="_rounded-0 _bg-brand-primary-dark">
                        <hot-card-header>
                          <p className="_text-white">--brand-primary-dark</p>
                          <p className="_text-white _mb-0">#C5310A</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      content="--brand-primary-darker"
                    >
                      <hot-card class="_rounded-0 _bg-brand-primary-darker">
                        <hot-card-header>
                          <p className="_text-white">--brand-primary-darker</p>
                          <p className="_text-white _mb-0">#9C2100</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                  </div>
                </div>
              </hot-card>
            </div>
            <div className="_mb-0">
              <h3 className="_font-weight-bold _text-2">Brand Secondary</h3>
              <p>{brand.secondary}</p>
              <hot-card>
                <div className="hot-row">
                  <div className="hot-col-md-6 _pr-md-0">
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      className="_h-full"
                      content="--brand-secondary"
                    >
                      <hot-card class="_rounded-0 _bg-brand-secondary _h-full">
                        <hot-card-header>
                          <p className="_text-white">--brand-secondary</p>
                          <p className="_text-white _mb-0">#51C1C3</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                  </div>
                  <div className="hot-col-md-6 _pl-md-0">
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--brand-secondary-lightest"
                    >
                      <hot-card class="_rounded-0 _bg-brand-secondary-lightest">
                        <hot-card-header>
                          <p className="_text-black">
                            --brand-secondary-lightest
                          </p>
                          <p className="_text-black _mb-0">#053D4E</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      iconAbsolutePosition
                      content="--brand-secondary-lighter"
                    >
                      <hot-card class="_rounded-0 _bg-brand-secondary-lighter">
                        <hot-card-header>
                          <p className="_text-black">
                            --brand-secondary-lighter
                          </p>
                          <p className="_text-black _mb-0">#E7F8FD</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      content="--brand-secondary-light"
                    >
                      <hot-card class="_rounded-0 _bg-brand-secondary-light">
                        <hot-card-header>
                          <p className="_text-white">--brand-secondary-light</p>
                          <p className="_text-white _mb-0">#155364</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      content="--brand-secondary-dark"
                    >
                      <hot-card class="_rounded-0 _bg-brand-secondary-dark">
                        <hot-card-header>
                          <p className="_text-white">--brand-secondary-dark</p>
                          <p className="_text-white _mb-0">#155364</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                    <CopyPasteContainer
                      toastVariation="custom"
                      colorWhite
                      iconAbsolutePosition
                      content="--brand-secondary-darker"
                    >
                      <hot-card class="_rounded-0 _bg-brand-secondary-darker">
                        <hot-card-header>
                          <p className="_text-white">
                            --brand-secondary-darker
                          </p>
                          <p className="_text-white _mb-0">#00181E</p>
                        </hot-card-header>
                      </hot-card>
                    </CopyPasteContainer>
                  </div>
                </div>
              </hot-card>
            </div>
          </div>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={otherDocumentations} />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </div>
  );
}
