import styled from 'styled-components';

export const Item = styled.div`
  padding-bottom: var(--spacer-4);
`;

export const Title = styled.p`
  font-weight: var(--font-weight-bold);
  margin: 0;
  padding: var(--spacer-4) var(--spacer-4) var(--spacer-2) var(--spacer-4);

  &:empty {
    padding-top: var(--spacer-2);
    padding-bottom: 0;
  }
`;

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  font-size: var(--text-1);
  color: var(--gray-900);
  margin-bottom: 0;
`;

export const ListItemName = styled.p`
  font-weight: var(--font-weight-bold);
  padding: var(--spacer-2) var(--spacer-4);
  margin: 0;
`;

export const ListItemWrapper = styled.div`
  cursor: pointer;
  transition: background-color var(--timing) var(--easing),
    color var(--timing) var(--easing);
  margin-left: 12px;
  margin-right: 12px;

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }

  .copy-inline {
    border-radius: var(--spacer-1);
    padding: var(--spacer-2);
    width: 100%;
  }

  &:hover {
    color: var(--blue-dark);
    background: var(--blue-lightest);
  }
`;
