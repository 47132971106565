import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { appRoutes } from 'routes-map';

import { Sidebar } from 'components';

export default function DocumentationPage() {
  const { data: datinha } = useSelector((state) => state.language);

  return (
    <hot-structure>
      <Sidebar />
      <hot-content slot="content" class="hot-application--pinned">
        <Switch>
          {appRoutes.map(({ component, ...props }, key) => {
            return (
              <Route
                path={`/cosmos/documentation${props.path}`}
                component={() => component(datinha)}
                key={key}
                exact
              />
            );
          })}
          <Route path="">
            <Redirect to="/404" />
          </Route>
        </Switch>
      </hot-content>
    </hot-structure>
  );
}
