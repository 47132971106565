import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import voiceAndToneImage from 'assets/images/overview/content/voice-and-tone.svg';
import coverageImage from 'assets/images/overview/content/coverage.svg';
import productNameImage from 'assets/images/overview/content/product-name.svg';
import styleImage from 'assets/images/overview/content/style.svg';
import internationalizationImage from 'assets/images/overview/content/internationalization.svg';

const images = {
  voiceAndToneImage,
  coverageImage,
  productNameImage,
  styleImage,
  internationalizationImage,
};

export default function ContentPage() {
  const { data: language } = useSelector((state) => state.language);

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <h1 className="_font-weight-bold _text-8">
        {language.contentOverview.title}
      </h1>
      <div className="hot-row">
        <div className="hot-col-md-9">
          <p className="_mb-5 _mb-lg-8">{language.contentOverview.subhead}</p>
        </div>
      </div>

      <div className="hot-row">
        {language.contentOverview.items.map((item) => (
          <div
            className="hot-col-md-6 hot-col-lg-4 _mb-4 _mb-lg-5"
            key={item.id}
          >
            <hot-card class="hot-card--hover _h-full _rounded-lg">
              <hot-card-header>
                <img
                  src={images[item.image]}
                  alt={item.title}
                  className="_w-full"
                />
              </hot-card-header>
              <hot-card-body class="_p-4 _p-lg-8">
                <h2 className="_font-weight-bold _text-4">
                  <Link
                    to={item.link}
                    className="_stretched-link _text-decoration-none _text-gray-700"
                    title={item.title}
                  >
                    {item.title}
                  </Link>
                </h2>
                <p className="_text-gray-500 _mb-0">{item.text}</p>
              </hot-card-body>
            </hot-card>
          </div>
        ))}
      </div>
    </div>
  );
}
