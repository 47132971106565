import React from 'react';
import figmaImage from 'assets/images/icons/figma.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';

export default function Design({ design }) {
  return (
    <div className="hot-row">
      <div className="hot-col-12 hot-col-sm-9">
        <h4 className="_font-weight-bold">{design.content.title}</h4>
        <p>{design.content.text}</p>
        <hot-card class="hot-card--hover _d-inline-flex _rounded-lg">
          <hot-card-body class="_d-flex _align-items-center _p-6">
            <img src={figmaImage} className="_w-6 _mr-3" alt="Figma Logo" />
            <h6 className="_mb-0">
              <a
                href="https://www.figma.com/proto/1bYSL0kCDMxcaoDmScC7EZ/Hotmart-Design-Get-Started?node-id=121%3A314&viewport=-1583%2C-926%2C0.641615092754364&scaling=min-zoom&hotspot-hints=0&hide-ui=1"
                className="_text-decoration-none _stretched-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hotmart Get Started Guide
              </a>
            </h6>
            <FontAwesomeIcon
              icon={faExternalLink}
              className="_ml-8 _text-primary"
            />{' '}
          </hot-card-body>
        </hot-card>
      </div>
    </div>
  );
}
