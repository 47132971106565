import React from 'react';
import { useLocation } from 'react-router-dom';

import { Banner, OtherDocumentations, SandBox } from 'components';

import {
  collapseUsageOneImage,
  collapseUsageSecondImage,
} from './collapse-images';

export default function CollapsePage(data) {
  const component = data.components.collapse;
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8"> {component.title} </h1>
          <p className="_pb-7 _mb-0">{component.subhead}</p>
        </div>
      </div>

      <div className="hot-row _pt-7 _pb-7">
        <div className="hot-col">
          <SandBox slug="collapse" translates={component} />
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section id={component.usage.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.usage.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.usage.text}</p>
            <img
              src={collapseUsageOneImage}
              alt=""
              className="_max-w-full _mb-6"
            />
            <p className="_mb-0 _pb-5">{component.usage.secondText}</p>
            <img
              src={collapseUsageSecondImage}
              alt=""
              className="_max-w-full _mb-0"
            />
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={component.otherDocumentations} />
        </div>
      </div>
    </div>
  );
}
