import React, { Fragment, useEffect } from 'react';
import { CopyPasteContainer } from 'components';

import * as S from './sandbox-code-mobile-styles';

export default function SandboxCodeMobile({
  imports,
  renderedCode,
  renderedComponent,
  expandedCode,
  setExpandedCode,
}) {
  const sidebarProps = {};

  const hotSidebar = document.querySelector('#sandboxCodeMobileSidebar');

  if (expandedCode) {
    sidebarProps.open = true;
  }

  useEffect(() => {
    if (!hotSidebar) {
      return;
    }

    const shadowRoot = hotSidebar.shadowRoot;

    if (!shadowRoot) {
      return;
    }

    const style = document.createElement('style');

    style.innerHTML = `
          :host hot-sidebar-concrete {
            width: 100%;
          }
        `;

    shadowRoot.appendChild(style);

    return () => {};
  }, [hotSidebar]);

  useEffect(() => {
    if (hotSidebar) {
      hotSidebar.addEventListener('close', () => {
        setExpandedCode(false);
      });
    }
  }, [hotSidebar, setExpandedCode]);

  return (
    <hot-sidebar
      class="hot-sidebar--left"
      id="sandboxCodeMobileSidebar"
      {...sidebarProps}
    >
      <hot-sidebar-header>
        <S.LabelWrapper>
          <S.Label> Code </S.Label>
        </S.LabelWrapper>
      </hot-sidebar-header>
      <hot-sidebar-body style={{ overflowY: 'auto' }}>
        <S.CodePanel>
          <CopyPasteContainer
            iconAbsolutePosition
            content={renderedCode}
            renderToastCopiedText={false}
            colorWhite
          >
            <S.Code>
              <S.Pre>
                {imports.map((item, index) => (
                  <Fragment key={index}>
                    {item}
                    <br />
                  </Fragment>
                ))}
                <br />
                {renderedComponent}
              </S.Pre>
            </S.Code>
          </CopyPasteContainer>
        </S.CodePanel>
      </hot-sidebar-body>
    </hot-sidebar>
  );
}
