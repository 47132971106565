import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  Banner,
  FoundationMenu,
  OtherDocumentations,
  CopyPasteContainer,
} from 'components';

export default function ObjectStylePage() {
  const { data: language } = useSelector((state) => state.language);

  const objectStyle = language.objectStyle;
  const borderRadius = objectStyle.borderRadius;
  const shadow = objectStyle.shadow;
  const otherDocumentations = objectStyle.otherDocumentations;
  const titles = [borderRadius.title, shadow.title];

  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8"> {objectStyle.title} </h1>
          <p className="_pb-7 _mb-0">{objectStyle.subtitle}</p>
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <div className="_pt-7 _pb-7" id={borderRadius.title}>
            <h2 className="_font-weight-bold _text-4">{borderRadius.title}</h2>
            <p>{borderRadius.subtitle}</p>
            <table className="hot-table hot-table--card _mb-0">
              <thead>
                <tr>
                  <th>{borderRadius.table.variables}</th>
                  <th>{borderRadius.table.value}</th>
                  <th>px</th>
                  <th>{borderRadius.table.example}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer
                      iconInlinePosition
                      content="--border-radius-sm"
                    >
                      <code className="_text-nowrap">--border-radius-sm</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">calc(var(--spacer-1) / 2)</td>
                  <td className="_bg-white">2px</td>
                  <td className="_bg-white">
                    <div className="_d-block _w-8 _h-5 _bg-purple _rounded-sm"></div>
                  </td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer
                      iconInlinePosition
                      content="--border-radius"
                    >
                      <code className="_text-nowrap">--border-radius</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">var(--spacer-1)</td>
                  <td className="_bg-white">4px</td>
                  <td className="_bg-white">
                    <div className="_d-block _w-8 _h-5 _bg-purple _rounded"></div>
                  </td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer
                      iconInlinePosition
                      content="--border-radius-lg"
                    >
                      <code className="_text-nowrap">--border-radius-lg</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">var(--spacer-2)</td>
                  <td className="_bg-white">8px</td>
                  <td className="_bg-white">
                    <div className="_d-block _w-8 _h-5 _bg-purple _rounded-lg"></div>
                  </td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer
                      iconInlinePosition
                      content="--border-radius-pill"
                    >
                      <code className="_text-nowrap">--border-radius-pill</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">var(--spacer-8)</td>
                  <td className="_bg-white">48px</td>
                  <td className="_bg-white">
                    <div className="_d-block _w-8 _h-5 _bg-purple _rounded-pill"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="_pt-7 _pb-7" id={shadow.title}>
            <h2 className="_font-weight-bold _text-4">{shadow.title}</h2>
            <p>{shadow.subtitle}</p>
            <div className="hot-row">
              <div className="hot-col-sm-4 _mb-4 _mb-sm-0">
                <CopyPasteContainer iconAbsolutePosition content="--shadow-sm">
                  <hot-card class="_shadow-sm _rounded-lg">
                    <hot-card-header>
                      <p className="_mb-0 _font-weight-bold">Small</p>
                    </hot-card-header>
                    <hot-card-footer>
                      <code className="_text-nowrap">--shadow-sm</code>
                    </hot-card-footer>
                  </hot-card>
                </CopyPasteContainer>
              </div>
              <div className="hot-col-sm-4 _mb-4 _mb-sm-0">
                <CopyPasteContainer iconAbsolutePosition content="--shadow">
                  <hot-card class="_shadow _rounded-lg">
                    <hot-card-header>
                      <p className="_mb-0 _font-weight-bold">Medium</p>
                    </hot-card-header>
                    <hot-card-footer>
                      <code className="_text-nowrap">--shadow</code>
                    </hot-card-footer>
                  </hot-card>
                </CopyPasteContainer>
              </div>
              <div className="hot-col-sm-4 _mb-4 _mb-sm-0">
                <CopyPasteContainer iconAbsolutePosition content="--shadow-lg">
                  <hot-card class="_shadow-lg _rounded-lg">
                    <hot-card-header>
                      <p className="_mb-0 _font-weight-bold">Large</p>
                    </hot-card-header>
                    <hot-card-footer>
                      <code className="_text-nowrap">--shadow-lg</code>
                    </hot-card-footer>
                  </hot-card>
                </CopyPasteContainer>
              </div>
            </div>
          </div>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={otherDocumentations} />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </div>
  );
}
