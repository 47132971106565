import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Banner,
  FoundationMenu,
  OtherDocumentations,
  CopyPasteContainer,
} from 'components';

const Content = ({ data }) => {
  const [language, setLanguage] = useState({});
  const [menu, setMenu] = useState([]);

  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  useEffect(() => {
    if (data) {
      setLanguage(data.utilities);
      setMenu([
        data.utilities.align.title,
        data.utilities.background.title,
        data.utilities.border.title,
        data.utilities.clearfix.title,
        data.utilities.display.title,
        data.utilities.flex.title,
        data.utilities.float.title,
        data.utilities.overflow.title,
        data.utilities.position.title,
        data.utilities.screenreaders.title,
        data.utilities.scroll.title,
        data.utilities.shadow.title,
        data.utilities.sizing.title,
        data.utilities.spacing.title,
        data.utilities.stretchedLink.title,
        data.utilities.text.title,
        data.utilities.visibility.title,
      ]);
    }
  }, [data]);

  return (
    <div className="hot-container _p-5 _p-lg-8">
      {!!language && (
        <>
          <h1 className="_font-weight-bold _text-8">{data.utilities.title}</h1>
          <div className="hot-row">
            <div className="hot-col-12 hot-col-sm-9">
              <p className="_mb-5 _mb-lg-8">{data.utilities.subtitle}</p>
            </div>
          </div>

          <div className="hot-row _justify-content-between">
            <div className="hot-col-12 hot-col-sm-9">
              <div className="_mb-8">
                <h4
                  className="_font-weight-bold"
                  id={data.utilities.align.title}
                >
                  {data.utilities.align.title}
                </h4>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Align</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/align.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/align.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-0">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-baseline"
                            >
                              <code className="_text-nowrap">
                                _align-baseline
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            vertical-align: baseline
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-top"
                            >
                              <code className="_text-nowrap">_align-top</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">vertical-align: top</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-middle"
                            >
                              <code className="_text-nowrap">
                                _align-middle
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">vertical-align: middle</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-bottom"
                            >
                              <code className="_text-nowrap">
                                _align-bottom
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">vertical-align: bottom</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-text-bottom"
                            >
                              <code className="_text-nowrap">
                                _align-text-bottom
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            vertical-align: text-bottom
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-text-top"
                            >
                              <code className="_text-nowrap">
                                _align-text-top
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            vertical-align: text-top
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>
              </div>

              <hr className="_my-5 _my-lg-8" />

              <div className="_mb-8">
                <h4
                  className="_font-weight-bold _mb-8"
                  id={data.utilities.background.title}
                >
                  {data.utilities.background.title}
                </h4>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Blue</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/background/variations/blue.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/background/variations/blue.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-blue-lightest"
                            >
                              <code className="_text-nowrap">
                                _bg-blue-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--blue-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-blue-lighter"
                            >
                              <code className="_text-nowrap">
                                _bg-blue-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--blue-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-blue-light"
                            >
                              <code className="_text-nowrap">
                                _bg-blue-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--blue-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-blue"
                            >
                              <code className="_text-nowrap">_bg-blue</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--blue)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-blue-dark"
                            >
                              <code className="_text-nowrap">
                                _bg-blue-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--blue-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-blue-darker"
                            >
                              <code className="_text-nowrap">
                                _bg-blue-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--blue-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Green</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/background/variations/green.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/background/variations/green.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-green-lightest"
                            >
                              <code className="_text-nowrap">
                                _bg-green-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--green-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-green-lighter"
                            >
                              <code className="_text-nowrap">
                                _bg-green-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--green-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-green-light"
                            >
                              <code className="_text-nowrap">
                                _bg-green-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--green-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-green"
                            >
                              <code className="_text-nowrap">_bg-green</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--green)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-green-dark"
                            >
                              <code className="_text-nowrap">
                                _bg-green-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--green-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-green-darker"
                            >
                              <code className="_text-nowrap">
                                _bg-green-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--green-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Yellow</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/background/variations/yellow.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/background/variations/yellow.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-yellow-lightest"
                            >
                              <code className="_text-nowrap">
                                _bg-yellow-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--yellow-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-yellow-lighter"
                            >
                              <code className="_text-nowrap">
                                _bg-yellow-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--yellow-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-yellow-light"
                            >
                              <code className="_text-nowrap">
                                _bg-yellow-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--yellow-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-yellow"
                            >
                              <code className="_text-nowrap">_bg-yellow</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--yellow)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-yellow-dark"
                            >
                              <code className="_text-nowrap">
                                _bg-yellow-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--yellow-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-yellow-darker"
                            >
                              <code className="_text-nowrap">
                                _bg-yellow-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--yellow-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Red</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/background/variations/red.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/background/variations/red.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-red-lightest"
                            >
                              <code className="_text-nowrap">
                                _bg-red-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--red-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-red-lighter"
                            >
                              <code className="_text-nowrap">
                                _bg-red-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--red-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-red-light"
                            >
                              <code className="_text-nowrap">
                                _bg-red-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--red-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-red"
                            >
                              <code className="_text-nowrap">_bg-red</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--red)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-red-dark"
                            >
                              <code className="_text-nowrap">_bg-red-dark</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--red-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-red-darker"
                            >
                              <code className="_text-nowrap">
                                _bg-red-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--red-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Purple</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/background/variations/purple.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/background/variations/purple.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-purple-lightest"
                            >
                              <code className="_text-nowrap">
                                _bg-purple-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--purple-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-purple-lighter"
                            >
                              <code className="_text-nowrap">
                                _bg-purple-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--purple-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-purple-light"
                            >
                              <code className="_text-nowrap">
                                _bg-purple-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--purple-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-purple"
                            >
                              <code className="_text-nowrap">_bg-purple</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--purple)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-purple-dark"
                            >
                              <code className="_text-nowrap">
                                _bg-purple-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--purple-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-purple-darker"
                            >
                              <code className="_text-nowrap">
                                _bg-purple-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--purple-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Cyan</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/background/variations/cyan.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/background/variations/cyan.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-cyan-lightest"
                            >
                              <code className="_text-nowrap">
                                _bg-cyan-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--cyan-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-cyan-lighter"
                            >
                              <code className="_text-nowrap">
                                _bg-cyan-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--cyan-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-cyan-light"
                            >
                              <code className="_text-nowrap">
                                _bg-cyan-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--cyan-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-cyan"
                            >
                              <code className="_text-nowrap">_bg-cyan</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--cyan)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-cyan-dark"
                            >
                              <code className="_text-nowrap">
                                _bg-cyan-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--cyan-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-cyan-darker"
                            >
                              <code className="_text-nowrap">
                                _bg-cyan-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--cyan-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Grays</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/background/variations/grays.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/background/variations/grays.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-gray-100"
                            >
                              <code className="_text-nowrap">_bg-gray-100</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--gray-100)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-gray-200"
                            >
                              <code className="_text-nowrap">_bg-gray-200</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--gray-200)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-gray-300"
                            >
                              <code className="_text-nowrap">_bg-gray-300</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--gray-300)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-gray-400"
                            >
                              <code className="_text-nowrap">_bg-gray-400</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--gray-400)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-gray-500"
                            >
                              <code className="_text-nowrap">_bg-gray-500</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--gray-500)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-gray-600"
                            >
                              <code className="_text-nowrap">_bg-gray-600</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--gray-600)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-gray-700"
                            >
                              <code className="_text-nowrap">_bg-gray-700</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--gray-700)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-gray-800"
                            >
                              <code className="_text-nowrap">_bg-gray-800</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--gray-800)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-gray-900"
                            >
                              <code className="_text-nowrap">_bg-gray-900</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--gray-900)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-white"
                            >
                              <code className="_text-nowrap">_bg-white</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--white)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-black"
                            >
                              <code className="_text-nowrap">_bg-black</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--black)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Brand Primary</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/background/variations/brand-primary.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/background/variations/brand-primary.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-brand-primary-lightest"
                            >
                              <code className="_text-nowrap">
                                _bg-brand-primary-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--brand-primary-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-brand-primary-lighter"
                            >
                              <code className="_text-nowrap">
                                _bg-brand-primary-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--brand-primary-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-brand-primary-light"
                            >
                              <code className="_text-nowrap">
                                _bg-brand-primary-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--brand-primary-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-brand-primary"
                            >
                              <code className="_text-nowrap">
                                _bg-brand-primary
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--brand-primary)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-brand-primary-dark"
                            >
                              <code className="_text-nowrap">
                                _bg-brand-primary-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--brand-primary-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-brand-primary-darker"
                            >
                              <code className="_text-nowrap">
                                _bg-brand-primary-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--brand-primary-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Brand Secondary</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/background/variations/brand-secondary.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/background/variations/brand-secondary.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-brand-secondary-lightest"
                            >
                              <code className="_text-nowrap">
                                _bg-brand-secondary-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--brand-secondary-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-brand-secondary-lighter"
                            >
                              <code className="_text-nowrap">
                                _bg-brand-secondary-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--brand-secondary-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-brand-secondary-light"
                            >
                              <code className="_text-nowrap">
                                _bg-brand-secondary-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--brand-secondary-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-brand-secondary"
                            >
                              <code className="_text-nowrap">
                                _bg-brand-secondary
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--brand-secondary)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-brand-secondary-dark"
                            >
                              <code className="_text-nowrap">
                                _bg-brand-secondary-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--brand-secondary-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-brand-secondary-darker"
                            >
                              <code className="_text-nowrap">
                                _bg-brand-secondary-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--brand-secondary-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Primary</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/background/variations/primary.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/background/variations/primary.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-0">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-primary-lightest"
                            >
                              <code className="_text-nowrap">
                                _bg-primary-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--primary-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-primary-lighter"
                            >
                              <code className="_text-nowrap">
                                _bg-primary-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--primary-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-primary-light"
                            >
                              <code className="_text-nowrap">
                                _bg-primary-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--primary-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-primary"
                            >
                              <code className="_text-nowrap">_bg-primary</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--primary)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-primary-dark"
                            >
                              <code className="_text-nowrap">
                                _bg-primary-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--primary-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_bg-primary-darker"
                            >
                              <code className="_text-nowrap">
                                _bg-primary-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: var(--primary-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>
              </div>

              <hr className="_my-5 _my-lg-8" />

              <div className="_mb-8">
                <h4
                  className="_font-weight-bold _mb-8"
                  id={data.utilities.border.title}
                >
                  {data.utilities.border.title}
                </h4>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Border Width</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/border/border.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/border/border.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]"
                            >
                              <code className="_text-nowrap">
                                _border-[BREAKPOINT]
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border: var(--component-border-width) solid
                            var(--component-border-color)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]-0"
                            >
                              <code className="_text-nowrap">
                                _border-[BREAKPOINT]-0
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">border: 0</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]-top"
                            >
                              <code className="_text-nowrap">
                                _border-[BREAKPOINT]-top
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-top: var(--component-border-width) solid
                            var(--component-border-color)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]-top-0"
                            >
                              <code className="_text-nowrap">
                                _border-[BREAKPOINT]-top-0
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">border-top: 0</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]-right"
                            >
                              <code className="_text-nowrap">
                                _border-[BREAKPOINT]-right
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-right: var(--component-border-width) solid
                            var(--component-border-color)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]-right-0"
                            >
                              <code className="_text-nowrap">
                                _border-[BREAKPOINT]-right-0
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">border-right: 0</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]-bottom"
                            >
                              <code className="_text-nowrap">
                                _border-[BREAKPOINT]-bottom
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-bottom: var(--component-border-width) solid
                            var(--component-border-color)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]-bottom-0"
                            >
                              <code className="_text-nowrap">
                                _border-[BREAKPOINT]-bottom-0
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">border-bottom: 0</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]-left"
                            >
                              <code className="_text-nowrap">
                                _border-[BREAKPOINT]-left
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-left: var(--component-border-width) solid
                            var(--component-border-color)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]-left-0"
                            >
                              <code className="_text-nowrap">
                                _border-[BREAKPOINT]-left-0
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">border-left: 0</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Border Radius</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/border/border.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/border/border.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]"
                            >
                              <code className="_text-nowrap">
                                _rounded-[BREAKPOINT]
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-radius: var(--border-radius)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]-0"
                            >
                              <code className="_text-nowrap">
                                _rounded-[BREAKPOINT]-0
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">border-radius: 0</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]-sm"
                            >
                              <code className="_text-nowrap">
                                _rounded-[BREAKPOINT]-sm
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-radius: var(--border-radius-sm)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]-lg"
                            >
                              <code className="_text-nowrap">
                                _rounded-[BREAKPOINT]-lg
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-radius: var(--border-radius-lg)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]-circle"
                            >
                              <code className="_text-nowrap">
                                _rounded-[BREAKPOINT]-circle
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">border-radius: 50%</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]-pill"
                            >
                              <code className="_text-nowrap">
                                _rounded-[BREAKPOINT]-pill
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-radius: var(--spacer-8)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]-top"
                            >
                              <code className="_text-nowrap">
                                _rounded-[BREAKPOINT]-top
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-top-left-radius: var(--border-radius)
                            <br />
                            border-top-right-radius: var(--border-radius)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]-right"
                            >
                              <code className="_text-nowrap">
                                _rounded-[BREAKPOINT]-right
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-bottom-right-radius: var(--border-radius)
                            <br />
                            border-top-right-radius: var(--border-radius)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]-bottom"
                            >
                              <code className="_text-nowrap">
                                _rounded-[BREAKPOINT]-bottom
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-bottom-left-radius: var(--border-radius)
                            <br />
                            border-bottom-right-radius: var(--border-radius)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-[BREAKPOINT]-left"
                            >
                              <code className="_text-nowrap">
                                _rounded-[BREAKPOINT]-left
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-bottom-left-radius: var(--border-radius)
                            <br />
                            border-top-left-radius: var(--border-radius)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Blue</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/border/variations/blue.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/border/variations/blue.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-blue-lightest"
                            >
                              <code className="_text-nowrap">
                                _border-blue-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--blue-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-blue-lighter"
                            >
                              <code className="_text-nowrap">
                                _border-blue-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--blue-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-blue-light"
                            >
                              <code className="_text-nowrap">
                                _border-blue-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--blue-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-blue"
                            >
                              <code className="_text-nowrap">_border-blue</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--blue)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-blue-dark"
                            >
                              <code className="_text-nowrap">
                                _border-blue-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--blue-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-blue-darker"
                            >
                              <code className="_text-nowrap">
                                _border-blue-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--blue-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Green</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/border/variations/green.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/border/variations/green.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-green-lightest"
                            >
                              <code className="_text-nowrap">
                                _border-green-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--green-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-green-lighter"
                            >
                              <code className="_text-nowrap">
                                _border-green-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--green-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-green-light"
                            >
                              <code className="_text-nowrap">
                                _border-green-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--green-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-green"
                            >
                              <code className="_text-nowrap">
                                _border-green
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--green)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-green-dark"
                            >
                              <code className="_text-nowrap">
                                _border-green-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--green-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-green-darker"
                            >
                              <code className="_text-nowrap">
                                _border-green-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--green-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Yellow</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/border/variations/yellow.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/border/variations/yellow.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-yellow-lightest"
                            >
                              <code className="_text-nowrap">
                                _border-yellow-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--yellow-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-yellow-lighter"
                            >
                              <code className="_text-nowrap">
                                _border-yellow-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--yellow-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-yellow-light"
                            >
                              <code className="_text-nowrap">
                                _border-yellow-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--yellow-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-yellow"
                            >
                              <code className="_text-nowrap">
                                _border-yellow
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--yellow)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-yellow-dark"
                            >
                              <code className="_text-nowrap">
                                _border-yellow-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--yellow-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-yellow-darker"
                            >
                              <code className="_text-nowrap">
                                _border-yellow-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--yellow-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Red</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/border/variations/red.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/border/variations/red.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-red-lightest"
                            >
                              <code className="_text-nowrap">
                                _border-red-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--red-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-red-lighter"
                            >
                              <code className="_text-nowrap">
                                _border-red-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--red-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-red-light"
                            >
                              <code className="_text-nowrap">
                                _border-red-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--red-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-red"
                            >
                              <code className="_text-nowrap">_border-red</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--red)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-red-dark"
                            >
                              <code className="_text-nowrap">
                                _border-red-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--red-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-red-darker"
                            >
                              <code className="_text-nowrap">
                                _border-red-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--red-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Purple</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/border/variations/purple.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/border/variations/purple.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-purple-lightest"
                            >
                              <code className="_text-nowrap">
                                _border-purple-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--purple-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-purple-lighter"
                            >
                              <code className="_text-nowrap">
                                _border-purple-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--purple-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-purple-light"
                            >
                              <code className="_text-nowrap">
                                _border-purple-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--purple-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-purple"
                            >
                              <code className="_text-nowrap">
                                _border-purple
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--purple)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-purple-dark"
                            >
                              <code className="_text-nowrap">
                                _border-purple-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--purple-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-purple-darker"
                            >
                              <code className="_text-nowrap">
                                _border-purple-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--purple-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Cyan</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/border/variations/cyan.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/border/variations/cyan.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-cyan-lightest"
                            >
                              <code className="_text-nowrap">
                                _border-cyan-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--cyan-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-cyan-lighter"
                            >
                              <code className="_text-nowrap">
                                _border-cyan-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--cyan-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-cyan-light"
                            >
                              <code className="_text-nowrap">
                                _border-cyan-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--cyan-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-cyan"
                            >
                              <code className="_text-nowrap">_border-cyan</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--cyan)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-cyan-dark"
                            >
                              <code className="_text-nowrap">
                                _border-cyan-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--cyan-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-cyan-darker"
                            >
                              <code className="_text-nowrap">
                                _border-cyan-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--cyan-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Grays</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/border/variations/grays.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/border/variations/grays.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-gray-100"
                            >
                              <code className="_text-nowrap">
                                _border-gray-100
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--gray-100)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-gray-200"
                            >
                              <code className="_text-nowrap">
                                _border-gray-200
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--gray-200)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-gray-300"
                            >
                              <code className="_text-nowrap">
                                _border-gray-300
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--gray-300)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-gray-400"
                            >
                              <code className="_text-nowrap">
                                _border-gray-400
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--gray-400)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-gray-500"
                            >
                              <code className="_text-nowrap">
                                _border-gray-500
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--gray-500)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-gray-600"
                            >
                              <code className="_text-nowrap">
                                _border-gray-600
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--gray-600)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-gray-700"
                            >
                              <code className="_text-nowrap">
                                _border-gray-700
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--gray-700)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-gray-800"
                            >
                              <code className="_text-nowrap">
                                _border-gray-800
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--gray-800)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-gray-900"
                            >
                              <code className="_text-nowrap">
                                _border-gray-900
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--gray-900)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-white"
                            >
                              <code className="_text-nowrap">
                                _border-white
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--white)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-black"
                            >
                              <code className="_text-nowrap">
                                _border-black
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--black)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Brand Primary</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/border/variations/brand-primary.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/border/variations/brand-primary.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-brand-primary-lightest"
                            >
                              <code className="_text-nowrap">
                                _border-brand-primary-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--brand-primary-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-brand-primary-lighter"
                            >
                              <code className="_text-nowrap">
                                _border-brand-primary-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--brand-primary-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-brand-primary-light"
                            >
                              <code className="_text-nowrap">
                                _border-brand-primary-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--brand-primary-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-brand-primary"
                            >
                              <code className="_text-nowrap">
                                _border-brand-primary
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--brand-primary)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-brand-primary-dark"
                            >
                              <code className="_text-nowrap">
                                _border-brand-primary-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--brand-primary-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-brand-primary-darker"
                            >
                              <code className="_text-nowrap">
                                _border-brand-primary-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--brand-primary-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Brand Secondary</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/border/variations/brand-secondary.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/border/variations/brand-secondary.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-brand-secondary-lightest"
                            >
                              <code className="_text-nowrap">
                                _border-brand-secondary-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--brand-secondary-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-brand-secondary-lighter"
                            >
                              <code className="_text-nowrap">
                                _border-brand-secondary-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--brand-secondary-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-brand-secondary-light"
                            >
                              <code className="_text-nowrap">
                                _border-brand-secondary-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--brand-secondary-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-brand-secondary"
                            >
                              <code className="_text-nowrap">
                                _border-brand-secondary
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--brand-secondary)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-brand-secondary-dark"
                            >
                              <code className="_text-nowrap">
                                _border-brand-secondary-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--brand-secondary-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-brand-secondary-darker"
                            >
                              <code className="_text-nowrap">
                                _border-brand-secondary-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--brand-secondary-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Primary</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/border/variations/primary.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/border/variations/primary.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-0">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-primary-lightest"
                            >
                              <code className="_text-nowrap">
                                _border-primary-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--primary-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-primary-lighter"
                            >
                              <code className="_text-nowrap">
                                _border-primary-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--primary-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-primary-light"
                            >
                              <code className="_text-nowrap">
                                _border-primary-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--primary-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-primary"
                            >
                              <code className="_text-nowrap">
                                _border-primary
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--primary)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-primary-dark"
                            >
                              <code className="_text-nowrap">
                                _border-primary-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--primary-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_border-primary-darker"
                            >
                              <code className="_text-nowrap">
                                _border-primary-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border-color: var(--primary-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>
              </div>

              <hr className="_my-5 _my-lg-8" />

              <div className="_mb-8">
                <h4
                  className="_font-weight-bold _mb-8"
                  id={data.utilities.clearfix.title}
                >
                  {data.utilities.clearfix.title}
                </h4>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Clearfix</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/clearfix.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/clearfix.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-0">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_clearfix"
                            >
                              <code className="_text-nowrap">_clearfix</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            clear: both;
                            <br />
                            content: "";
                            <br />
                            display: block;
                            <br />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>
              </div>

              <hr className="_my-5 _my-lg-8" />

              <div className="_mb-8">
                <h4
                  className="_font-weight-bold _mb-8"
                  id={data.utilities.display.title}
                >
                  {data.utilities.display.title}
                </h4>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Display</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/display/display.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/display/display.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-0">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_d-[BREAKPOINT]-none"
                            >
                              <code className="_text-nowrap">
                                _d-[BREAKPOINT]-none
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">display: none</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_d-[BREAKPOINT]-inline"
                            >
                              <code className="_text-nowrap">
                                _d-[BREAKPOINT]-inline
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">display: inline</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_d-[BREAKPOINT]-inline-block"
                            >
                              <code className="_text-nowrap">
                                _d-[BREAKPOINT]-inline-block
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">display: inline-block</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_d-[BREAKPOINT]-block"
                            >
                              <code className="_text-nowrap">
                                _d-[BREAKPOINT]-block
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">display: block</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_d-[BREAKPOINT]-table"
                            >
                              <code className="_text-nowrap">
                                _d-[BREAKPOINT]-table
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">display: table</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_d-[BREAKPOINT]-table-row"
                            >
                              <code className="_text-nowrap">
                                _d-[BREAKPOINT]-table-row
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">display: table-row</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_d-[BREAKPOINT]-table-cell"
                            >
                              <code className="_text-nowrap">
                                _d-[BREAKPOINT]-table-cell
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">display: table-cell</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_d-[BREAKPOINT]-flex"
                            >
                              <code className="_text-nowrap">
                                _d-[BREAKPOINT]-flex
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">display: flex</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_d-[BREAKPOINT]-inline-flex"
                            >
                              <code className="_text-nowrap">
                                _d-[BREAKPOINT]-inline-flex
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">display: inline-flex</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>
              </div>

              <hr className="_my-5 _my-lg-8" />

              <div className="_mb-8">
                <h4
                  className="_font-weight-bold _mb-8"
                  id={data.utilities.flex.title}
                >
                  {data.utilities.flex.title}
                </h4>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Flex</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/flex/flex.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/flex/flex.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_flex-[BREAKPOINT]-row"
                            >
                              <code className="_text-nowrap">
                                _flex-[BREAKPOINT]-row
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">flex-direction: row</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_flex-[BREAKPOINT]-row-reverse"
                            >
                              <code className="_text-nowrap">
                                _flex-[BREAKPOINT]-row-reverse
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            flex-direction: row-reverse
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_flex-[BREAKPOINT]-column"
                            >
                              <code className="_text-nowrap">
                                _flex-[BREAKPOINT]-column
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">flex-direction: column</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_flex-[BREAKPOINT]-column-reverse"
                            >
                              <code className="_text-nowrap">
                                _flex-[BREAKPOINT]-column-reverse
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            flex-direction: column-reverse
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_flex-[BREAKPOINT]-wrap"
                            >
                              <code className="_text-nowrap">
                                _flex-[BREAKPOINT]-wrap
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">flex-wrap: wrap</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_flex-[BREAKPOINT]-wrap-reverse"
                            >
                              <code className="_text-nowrap">
                                _flex-[BREAKPOINT]-wrap-reverse
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">flex-wrap: wrap-reverse</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_flex-[BREAKPOINT]-nowrap"
                            >
                              <code className="_text-nowrap">
                                _flex-[BREAKPOINT]-nowrap
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">flex-wrap: nowrap</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_flex-[BREAKPOINT]-1"
                            >
                              <code className="_text-nowrap">
                                _flex-[BREAKPOINT]-1
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">flex: 1</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_flex-[BREAKPOINT]-fill"
                            >
                              <code className="_text-nowrap">
                                _flex-[BREAKPOINT]-fill
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">flex: 1 1 auto</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_flex-[BREAKPOINT]-grow-0"
                            >
                              <code className="_text-nowrap">
                                _flex-[BREAKPOINT]-grow-0
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">flex-grow: 0</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_flex-[BREAKPOINT]-grow-1"
                            >
                              <code className="_text-nowrap">
                                _flex-[BREAKPOINT]-grow-1
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">flex-grow: 1</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_flex-[BREAKPOINT]-shrink-0"
                            >
                              <code className="_text-nowrap">
                                _flex-[BREAKPOINT]-shrink-0
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">flex-shrink: 0</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_flex-[BREAKPOINT]-shrink-1"
                            >
                              <code className="_text-nowrap">
                                _flex-[BREAKPOINT]-shrink-1
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">flex-shrink: 1</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Justify Content</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/flex/flex.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/flex/flex.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_justify-content-[BREAKPOINT]-start"
                            >
                              <code className="_text-nowrap">
                                _justify-content-[BREAKPOINT]-start
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            justify-content: flex-start
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_justify-content-[BREAKPOINT]-end"
                            >
                              <code className="_text-nowrap">
                                _justify-content-[BREAKPOINT]-end
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            justify-content: flex-end
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_justify-content-[BREAKPOINT]-center"
                            >
                              <code className="_text-nowrap">
                                _justify-content-[BREAKPOINT]-center
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">justify-content: center</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_justify-content-[BREAKPOINT]-between"
                            >
                              <code className="_text-nowrap">
                                _justify-content-[BREAKPOINT]-between
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            justify-content: space-between
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_justify-content-[BREAKPOINT]-around"
                            >
                              <code className="_text-nowrap">
                                _justify-content-[BREAKPOINT]-around
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            justify-content: space-around
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Justify Self</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/flex/flex.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/flex/flex.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_justify-self-[BREAKPOINT]-auto"
                            >
                              <code className="_text-nowrap">
                                _justify-self-[BREAKPOINT]-auto
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">justify-self: auto</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_justify-self-[BREAKPOINT]-start"
                            >
                              <code className="_text-nowrap">
                                _justify-self-[BREAKPOINT]-start
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            justify-self: flex-start
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_justify-self-[BREAKPOINT]-end"
                            >
                              <code className="_text-nowrap">
                                _justify-self-[BREAKPOINT]-end
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">justify-self: flex-end</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_justify-self-[BREAKPOINT]-center"
                            >
                              <code className="_text-nowrap">
                                _justify-self-[BREAKPOINT]-center
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">justify-self: center</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_justify-self-[BREAKPOINT]-baseline"
                            >
                              <code className="_text-nowrap">
                                _justify-self-[BREAKPOINT]-baseline
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">justify-self: baseline</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_justify-self-[BREAKPOINT]-stretch"
                            >
                              <code className="_text-nowrap">
                                _justify-self-[BREAKPOINT]-stretch
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">justify-self: stretch</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Align Items</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/flex/flex.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/flex/flex.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-items-[BREAKPOINT]-start"
                            >
                              <code className="_text-nowrap">
                                _align-items-[BREAKPOINT]-start
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">align-items: flex-start</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-items-[BREAKPOINT]-end"
                            >
                              <code className="_text-nowrap">
                                _align-items-[BREAKPOINT]-end
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">align-items: flex-end</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-items-[BREAKPOINT]-center"
                            >
                              <code className="_text-nowrap">
                                _align-items-[BREAKPOINT]-center
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">align-items: center</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-items-[BREAKPOINT]-baseline"
                            >
                              <code className="_text-nowrap">
                                _align-items-[BREAKPOINT]-baseline
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">align-items: baseline</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-items-[BREAKPOINT]-stretch"
                            >
                              <code className="_text-nowrap">
                                _align-items-[BREAKPOINT]-stretch
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">align-items: stretch</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Align Content</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/flex/flex.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/flex/flex.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-content-[BREAKPOINT]-start"
                            >
                              <code className="_text-nowrap">
                                _align-content-[BREAKPOINT]-start
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            align-content: flex-start
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-content-[BREAKPOINT]-end"
                            >
                              <code className="_text-nowrap">
                                _align-content-[BREAKPOINT]-end
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">align-content: flex-end</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-content-[BREAKPOINT]-center"
                            >
                              <code className="_text-nowrap">
                                _align-content-[BREAKPOINT]-center
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">align-content: center</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-content-[BREAKPOINT]-between"
                            >
                              <code className="_text-nowrap">
                                _align-content-[BREAKPOINT]-between
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            align-content: space-between
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-content-[BREAKPOINT]-around"
                            >
                              <code className="_text-nowrap">
                                _align-content-[BREAKPOINT]-around
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            align-content: space-around
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-content-[BREAKPOINT]-stretch"
                            >
                              <code className="_text-nowrap">
                                _align-content-[BREAKPOINT]-stretch
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">align-content: stretch</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Align Self</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/flex/flex.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/flex/flex.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-0">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-self-[BREAKPOINT]-auto"
                            >
                              <code className="_text-nowrap">
                                _align-self-[BREAKPOINT]-auto
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">align-self: auto</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-self-[BREAKPOINT]-start"
                            >
                              <code className="_text-nowrap">
                                _align-self-[BREAKPOINT]-start
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">align-self: flex-start</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-self-[BREAKPOINT]-end"
                            >
                              <code className="_text-nowrap">
                                _align-self-[BREAKPOINT]-end
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">align-self: flex-end</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-self-[BREAKPOINT]-center"
                            >
                              <code className="_text-nowrap">
                                _align-self-[BREAKPOINT]-center
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">align-self: center</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-self-[BREAKPOINT]-baseline"
                            >
                              <code className="_text-nowrap">
                                _align-self-[BREAKPOINT]-baseline
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">align-self: baseline</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_align-self-[BREAKPOINT]-stretch"
                            >
                              <code className="_text-nowrap">
                                _align-self-[BREAKPOINT]-stretch
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">align-self: stretch</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>
              </div>

              <hr className="_my-5 _my-lg-8" />

              <div className="_mb-8">
                <h4
                  className="_font-weight-bold _mb-8"
                  id={data.utilities.float.title}
                >
                  {data.utilities.float.title}
                </h4>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Float</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/float/float.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/float/float.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-0">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_float-[BREAKPOINT]-left"
                            >
                              <code className="_text-nowrap">
                                _float-[BREAKPOINT]-left
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">float: left</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_float-[BREAKPOINT]-right"
                            >
                              <code className="_text-nowrap">
                                _float-[BREAKPOINT]-right
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">float: right</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_float-[BREAKPOINT]-none"
                            >
                              <code className="_text-nowrap">
                                _float-[BREAKPOINT]-none
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">float: none</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>
              </div>

              <hr className="_my-5 _my-lg-8" />

              <div className="_mb-8">
                <h4
                  className="_font-weight-bold _mb-8"
                  id={data.utilities.overflow.title}
                >
                  {data.utilities.overflow.title}
                </h4>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Overflow</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/overflow/overflow.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/overflow/overflow.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-0">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_overflow-[BREAKPOINT]-visible"
                            >
                              <code className="_text-nowrap">
                                _overflow-[BREAKPOINT]-visible
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">overflow: visible</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_overflow-[BREAKPOINT]-hidden"
                            >
                              <code className="_text-nowrap">
                                _overflow-[BREAKPOINT]-hidden
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">overflow: hidden</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_overflow-[BREAKPOINT]-scroll"
                            >
                              <code className="_text-nowrap">
                                _overflow-[BREAKPOINT]-scroll
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">overflow: scroll</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_overflow-[BREAKPOINT]-auto"
                            >
                              <code className="_text-nowrap">
                                _overflow-[BREAKPOINT]-auto
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">overflow: auto</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_overflow-x-[BREAKPOINT]-visible"
                            >
                              <code className="_text-nowrap">
                                _overflow-x-[BREAKPOINT]-visible
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">overflow-x: visible</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_overflow-x-[BREAKPOINT]-hidden"
                            >
                              <code className="_text-nowrap">
                                _overflow-x-[BREAKPOINT]-hidden
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">overflow-x: hidden</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_overflow-x-[BREAKPOINT]-scroll"
                            >
                              <code className="_text-nowrap">
                                _overflow-x-[BREAKPOINT]-scroll
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">overflow-x: scroll</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_overflow-x-[BREAKPOINT]-auto"
                            >
                              <code className="_text-nowrap">
                                _overflow-x-[BREAKPOINT]-auto
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">overflow-x: auto</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_overflow-y-[BREAKPOINT]-visible"
                            >
                              <code className="_text-nowrap">
                                _overflow-y-[BREAKPOINT]-visible
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">overflow-y: visible</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_overflow-y-[BREAKPOINT]-hidden"
                            >
                              <code className="_text-nowrap">
                                _overflow-y-[BREAKPOINT]-hidden
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">overflow-y: hidden</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_overflow-y-[BREAKPOINT]-scroll"
                            >
                              <code className="_text-nowrap">
                                _overflow-y-[BREAKPOINT]-scroll
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">overflow-y: scroll</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_overflow-y-[BREAKPOINT]-auto"
                            >
                              <code className="_text-nowrap">
                                _overflow-y-[BREAKPOINT]-auto
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">overflow-y: auto</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>
              </div>

              <hr className="_my-5 _my-lg-8" />

              <div className="_mb-8">
                <h4
                  className="_font-weight-bold _mb-8"
                  id={data.utilities.position.title}
                >
                  {data.utilities.position.title}
                </h4>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Position</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/position/position.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/position/position.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-0">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_position-[BREAKPOINT]-static"
                            >
                              <code className="_text-nowrap">
                                _position-[BREAKPOINT]-static
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">position: static</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_position-[BREAKPOINT]-relative"
                            >
                              <code className="_text-nowrap">
                                _position-[BREAKPOINT]-relative
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">position: relative</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_position-[BREAKPOINT]-absolute"
                            >
                              <code className="_text-nowrap">
                                _position-[BREAKPOINT]-absolute
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">position: absolute</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_position-[BREAKPOINT]-fixed"
                            >
                              <code className="_text-nowrap">
                                _position-[BREAKPOINT]-fixed
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">position: fixed</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_position-[BREAKPOINT]-sticky"
                            >
                              <code className="_text-nowrap">
                                _position-[BREAKPOINT]-sticky
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">position: sticky</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>
              </div>

              <hr className="_my-5 _my-lg-8" />

              <div className="_mb-8">
                <h4
                  className="_font-weight-bold _mb-8"
                  id={data.utilities.screenreaders.title}
                >
                  {data.utilities.screenreaders.title}
                </h4>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Screenreaders</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/screenreaders.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/screenreaders.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-0">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_sr-only"
                            >
                              <code className="_text-nowrap">_sr-only</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            border: 0;
                            <br />
                            clip: rect(0, 0, 0, 0);
                            <br />
                            height: 1px;
                            <br />
                            overflow: hidden;
                            <br />
                            padding: 0;
                            <br />
                            position: absolute;
                            <br />
                            white-space: nowrap;
                            <br />
                            width: 1px;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>
              </div>

              <hr className="_my-5 _my-lg-8" />

              <div className="_mb-8">
                <h4
                  className="_font-weight-bold _mb-8"
                  id={data.utilities.scroll.title}
                >
                  {data.utilities.scroll.title}
                </h4>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Scroll</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/scroll.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/scroll.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-0">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_hot-scroll"
                            >
                              <code className="_text-nowrap">_hot-scroll</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            display: block;
                            <br />
                            flex: 1;
                            <br />
                            height: 1px;
                            <br />
                            -webkit-overflow-scrolling: touch;
                            <br />
                            -ms-overflow-style: -ms-autohiding-scrollbar;
                            <br />
                            overflow-x: hidden;
                            <br />
                            overflow-y: auto;
                            <br />
                            position: relative;
                            <br />
                            width: 100%;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>
              </div>

              <hr className="_my-5 _my-lg-8" />

              <div className="_mb-8">
                <h4
                  className="_font-weight-bold _mb-8"
                  id={data.utilities.shadow.title}
                >
                  {data.utilities.shadow.title}
                </h4>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Shadow</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/shadow.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/shadow.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-0">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_shadow"
                            >
                              <code className="_text-nowrap">_shadow</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            box-shadow: var(--box-shadow)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_shadow-sm"
                            >
                              <code className="_text-nowrap">_shadow-sm</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            box-shadow: var(--box-shadow-sm)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_shadow-lg"
                            >
                              <code className="_text-nowrap">_shadow-lg</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            box-shadow: var(--box-shadow-lg)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_shadow-none"
                            >
                              <code className="_text-nowrap">_shadow-none</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">box-shadow: none</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>
              </div>

              <hr className="_my-5 _my-lg-8" />

              <div className="_mb-8">
                <h4
                  className="_font-weight-bold _mb-8"
                  id={data.utilities.sizing.title}
                >
                  {data.utilities.sizing.title}
                </h4>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">
                    Width and Height (Spacers)
                  </h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/sizing/sizing.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/sizing/sizing.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_w-[BREAKPOINT]-auto"
                            >
                              <code className="_text-nowrap">
                                _w-[BREAKPOINT]-auto
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">width: auto</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_w-[BREAKPOINT]-[SPACER]"
                            >
                              <code className="_text-nowrap">
                                _w-[BREAKPOINT]-[SPACER]
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            width: var(--spacer-[SPACER])
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_h-[BREAKPOINT]-auto"
                            >
                              <code className="_text-nowrap">
                                _h-[BREAKPOINT]-auto
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">height: auto</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_h-[BREAKPOINT]-[SPACER]"
                            >
                              <code className="_text-nowrap">
                                _h-[BREAKPOINT]-[SPACER]
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            height: var(--spacer-[SPACER])
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">
                    Width and Height (Percentage)
                  </h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/sizing/sizing.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/sizing/sizing.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_w-[BREAKPOINT]-full"
                            >
                              <code className="_text-nowrap">
                                _w-[BREAKPOINT]-full
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">width: 100%</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_w-[BREAKPOINT]-half"
                            >
                              <code className="_text-nowrap">
                                _w-[BREAKPOINT]-half
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">width: 50%</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_h-[BREAKPOINT]-full"
                            >
                              <code className="_text-nowrap">
                                _h-[BREAKPOINT]-full
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">height: 100%</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_h-[BREAKPOINT]-half"
                            >
                              <code className="_text-nowrap">
                                _h-[BREAKPOINT]-half
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">height: 50%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">
                    Min Width and Min Height (Percentage)
                  </h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/sizing/sizing.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/sizing/sizing.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_min-w-[BREAKPOINT]-full"
                            >
                              <code className="_text-nowrap">
                                _min-w-[BREAKPOINT]-full
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">min-width: 100%</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_min-w-[BREAKPOINT]-half"
                            >
                              <code className="_text-nowrap">
                                _min-w-[BREAKPOINT]-half
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">min-width: 50%</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_min-h-[BREAKPOINT]-full"
                            >
                              <code className="_text-nowrap">
                                _min-h-[BREAKPOINT]-full
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">min-height: 100%</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_min-h-[BREAKPOINT]-half"
                            >
                              <code className="_text-nowrap">
                                _min-h-[BREAKPOINT]-half
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">min-height: 50%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">
                    Max Width and Max Height (Percentage)
                  </h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/sizing/sizing.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/sizing/sizing.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_max-w-[BREAKPOINT]-full"
                            >
                              <code className="_text-nowrap">
                                _max-w-[BREAKPOINT]-full
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">max-width: 100%</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_max-w-[BREAKPOINT]-half"
                            >
                              <code className="_text-nowrap">
                                _max-w-[BREAKPOINT]-half
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">max-width: 50%</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_max-h-[BREAKPOINT]-full"
                            >
                              <code className="_text-nowrap">
                                _max-h-[BREAKPOINT]-full
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">max-height: 100%</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_max-h-[BREAKPOINT]-half"
                            >
                              <code className="_text-nowrap">
                                _max-h-[BREAKPOINT]-half
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">max-height: 50%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">
                    Viewport Width and Viewport Height
                  </h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/sizing/sizing.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/sizing/sizing.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_vw-[BREAKPOINT]-full"
                            >
                              <code className="_text-nowrap">
                                _vw-[BREAKPOINT]-full
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">width: 100vw</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_vw-[BREAKPOINT]-half"
                            >
                              <code className="_text-nowrap">
                                _vw-[BREAKPOINT]-half
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">width: 50vw</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_vh-[BREAKPOINT]-full"
                            >
                              <code className="_text-nowrap">
                                _vh-[BREAKPOINT]-full
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">height: 100vh</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_vh-[BREAKPOINT]-half"
                            >
                              <code className="_text-nowrap">
                                _vh-[BREAKPOINT]-half
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">height: 50vh</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">
                    Min Viewport Width and Min Viewport Height
                  </h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/sizing/sizing.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/sizing/sizing.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_min-vw-[BREAKPOINT]-full"
                            >
                              <code className="_text-nowrap">
                                _min-vw-[BREAKPOINT]-full
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">min-width: 100vw</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_min-vw-[BREAKPOINT]-half"
                            >
                              <code className="_text-nowrap">
                                _min-vw-[BREAKPOINT]-half
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">min-width: 50vw</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_min-vh-[BREAKPOINT]-full"
                            >
                              <code className="_text-nowrap">
                                _min-vh-[BREAKPOINT]-full
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">min-height: 100vh</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_min-vh-[BREAKPOINT]-half"
                            >
                              <code className="_text-nowrap">
                                _min-vh-[BREAKPOINT]-half
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">min-height: 50vh</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">
                    Max Viewport Width and Max Viewport Height
                  </h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/sizing/sizing.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/sizing/sizing.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-0">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_max-vw-[BREAKPOINT]-full"
                            >
                              <code className="_text-nowrap">
                                _max-vw-[BREAKPOINT]-full
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">max-width: 100vw</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_max-vw-[BREAKPOINT]-half"
                            >
                              <code className="_text-nowrap">
                                _max-vw-[BREAKPOINT]-half
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">max-width: 50vw</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_max-vh-[BREAKPOINT]-full"
                            >
                              <code className="_text-nowrap">
                                _max-vh-[BREAKPOINT]-full
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">max-height: 100vh</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_max-vh-[BREAKPOINT]-half"
                            >
                              <code className="_text-nowrap">
                                _max-vh-[BREAKPOINT]-half
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">max-height: 50vh</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>
              </div>

              <hr className="_my-5 _my-lg-8" />

              <div className="_mb-8">
                <h4
                  className="_font-weight-bold _mb-8"
                  id={data.utilities.spacing.title}
                >
                  {data.utilities.spacing.title}
                </h4>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Margin</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/spacing/spacing.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/spacing/spacing.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-0">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_m-[BREAKPOINT]-auto"
                            >
                              <code className="_text-nowrap">
                                _m-[BREAKPOINT]-auto
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">margin: auto</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_mt-[BREAKPOINT]-auto"
                            >
                              <code className="_text-nowrap">
                                _mt-[BREAKPOINT]-auto
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">margin-top: auto</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_mr-[BREAKPOINT]-auto"
                            >
                              <code className="_text-nowrap">
                                _mr-[BREAKPOINT]-auto
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">margin-right: auto</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_mb-[BREAKPOINT]-auto"
                            >
                              <code className="_text-nowrap">
                                _mb-[BREAKPOINT]-auto
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">margin-bottom: auto</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_ml-[BREAKPOINT]-auto"
                            >
                              <code className="_text-nowrap">
                                _ml-[BREAKPOINT]-auto
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">margin-left: auto</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_mx-[BREAKPOINT]-auto"
                            >
                              <code className="_text-nowrap">
                                _mx-[BREAKPOINT]-auto
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            margin-left: auto
                            <br />
                            margin-right: auto
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_my-[BREAKPOINT]-auto"
                            >
                              <code className="_text-nowrap">
                                _my-[BREAKPOINT]-auto
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            margin-top: auto
                            <br />
                            margin-bottom: auto
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_m-[BREAKPOINT]-[SPACER]"
                            >
                              <code className="_text-nowrap">
                                _m-[BREAKPOINT]-[SPACER]
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            margin: var(--spacer-[SPACER])
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_mt-[BREAKPOINT]-[SPACER]"
                            >
                              <code className="_text-nowrap">
                                _mt-[BREAKPOINT]-[SPACER]
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            margin-top: var(--spacer-[SPACER])
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_mr-[BREAKPOINT]-[SPACER]"
                            >
                              <code className="_text-nowrap">
                                _mr-[BREAKPOINT]-[SPACER]
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            margin-right: var(--spacer-[SPACER])
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_mb-[BREAKPOINT]-[SPACER]"
                            >
                              <code className="_text-nowrap">
                                _mb-[BREAKPOINT]-[SPACER]
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            margin-bottom: var(--spacer-[SPACER])
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_ml-[BREAKPOINT]-[SPACER]"
                            >
                              <code className="_text-nowrap">
                                _ml-[BREAKPOINT]-[SPACER]
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            margin-left: var(--spacer-[SPACER])
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_mx-[BREAKPOINT]-[SPACER]"
                            >
                              <code className="_text-nowrap">
                                _mx-[BREAKPOINT]-[SPACER]
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            margin-left: var(--spacer-[SPACER])
                            <br />
                            margin-right: var(--spacer-[SPACER])
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_my-[BREAKPOINT]-[SPACER]"
                            >
                              <code className="_text-nowrap">
                                _my-[BREAKPOINT]-[SPACER]
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            margin-top: var(--spacer-[SPACER])
                            <br />
                            margin-bottom: var(--spacer-[SPACER])
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Padding</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/spacing/spacing.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/spacing/spacing.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-0">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_p-[BREAKPOINT]-[SPACER]"
                            >
                              <code className="_text-nowrap">
                                _p-[BREAKPOINT]-[SPACER]
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            padding: var(--spacer-[SPACER])
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_pt-[BREAKPOINT]-[SPACER]"
                            >
                              <code className="_text-nowrap">
                                _pt-[BREAKPOINT]-[SPACER]
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            padding-top: var(--spacer-[SPACER])
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_pr-[BREAKPOINT]-[SPACER]"
                            >
                              <code className="_text-nowrap">
                                _pr-[BREAKPOINT]-[SPACER]
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            padding-right: var(--spacer-[SPACER])
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_pb-[BREAKPOINT]-[SPACER]"
                            >
                              <code className="_text-nowrap">
                                _pb-[BREAKPOINT]-[SPACER]
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            padding-bottom: var(--spacer-[SPACER])
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_pl-[BREAKPOINT]-[SPACER]"
                            >
                              <code className="_text-nowrap">
                                _pl-[BREAKPOINT]-[SPACER]
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            padding-left: var(--spacer-[SPACER])
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_px-[BREAKPOINT]-[SPACER]"
                            >
                              <code className="_text-nowrap">
                                _px-[BREAKPOINT]-[SPACER]
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            padding-left: var(--spacer-[SPACER])
                            <br />
                            padding-right: var(--spacer-[SPACER])
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_py-[BREAKPOINT]-[SPACER]"
                            >
                              <code className="_text-nowrap">
                                _py-[BREAKPOINT]-[SPACER]
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            padding-top: var(--spacer-[SPACER])
                            <br />
                            padding-bottom: var(--spacer-[SPACER])
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>
              </div>

              <hr className="_my-5 _my-lg-8" />

              <div className="_mb-8">
                <h4
                  className="_font-weight-bold _mb-8"
                  id={data.utilities.stretchedLink.title}
                >
                  {data.utilities.stretchedLink.title}
                </h4>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Stretched Link</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/stretched-link.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/stretched-link.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-0">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_stretched-link"
                            >
                              <code className="_text-nowrap">
                                _stretched-link
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            background-color: rgba(0, 0, 0, 0);
                            <br />
                            bottom: 0;
                            <br />
                            content: "";
                            <br />
                            left: 0;
                            <br />
                            pointer-events: auto;
                            <br />
                            position: absolute;
                            <br />
                            right: 0;
                            <br />
                            top: 0;
                            <br />
                            z-index: 10;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>
              </div>

              <hr className="_my-5 _my-lg-8" />

              <div className="_mb-8">
                <h4
                  className="_font-weight-bold _mb-8"
                  id={data.utilities.text.title}
                >
                  {data.utilities.text.title}
                </h4>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Font Size</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/text/text.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/text/text.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-[BREAKPOINT]-[SPACER]"
                            >
                              <code className="_text-nowrap">
                                _text-[BREAKPOINT]-[SPACER]
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            font-size: var(--text-[SPACER])
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">
                    Font Family, Wight and Style
                  </h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/text/text.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/text/text.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_font-weight-light"
                            >
                              <code className="_text-nowrap">
                                _font-weight-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            font-weight: var(--font-weight-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_font-weight"
                            >
                              <code className="_text-nowrap">_font-weight</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            font-weight: var(--font-weight)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_font-weight-bold"
                            >
                              <code className="_text-nowrap">
                                _font-weight-bold
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            font-weight: var(--font-weight-bold)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_font-italic"
                            >
                              <code className="_text-nowrap">_font-italic</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">font-style: italic</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Line Height</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/text/text.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/text/text.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_line-height-sm"
                            >
                              <code className="_text-nowrap">
                                _line-height-sm
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            line-height: var(--line-height-sm)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_line-height"
                            >
                              <code className="_text-nowrap">_line-height</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            line-height: var(--line-height)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_line-height-lg"
                            >
                              <code className="_text-nowrap">
                                _line-height-lg
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            line-height: var(--line-height-lg)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Text Align</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/text/text.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/text/text.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-left"
                            >
                              <code className="_text-nowrap">_text-left</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">text-align: left</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-right"
                            >
                              <code className="_text-nowrap">_text-right</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">text-align: right</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-center"
                            >
                              <code className="_text-nowrap">_text-center</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">text-align: center</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-justify"
                            >
                              <code className="_text-nowrap">
                                _text-justify
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">text-align: justify</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">
                    Text Transform and Decoration
                  </h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/text/text.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/text/text.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-lowercase"
                            >
                              <code className="_text-nowrap">
                                _text-lowercase
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            text-transform: lowercase
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-uppercase"
                            >
                              <code className="_text-nowrap">
                                _text-uppercase
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            text-transform: uppercase
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-capitalize"
                            >
                              <code className="_text-nowrap">
                                _text-capitalize
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            text-transform: capitalize
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-decoration-none"
                            >
                              <code className="_text-nowrap">
                                _text-decoration-none
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">text-decoration: none</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-monospace"
                            >
                              <code className="_text-nowrap">
                                _text-monospace
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            font-family: var(--font-family-monospace)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">White Space</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/text/text.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/text/text.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-wrap"
                            >
                              <code className="_text-nowrap">_text-wrap</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">white-space: normal</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-nowrap"
                            >
                              <code className="_text-nowrap">_text-nowrap</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">white-space: nowrap</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-truncate"
                            >
                              <code className="_text-nowrap">
                                _text-truncate
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            overflow: hidden;
                            <br />
                            text-overflow: ellipsis;
                            <br />
                            white-space: nowrap;
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-break"
                            >
                              <code className="_text-nowrap">_text-break</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">word-break: break-word</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Blue</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/text/variations/blue.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/text/variations/blue.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-blue-lightest"
                            >
                              <code className="_text-nowrap">
                                _text-blue-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--blue-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-blue-lighter"
                            >
                              <code className="_text-nowrap">
                                _text-blue-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--blue-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-blue-light"
                            >
                              <code className="_text-nowrap">
                                _text-blue-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--blue-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-blue"
                            >
                              <code className="_text-nowrap">_text-blue</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--blue)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-blue-dark"
                            >
                              <code className="_text-nowrap">
                                _text-blue-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--blue-dark)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-blue-darker"
                            >
                              <code className="_text-nowrap">
                                _text-blue-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--blue-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Green</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/text/variations/green.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/text/variations/green.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-green-lightest"
                            >
                              <code className="_text-nowrap">
                                _text-green-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--green-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-green-lighter"
                            >
                              <code className="_text-nowrap">
                                _text-green-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--green-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-green-light"
                            >
                              <code className="_text-nowrap">
                                _text-green-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--green-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-green"
                            >
                              <code className="_text-nowrap">_text-green</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--green)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-green-dark"
                            >
                              <code className="_text-nowrap">
                                _text-green-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--green-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-green-darker"
                            >
                              <code className="_text-nowrap">
                                _text-green-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--green-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Yellow</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/text/variations/yellow.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/text/variations/yellow.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-yellow-lightest"
                            >
                              <code className="_text-nowrap">
                                _text-yellow-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--yellow-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-yellow-lighter"
                            >
                              <code className="_text-nowrap">
                                _text-yellow-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--yellow-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-yellow-light"
                            >
                              <code className="_text-nowrap">
                                _text-yellow-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--yellow-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-yellow"
                            >
                              <code className="_text-nowrap">_text-yellow</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--yellow)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-yellow-dark"
                            >
                              <code className="_text-nowrap">
                                _text-yellow-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--yellow-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-yellow-darker"
                            >
                              <code className="_text-nowrap">
                                _text-yellow-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--yellow-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Red</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/text/variations/red.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/text/variations/red.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-red-lightest"
                            >
                              <code className="_text-nowrap">
                                _text-red-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--red-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-red-lighter"
                            >
                              <code className="_text-nowrap">
                                _text-red-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--red-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-red-light"
                            >
                              <code className="_text-nowrap">
                                _text-red-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--red-light)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-red"
                            >
                              <code className="_text-nowrap">_text-red</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--red)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-red-dark"
                            >
                              <code className="_text-nowrap">
                                _text-red-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--red-dark)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-red-darker"
                            >
                              <code className="_text-nowrap">
                                _text-red-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--red-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Purple</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/text/variations/purple.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/text/variations/purple.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-purple-lightest"
                            >
                              <code className="_text-nowrap">
                                _text-purple-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--purple-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-purple-lighter"
                            >
                              <code className="_text-nowrap">
                                _text-purple-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--purple-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-purple-light"
                            >
                              <code className="_text-nowrap">
                                _text-purple-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--purple-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-purple"
                            >
                              <code className="_text-nowrap">_text-purple</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--purple)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-purple-dark"
                            >
                              <code className="_text-nowrap">
                                _text-purple-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--purple-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-purple-darker"
                            >
                              <code className="_text-nowrap">
                                _text-purple-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--purple-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Cyan</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/text/variations/cyan.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/text/variations/cyan.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-cyan-lightest"
                            >
                              <code className="_text-nowrap">
                                _text-cyan-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--cyan-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-cyan-lighter"
                            >
                              <code className="_text-nowrap">
                                _text-cyan-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--cyan-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-cyan-light"
                            >
                              <code className="_text-nowrap">
                                _text-cyan-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--cyan-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-cyan"
                            >
                              <code className="_text-nowrap">_text-cyan</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--cyan)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-cyan-dark"
                            >
                              <code className="_text-nowrap">
                                _text-cyan-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--cyan-dark)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-cyan-darker"
                            >
                              <code className="_text-nowrap">
                                _text-cyan-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--cyan-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Grays</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/text/variations/grays.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/text/variations/grays.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-gray-100"
                            >
                              <code className="_text-nowrap">
                                _text-gray-100
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--gray-100)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-gray-200"
                            >
                              <code className="_text-nowrap">
                                _text-gray-200
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--gray-200)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-gray-300"
                            >
                              <code className="_text-nowrap">
                                _text-gray-300
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--gray-300)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-gray-400"
                            >
                              <code className="_text-nowrap">
                                _text-gray-400
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--gray-400)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-gray-500"
                            >
                              <code className="_text-nowrap">
                                _text-gray-500
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--gray-500)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-gray-600"
                            >
                              <code className="_text-nowrap">
                                _text-gray-600
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--gray-600)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-gray-700"
                            >
                              <code className="_text-nowrap">
                                _text-gray-700
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--gray-700)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-gray-800"
                            >
                              <code className="_text-nowrap">
                                _text-gray-800
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--gray-800)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-gray-900"
                            >
                              <code className="_text-nowrap">
                                _text-gray-900
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--gray-900)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-white"
                            >
                              <code className="_text-nowrap">_text-white</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--white)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-black"
                            >
                              <code className="_text-nowrap">_text-black</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--black)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Brand Primary</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/text/variations/brand-primary.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/text/variations/brand-primary.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-brand-primary-lightest"
                            >
                              <code className="_text-nowrap">
                                _text-brand-primary-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--brand-primary-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-brand-primary-lighter"
                            >
                              <code className="_text-nowrap">
                                _text-brand-primary-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--brand-primary-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-brand-primary-light"
                            >
                              <code className="_text-nowrap">
                                _text-brand-primary-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--brand-primary-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-brand-primary"
                            >
                              <code className="_text-nowrap">
                                _text-brand-primary
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--brand-primary)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-brand-primary-dark"
                            >
                              <code className="_text-nowrap">
                                _text-brand-primary-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--brand-primary-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-brand-primary-darker"
                            >
                              <code className="_text-nowrap">
                                _text-brand-primary-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--brand-primary-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Brand Secondary</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/text/variations/brand-secondary.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/text/variations/brand-secondary.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-8">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-brand-secondary-lightest"
                            >
                              <code className="_text-nowrap">
                                _text-brand-secondary-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--brand-secondary-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-brand-secondary-lighter"
                            >
                              <code className="_text-nowrap">
                                _text-brand-secondary-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--brand-secondary-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-brand-secondary-light"
                            >
                              <code className="_text-nowrap">
                                _text-brand-secondary-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--brand-secondary-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-brand-secondary"
                            >
                              <code className="_text-nowrap">
                                _text-brand-secondary
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--brand-secondary)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-brand-secondary-dark"
                            >
                              <code className="_text-nowrap">
                                _text-brand-secondary-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--brand-secondary-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-brand-secondary-darker"
                            >
                              <code className="_text-nowrap">
                                _text-brand-secondary-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--brand-secondary-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Primary</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/utilities/text/variations/primary.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/text/variations/primary.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-0">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-primary-lightest"
                            >
                              <code className="_text-nowrap">
                                _text-primary-lightest
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--primary-lightest)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-primary-lighter"
                            >
                              <code className="_text-nowrap">
                                _text-primary-lighter
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--primary-lighter)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-primary-light"
                            >
                              <code className="_text-nowrap">
                                _text-primary-light
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--primary-light)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-primary"
                            >
                              <code className="_text-nowrap">
                                _text-primary
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">color: var(--primary)</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-primary-dark"
                            >
                              <code className="_text-nowrap">
                                _text-primary-dark
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--primary-dark)
                          </td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_text-primary-darker"
                            >
                              <code className="_text-nowrap">
                                _text-primary-darker
                              </code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">
                            color: var(--primary-darker)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>
              </div>

              <hr className="_my-5 _my-lg-8" />

              <div className="_mb-8">
                <h4
                  className="_font-weight-bold _mb-8"
                  id={data.utilities.visibility.title}
                >
                  {data.utilities.visibility.title}
                </h4>

                <hot-collapse>
                  <h6 className="_font-weight-bold _mb-0">Visibility</h6>
                  <div slot="collapsed">
                    <CopyPasteContainer
                      iconAbsolutePosition
                      content="import '@hotmart/cosmos/dist/styles/visibility.css';"
                    >
                      <pre className="_text-2">
                        import
                        '@hotmart/cosmos/dist/styles/utilities/visibility.css';
                      </pre>
                    </CopyPasteContainer>
                    <table className="hot-table hot-table--card _mb-0">
                      <thead>
                        <tr>
                          <th>Classe</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_visible"
                            >
                              <code className="_text-nowrap">_visible</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">visibility: visible</td>
                        </tr>
                        <tr>
                          <td className="_bg-white">
                            <CopyPasteContainer
                              iconInlinePosition
                              content="_hidden"
                            >
                              <code className="_text-nowrap">_hidden</code>
                            </CopyPasteContainer>
                          </td>
                          <td className="_bg-white">visibility: hidden</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </hot-collapse>
              </div>

              <hr className="_my-5 _my-lg-8" />

              <div className="_mb-8">
                <p className="_mb-2">
                  Breakpoints: <code>sm</code>, <code>md</code>, <code>lg</code>
                  , <code>xl</code>, <code>xxl</code>
                </p>
                <p>
                  Spacers: <code>1</code>, <code>2</code>, <code>3</code>,{' '}
                  <code>4</code>, <code>5</code>, <code>6</code>, <code>7</code>
                  , <code>8</code>
                </p>
              </div>

              <hr className="_my-5 _my-lg-8" />
            </div>
            <aside className="hot-col-md-2 _d-none _d-md-block">
              <FoundationMenu content={menu} />
            </aside>
          </div>

          <div className="hot-row">
            <div className="hot-col-12 hot-col-sm-9">
              <Banner link={bannerLink} />
            </div>
          </div>

          <OtherDocumentations documentations={[]} />
        </>
      )}
    </div>
  );
};

const UtilitiesPage = (data) => {
  return <Content data={data} />;
};

export default UtilitiesPage;
