import React from 'react';

function Loader() {
  return (
    <div className="_w-full _text-center">
      <hot-loading data-testid="hot-loading" />
    </div>
  );
}

export default React.memo(Loader);
