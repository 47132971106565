import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';

import {
  Banner,
  FoundationMenu,
  OtherDocumentations,
  CopyPasteContainer,
} from 'components';

import spacersImg from 'assets/images/foundation/spacers.png';
import gridNormal from 'assets/images/foundation/gridNormal.png';
import gridCentered from 'assets/images/foundation/gridCentered.png';
import gridFluid from 'assets/images/foundation/gridFluid.png';
import gridMobile1 from 'assets/images/foundation/gridMobile1.png';
import gridMobile2 from 'assets/images/foundation/gridMobile2.png';

export default function SpacingPage() {
  const { data: language } = useSelector((state) => state.language);

  const spacing = language.spacing;
  const spacers = spacing.spacers;
  const grid = spacing.grid;
  const breakpoint = spacing.breakpoint;
  const otherDocumentations = spacing.otherDocumentations;

  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const titles = [spacers.title, grid.title, breakpoint.title];
  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8"> {spacing.title} </h1>
          <p className="_pb-7 _mb-0">{spacing.subtitle}</p>
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <div className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _text-4">{spacers.title}</h2>
            <p>{spacers.subtitle}</p>
            <table className="hot-table hot-table--card _mb-8">
              <thead>
                <tr>
                  <th>{spacers.table.first}</th>
                  <th>{spacers.table.second}</th>
                  <th>{spacers.table.third}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer iconInlinePosition content="--spacer-1">
                      <code className="_text-nowrap">--spacer-1</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">0.25</td>
                  <td className="_bg-white">4px</td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer iconInlinePosition content="--spacer-2">
                      <code className="_text-nowrap">--spacer-2</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">0.5</td>
                  <td className="_bg-white">8px</td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer iconInlinePosition content="--spacer-2">
                      <code className="_text-nowrap">--spacer-3</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">0.75</td>
                  <td className="_bg-white">12px</td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer iconInlinePosition content="--spacer-2">
                      <code className="_text-nowrap">--spacer-4</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">1</td>
                  <td className="_bg-white">16px</td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer iconInlinePosition content="--spacer-5">
                      <code className="_text-nowrap">--spacer-5</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">1.5</td>
                  <td className="_bg-white">24px</td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer iconInlinePosition content="--spacer-6">
                      <code className="_text-nowrap">--spacer-6</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">2</td>
                  <td className="_bg-white">32px</td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer iconInlinePosition content="--spacer-7">
                      <code className="_text-nowrap">--spacer-7</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">2.5</td>
                  <td className="_bg-white">40px</td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer iconInlinePosition content="--spacer-8">
                      <code className="_text-nowrap">--spacer-8</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">3</td>
                  <td className="_bg-white">48px</td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer iconInlinePosition content="--spacer-8">
                      <code className="_text-nowrap">--spacer-9</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">3.5</td>
                  <td className="_bg-white">56px</td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer iconInlinePosition content="--spacer-8">
                      <code className="_text-nowrap">--spacer-10</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">4</td>
                  <td className="_bg-white">64px</td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer iconInlinePosition content="--spacer-8">
                      <code className="_text-nowrap">--spacer-11</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">4.5</td>
                  <td className="_bg-white">72px</td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer iconInlinePosition content="--spacer-8">
                      <code className="_text-nowrap">--spacer-12</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">5</td>
                  <td className="_bg-white">80px</td>
                </tr>
              </tbody>
            </table>
            <p>{spacers.text}</p>
            <p>
              <img src={spacersImg} alt="spacers" className="_w-full" />
            </p>
          </div>

          <div className="_pt-7 _pb-7" id={grid.title}>
            <h4 className="_font-weight-bold _mb-8">{grid.title}</h4>
            <div className="_mb-8">
              <h3 className="_font-weight-bold _text-2">
                {grid.items.normal.title}
              </h3>
              <p>{grid.items.normal.content}</p>
              <p>
                <img src={gridNormal} className="_w-full" alt="grid-normal" />
              </p>
            </div>
            <div className="_mb-8">
              <h3 className="_font-weight-bold _text-2">
                {grid.items.centered.title}
              </h3>
              <p>{parse(grid.items.centered.content)}</p>
              <p>
                <img
                  src={gridCentered}
                  className="_w-full"
                  alt="grid-centered"
                />
              </p>
            </div>
            <div className="_mb-8">
              <h3 className="_font-weight-bold _text-2">
                {grid.items.fluid.title}
              </h3>
              <p>{parse(grid.items.fluid.content)}</p>
              <p>
                <img src={gridFluid} className="_w-full" alt="grid-fluid" />
              </p>
            </div>
            <div>
              <h3 className="_font-weight-bold _text-2">
                {grid.items.mobile.title}
              </h3>
              <p>{grid.items.mobile.content}</p>
              <div className="hot-row">
                <div className="hot-col-md-6">
                  <h6 className="_text-center">
                    <span role="img" aria-label="Smiling Face Emoji">
                      😃
                    </span>
                  </h6>
                  <p className="_mb-0">
                    <img
                      src={gridMobile1}
                      className="_w-full"
                      alt="grid-mobile"
                    />
                  </p>
                </div>
                <div className="hot-col-md-6">
                  <h6 className="_text-center">
                    <span role="img" aria-label="Pensive Face Emoji">
                      😔
                    </span>
                  </h6>
                  <p className="_mb-0">
                    <img
                      src={gridMobile2}
                      className="_w-full"
                      alt="grid-mobile"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="_pt-7 _pb-7" id={breakpoint.title}>
            <h2 className="_font-weight-bold _text-4">{breakpoint.title}</h2>
            <p>{breakpoint.subtitle}</p>
            <table className="hot-table hot-table--card _mb-0">
              <thead>
                <tr>
                  <th>{breakpoint.table.first}</th>
                  <th>{breakpoint.table.second}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer
                      iconInlinePosition
                      content="--breakpoint-sm"
                    >
                      <code className="_text-nowrap">--breakpoint-sm</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">576px</td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer
                      iconInlinePosition
                      content="--breakpoint-md"
                    >
                      <code className="_text-nowrap">--breakpoint-md</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">768px</td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer
                      iconInlinePosition
                      content="--breakpoint-lg"
                    >
                      <code className="_text-nowrap">--breakpoint-lg</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">992px</td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer
                      iconInlinePosition
                      content="--breakpoint-xl"
                    >
                      <code className="_text-nowrap">--breakpoint-xl</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">1200px</td>
                </tr>
                <tr>
                  <td className="_bg-white">
                    <CopyPasteContainer
                      iconInlinePosition
                      content="--breakpoint-xxl"
                    >
                      <code className="_text-nowrap">--breakpoint-xxl</code>
                    </CopyPasteContainer>
                  </td>
                  <td className="_bg-white">1700px</td>
                </tr>
              </tbody>
            </table>
          </div>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={otherDocumentations} />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </div>
  );
}
