import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as LanguageActions from 'store/modules/language/actions';

export default function PreLoadingApp({ children }) {
  const dispatch = useDispatch();
  const { data: language, localization } = useSelector(
    (state) => state.language,
  );

  const changeLanguage = useCallback(
    (lang) => {
      dispatch(LanguageActions.getLanguage(lang));
    },
    [dispatch],
  );

  useEffect(() => {
    changeLanguage(localization || 'pt_BR');
  }, [changeLanguage, localization]);

  if (language) {
    return children;
  }

  return <div />;
}
