import styled from 'styled-components';

export const LabelWrapper = styled.div`
  padding: 8px;
`;

export const Label = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #32363b;
  margin: 0;
`;

export const CodePanel = styled.div`
  background-color: #282c2f;
  border: 1px solid #e6e9ed;
  height: 100%;
  padding: 24px 32px;
`;

export const Code = styled.code`
  background-color: transparent;
  border: none;
`;

export const Pre = styled.pre`
  background-color: transparent;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  border: none;
`;
