import styled from 'styled-components';

export const Section = styled.section`
  background: linear-gradient(#e1e5e9, #f7f9fa);
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 80px;

  @media screen and (min-width: 768px) {
    padding-top: 160px;
    padding-bottom: 0;
  }

  @media (min-width: 1200px) {
    .hot-container {
      max-width: 1200px;
    }
  }

  @media (min-width: 1700px) {
    .hot-container {
      max-width: 1200px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
`;

export const OrnamentWrapper = styled.div`
  width: 100%;
  max-width: 340px;
  height: 100%;
  flex-shrink: 0;
  position: relative;
  left: 100px;
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }

  @media screen and (min-width: 1400px) {
    left: 0;
  }
`;

export const Wrapper = styled.div`
  max-width: 436px;
  padding-bottom: 40px;
`;

export const Title = styled.h1`
  font-size: 48px;
  font-weight: 700;
  line-height: 120%;
  color: #32363b;
  margin-bottom: 24px;
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 150%;
  color: #707780;
  margin: 0;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 40px;

  @media screen and (min-width: 768px) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }
`;

export const Ornament = styled.img`
  max-width: 100%;
`;

export const CardLink = styled.div`
  text-decoration: none;
  width: 100%;
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    max-width: 270px;
    margin-right: 24px;
  }

  &:hover {
    text-decoration: none;
  }
`;

export const Card = styled.div`
  background-color: var(--white);
  border-radius: 8px;
  padding: 16px 24px 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const CardIcon = styled.div`
  background: var(--${({ backgroundColor }) => backgroundColor});
  border-radius: 50%;
  height: 54px;
  width: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;

  svg {
    height: 24px !important;
    width: 24px !important;
    color: var(--${({ iconColor }) => iconColor});
  }
`;

export const CardText = styled.div`
  margin: 0;
  color: var(--gray-600);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
`;

export const CardArrow = styled.div`
  height: 10px;
  width: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.00364 0.37565V0.646872C1.00364 0.867843 1.27903 1 1.5 1H9.38319L0.959445 9.30893C0.804765 9.46361 0.826862 9.70668 0.959445 9.83926L1.18042 10.0602C1.3351 10.2149 1.55607 10.2149 1.71075 10.0602L10.0192 1.744V9.62538C10.0192 9.84635 10.1739 10.001 10.3949 10.001H10.6439C10.8649 10.001 11.0195 9.84635 11.0195 9.62538V0.37565C11.0195 0.15468 10.8649 0 10.6439 0H1.37929C1.15832 0 1.00364 0.15468 1.00364 0.37565Z' fill='%235981E3'/%3E%3C/svg%3E%0A");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
`;
