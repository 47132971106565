import React from 'react';
import { CopyPasteContainer } from 'components';

import gitHubImage from 'assets/images/icons/github.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';

export default function Developer({ developer }) {
  return (
    <div className="hot-row">
      <div className="hot-col-12 hot-col-sm-9">
        {Object.entries(developer.content).map((item, index) => {
          return (
            <div className="_pt-7 _pb-5" key={index}>
              <h4 className="_font-weight-bold">{item[1].title}</h4>
              {item[1].textOne ? <p>{item[1].textOne}</p> : ''}
              {item[1].commandOne ? (
                <CopyPasteContainer
                  iconAbsolutePosition
                  content={item[1].commandOne}
                >
                  <pre className="_text-1 _p-5">$ {item[1].commandOne}</pre>
                </CopyPasteContainer>
              ) : (
                ''
              )}
              {item[1].textTwo ? <p>{item[1].textTwo}</p> : ''}
              {item[1].commandTwo ? (
                <CopyPasteContainer
                  iconAbsolutePosition
                  content={item[1].commandTwo}
                >
                  <pre className="_text-1 _p-5">$ {item[1].commandTwo}</pre>
                </CopyPasteContainer>
              ) : (
                ''
              )}
              {item[1].textThree ? <p>{item[1].textThree}</p> : ''}
              {item[1].commandThree ? (
                <CopyPasteContainer
                  iconAbsolutePosition
                  content={item[1].commandThree}
                >
                  <pre className="_text-1 _p-5 _mb-0">
                    $ {item[1].commandThree}
                  </pre>
                </CopyPasteContainer>
              ) : (
                ''
              )}
            </div>
          );
        })}

        <div className="_pt-7 _pb-7">
          <h4 className="_font-weight-bold"> {developer.changelog.title} </h4>
          <p>{developer.changelog.text}</p>

          <hot-card class="hot-card--hover _d-inline-flex _rounded-lg">
            <hot-card-body class="_d-flex _align-items-center _p-6">
              <img src={gitHubImage} className="_w-6 _mr-3" alt="GitHub Logo" />
              <h6 className="_mb-0">
                <a
                  href="https://github.com/Hotmart-Org/cosmos/releases"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="_text-decoration-none _stretched-link"
                >
                  {developer.changelog.buttonLabel}
                </a>
              </h6>
              <FontAwesomeIcon
                icon={faExternalLink}
                className="_ml-8 _text-primary"
              />{' '}
            </hot-card-body>
          </hot-card>
        </div>
      </div>
    </div>
  );
}
