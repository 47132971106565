import styled from 'styled-components';

export const Container = styled.div`
  blockquote {
    position: relative;
    padding: 24px 24px 24px 52px;
    background-color: #fff;
    border-radius: 8px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 24px;
      width: 4px;
      height: calc(100% - 48px);
      transform: translateY(-50%);
      background-color: var(--blue);
    }
  }

  hot-card {
    &.correct,
    &.incorrect {
      border-bottom-width: 8px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &.correct {
      border-bottom-color: var(--green);

      .card-tip {
      }

      .tip-icon {
        background-color: var(--green);
      }
    }

    &.incorrect {
      border-bottom-color: var(--red);

      .card-tip {
      }

      .tip-icon {
        background-color: var(--red);
      }
    }

    .card-text {
      text-align: center;
    }

    hot-card-footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .tip-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    .tip-icon {
      height: 20px;
      width: 20px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 2px;

      svg {
        width: 12px;
      }
    }

    .card-tip {
      position: relative;
      padding-left: 16px;
    }

    hot-card-body,
    hot-card-footer {
      padding: var(--spacer-5);
    }
  }
`;
