import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  Banner,
  FoundationMenu,
  OtherDocumentations,
  SandBox,
} from 'components';

import {
  avatarUsageImage,
  avatarCircleImage,
  avatarInitialImage,
  avatarRoundeImage,
  avatarSizesImage,
} from './avatar-images';

export default function AvatarPage(data) {
  const component = data.components.avatar;
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const titles = [component.usage.title, component.formats.title];

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8"> {component.title} </h1>
          <p className="_pb-7 _mb-0">{component.subhead}</p>
        </div>
      </div>

      <div className="hot-row _pt-7 _pb-7">
        <div className="hot-col">
          <SandBox slug="avatar" translates={component} />
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section id={component.usage.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.usage.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.usage.text}</p>
            <img src={avatarUsageImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.formats.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.formats.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.formats.text}</p>

            <article>
              <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                {component.formats.circle.title}
              </h3>
              <p className="_mb-6">{component.formats.circle.text}</p>
              <img
                src={avatarCircleImage}
                alt=""
                className="_max-w-full _mb-5"
              />
            </article>

            <article>
              <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                {component.formats.rounded.title}
              </h3>
              <p className="_mb-6">{component.formats.rounded.text}</p>
              <img
                src={avatarRoundeImage}
                alt=""
                className="_max-w-full _mb-5"
              />
            </article>
          </section>

          <section id={component.initials.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.initials.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.initials.text}</p>
            <img src={avatarInitialImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.sizes.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.sizes.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.sizes.text}</p>
            <img src={avatarSizesImage} alt="" className="_max-w-full" />
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={component.otherDocumentations} />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </div>
  );
}
