import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;

  hot-card {
    padding-top: var(--spacer-7);
    border-bottom-width: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    ${({ variation }) =>
      variation === 'warning' &&
      `
      border-bottom-color: var(--yellow);
    `}

    ${({ variation }) =>
      variation === 'success' &&
      `
      border-bottom-color: var(--green);
    `}

    ${({ variation }) =>
      variation === 'danger' &&
      `
      border-bottom-color: var(--red);
    `}
  }

  hot-card-body,
  hot-card-footer {
    padding: var(--spacer-5);
    text-align: center;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const LabelIcon = styled.div`
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 2px;

  ${({ variation }) =>
    variation === 'warning' &&
    `
    color: var(--yellow-dark);

    svg {
      color: var(--yellow);
      width: 20px !important;
    }
    `}

  ${({ variation }) =>
    variation === 'success' &&
    `
    background-color: var(--green);

    svg {
      color: #fff;
      width: 9px !important;
    }
    `}

  ${({ variation }) =>
    variation === 'danger' &&
    `
    background-color: var(--red);

    svg {
      width: 8px !important;
      color: #fff;
    }
  `}
`;

export const Label = styled.div`
  padding-left: 12px;
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;

  ${({ variation }) =>
    variation === 'warning' &&
    `
    color: var(--yellow-dark);
  `}

  ${({ variation }) =>
    variation === 'success' &&
    `
    color: var(--green-dark);
  `}

  ${({ variation }) =>
    variation === 'danger' &&
    `
    color: var(--red-dark);
  `}
`;
