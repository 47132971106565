import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import alertImage from 'assets/images/overview/components/alert.svg';
import avatarImage from 'assets/images/overview/components/avatar.svg';
import breadcrumbImage from 'assets/images/overview/components/breadcrumb.svg';
import buttonGroupImage from 'assets/images/overview/components/button-group.svg';
import buttonImage from 'assets/images/overview/components/button.svg';
import cardImage from 'assets/images/overview/components/card.svg';
import checkboxImage from 'assets/images/overview/components/checkbox.svg';
import collapseImage from 'assets/images/overview/components/collapse.svg';
import contentLoaderImage from 'assets/images/overview/components/content-loader.svg';
import dateTimePickerImage from 'assets/images/overview/components/date-time-picker.svg';
import dropdownImage from 'assets/images/overview/components/dropdown.svg';
import fileUploadImage from 'assets/images/overview/components/file-upload.svg';
import listGroupImage from 'assets/images/overview/components/list-group.svg';
import loadingImage from 'assets/images/overview/components/loading.svg';
import modalImage from 'assets/images/overview/components/modal.svg';
import navigationImage from 'assets/images/overview/components/navigation.svg';
import paginationImage from 'assets/images/overview/components/pagination.svg';
import popoverImage from 'assets/images/overview/components/popover.svg';
import progressBarImage from 'assets/images/overview/components/progress-bar.svg';
import radioImage from 'assets/images/overview/components/radio.svg';
import rangeImage from 'assets/images/overview/components/range.svg';
import selectImage from 'assets/images/overview/components/select.svg';
import sidebarImage from 'assets/images/overview/components/sidebar.svg';
import switchImage from 'assets/images/overview/components/switch.svg';
import tableImage from 'assets/images/overview/components/table.svg';
import tagImage from 'assets/images/overview/components/tag.svg';
import textFieldImage from 'assets/images/overview/components/text-field.svg';
import tooltipImage from 'assets/images/overview/components/tooltip.svg';

const images = {
  alertImage,
  avatarImage,
  breadcrumbImage,
  buttonGroupImage,
  buttonImage,
  cardImage,
  checkboxImage,
  collapseImage,
  contentLoaderImage,
  dateTimePickerImage,
  dropdownImage,
  fileUploadImage,
  listGroupImage,
  loadingImage,
  modalImage,
  navigationImage,
  paginationImage,
  popoverImage,
  progressBarImage,
  radioImage,
  rangeImage,
  selectImage,
  sidebarImage,
  switchImage,
  tableImage,
  tagImage,
  textFieldImage,
  tooltipImage,
};

export default function ComponentsPage() {
  const { data: language } = useSelector((state) => state.language);

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <h1 className="_font-weight-bold _text-8">
        {language.componentsOverview.title}
      </h1>
      <div className="hot-row">
        <div className="hot-col-md-9">
          <p className="_mb-5 _mb-lg-8">
            {language.componentsOverview.subhead}
          </p>
        </div>
      </div>

      <div className="hot-row">
        {language.componentsOverview.items.map((item) => (
          <div
            className="hot-col-sm-6 hot-col-md-4 _mb-4 _mb-lg-5"
            key={item.id}
          >
            <hot-card class="hot-card--hover _h-full _rounded-lg">
              <hot-card-header>
                <img
                  src={images[item.image]}
                  alt={item.title}
                  className="_w-full"
                />
              </hot-card-header>
              <hot-card-body class="_p-4 _p-lg-8">
                <h2 className="_text-3 _font-weight-bold _text-gray-600">
                  <Link
                    to={item.link}
                    className="_stretched-link _text-decoration-none _text-gray-700"
                    title={item.title}
                  >
                    {item.title}
                  </Link>
                </h2>
                <p className="_text-gray-500 _mb-0">{item.text}</p>
              </hot-card-body>
            </hot-card>
          </div>
        ))}
      </div>
    </div>
  );
}
