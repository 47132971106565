import React from 'react';
import parser from 'html-react-parser';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Container } from './style-tips-style';
import { WebCard } from 'web-components';
import { Banner, FoundationMenu, OtherDocumentations } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';

export default function StyleTipsPage() {
  const { data: language } = useSelector((state) => state.language);

  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const titles = [
    language.styleTips.activeVoice.anchor,
    language.styleTips.pluralFirstPerson.anchor,
    language.styleTips.imperativeVsInfinitive.anchor,
    language.styleTips.genderNeutrality.anchor,
    language.styleTips.friendlyAndHumorous.anchor,
    language.styleTips.optimistic.anchor,
    language.styleTips.fullStop.anchor,
    language.styleTips.exclamationMark.anchor,
    language.styleTips.bold.anchor,
    language.styleTips.numerals.anchor,
    language.styleTips.foreignWords.anchor,
    language.styleTips.technologicalTerms.anchor,
    language.styleTips.businessTerms.anchor,
    language.styleTips.abbreviations.anchor,
  ];

  return (
    <Container className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8">
            {' '}
            {language.styleTips.title}{' '}
          </h1>
          <p className="_pb-7 _mb-0">{language.styleTips.subhead}</p>
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section
            className="_pt-7 _pb-7"
            id={language.styleTips.activeVoice.anchor}
          >
            <h2 className="_font-weight-bold _text-4">
              {language.styleTips.activeVoice.title}
            </h2>
            <p className="_mb-5">{language.styleTips.activeVoice.text}</p>
            <div className="hot-row">
              <div className="hot-col-lg-6">
                <WebCard className="correct _h-full">
                  <hot-card-header />
                  <hot-card-body>
                    <p className="_text-2 _text-center">
                      {language.styleTips.activeVoice.correct.text}
                    </p>
                  </hot-card-body>
                  <hot-card-footer>
                    <div className="tip-wrapper">
                      <div className="tip-icon">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="_text-white"
                        />
                      </div>

                      <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                        {language.styleTips.activeVoice.correct.tip}
                      </p>
                    </div>
                  </hot-card-footer>
                </WebCard>
              </div>
              <div className="hot-col-lg-6">
                <WebCard className="incorrect _h-full">
                  <hot-card-header />
                  <hot-card-body>
                    <p className="_text-2 _text-center">
                      {language.styleTips.activeVoice.incorrect.text}
                    </p>
                  </hot-card-body>
                  <hot-card-footer>
                    <div className="tip-wrapper">
                      <div className="tip-icon">
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="_text-white"
                        />
                      </div>
                      <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                        {language.styleTips.activeVoice.incorrect.tip}
                      </p>
                    </div>
                  </hot-card-footer>
                </WebCard>
              </div>
            </div>
          </section>

          <section
            className="_pt-7 _pb-7"
            id={language.styleTips.pluralFirstPerson.anchor}
          >
            <h2 className="_font-weight-bold _text-4">
              {language.styleTips.pluralFirstPerson.title}
            </h2>
            <p className="_mb-5">{language.styleTips.pluralFirstPerson.text}</p>
            <div>
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.styleTips.pluralFirstPerson.correct.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {language.styleTips.pluralFirstPerson.correct.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.styleTips.pluralFirstPerson.incorrect.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {language.styleTips.pluralFirstPerson.incorrect.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>

          <section
            className="_pt-7 _pb-7"
            id={language.styleTips.imperativeVsInfinitive.anchor}
          >
            <h2 className="_font-weight-bold _text-4">
              {language.styleTips.imperativeVsInfinitive.title}
            </h2>
            <p className="_mb-5">
              {language.styleTips.imperativeVsInfinitive.imperative.text}
            </p>
            <div>
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {
                          language.styleTips.imperativeVsInfinitive.imperative
                            .correct.text
                        }
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {
                            language.styleTips.imperativeVsInfinitive.imperative
                              .correct.tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {
                          language.styleTips.imperativeVsInfinitive.infinitive
                            .incorrect.text
                        }
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {
                            language.styleTips.imperativeVsInfinitive.infinitive
                              .incorrect.tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
            <p className="_mb-5">
              {language.styleTips.imperativeVsInfinitive.infinitive.text}
            </p>
            <div>
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {
                          language.styleTips.imperativeVsInfinitive.infinitive
                            .correct.text
                        }
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {
                            language.styleTips.imperativeVsInfinitive.infinitive
                              .correct.tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {
                          language.styleTips.imperativeVsInfinitive.infinitive
                            .incorrect.text
                        }
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {
                            language.styleTips.imperativeVsInfinitive.infinitive
                              .incorrect.tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>

          <section
            className="_pt-7 _pb-7"
            id={language.styleTips.genderNeutrality.anchor}
          >
            <h2 className="_font-weight-bold _text-4">
              {language.styleTips.genderNeutrality.title}
            </h2>
            <p className="_mb-5">{language.styleTips.genderNeutrality.text}</p>
            <div>
              {language.styleTips.genderNeutrality.tips.map((item, index) => (
                <div className="hot-row" key={index}>
                  <div className="hot-col-lg-6">
                    <WebCard className="correct _h-full">
                      <hot-card-header />
                      <hot-card-body>
                        <p className="_text-2 _text-center">
                          {item.correct.text}
                        </p>
                      </hot-card-body>
                      <hot-card-footer>
                        <div className="tip-wrapper">
                          <div className="tip-icon">
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="_text-white"
                            />
                          </div>
                          <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                            {item.correct.tip}
                          </p>
                        </div>
                      </hot-card-footer>
                    </WebCard>
                  </div>
                  <div className="hot-col-lg-6">
                    <WebCard className="incorrect _h-full">
                      <hot-card-header />
                      <hot-card-body>
                        <p className="_text-2 _text-center">
                          {item.incorrect.text}
                        </p>
                      </hot-card-body>
                      <hot-card-footer>
                        <div className="tip-wrapper">
                          <div className="tip-icon">
                            <FontAwesomeIcon
                              icon={faTimes}
                              className="_text-white"
                            />
                          </div>
                          <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                            {item.incorrect.tip}
                          </p>
                        </div>
                      </hot-card-footer>
                    </WebCard>
                  </div>
                </div>
              ))}
            </div>
          </section>

          <section
            className="_pt-7 _pb-7"
            id={language.styleTips.friendlyAndHumorous.anchor}
          >
            <h2 className="_font-weight-bold _text-4">
              {language.styleTips.friendlyAndHumorous.title}
            </h2>
            <p className="_mb-5">
              {language.styleTips.friendlyAndHumorous.text}
            </p>
            <div>
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.styleTips.friendlyAndHumorous.correct.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {language.styleTips.friendlyAndHumorous.correct.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.styleTips.friendlyAndHumorous.incorrect.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {language.styleTips.friendlyAndHumorous.incorrect.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>

          <section
            className="_pt-7 _pb-7"
            id={language.styleTips.optimistic.anchor}
          >
            <h2 className="_font-weight-bold _text-4">
              {language.styleTips.optimistic.title}
            </h2>
            <p className="_mb-5">{language.styleTips.optimistic.text}</p>
            <div>
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.styleTips.optimistic.correct.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {language.styleTips.optimistic.correct.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.styleTips.optimistic.incorrect.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {language.styleTips.optimistic.incorrect.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>

          <section
            className="_pt-7 _pb-7"
            id={language.styleTips.fullStop.anchor}
          >
            <h2 className="_font-weight-bold _text-4">
              {language.styleTips.fullStop.title}
            </h2>
            <p className="_mb-5">{language.styleTips.fullStop.text}</p>
            <div>
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.styleTips.fullStop.correct.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {language.styleTips.fullStop.correct.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.styleTips.fullStop.incorrect.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {language.styleTips.fullStop.incorrect.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>

          <section
            className="_pt-7 _pb-7"
            id={language.styleTips.exclamationMark.anchor}
          >
            <h2 className="_font-weight-bold _text-4">
              {language.styleTips.exclamationMark.title}
            </h2>
            <p className="_mb-8">{language.styleTips.exclamationMark.text}</p>
            <p className="_mb-5">
              {language.styleTips.exclamationMark.message.text}
            </p>
            <div>
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {
                          language.styleTips.exclamationMark.message.correct
                            .text
                        }
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {
                            language.styleTips.exclamationMark.message.correct
                              .tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {
                          language.styleTips.exclamationMark.message.incorrect
                            .text
                        }
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {
                            language.styleTips.exclamationMark.message.incorrect
                              .tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
            <p className="_mb-5">
              {language.styleTips.exclamationMark.order.text}
            </p>
            <div>
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.styleTips.exclamationMark.order.correct.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {language.styleTips.exclamationMark.order.correct.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {
                          language.styleTips.exclamationMark.order.incorrect
                            .text
                        }
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {
                            language.styleTips.exclamationMark.order.incorrect
                              .tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>

          <section className="_pt-7 _pb-7" id={language.styleTips.bold.anchor}>
            <h2 className="_font-weight-bold _text-4">
              {language.styleTips.bold.title}
            </h2>
            <p className="_mb-5">{language.styleTips.bold.text}</p>
            <div>
              <div className="hot-row">
                <div className="hot-col-lg-4">
                  <WebCard className="correct _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {parser(language.styleTips.bold.correct.text)}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {language.styleTips.bold.correct.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-4">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {parser(language.styleTips.bold.incorrect.text)}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {language.styleTips.bold.incorrect.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-4">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {parser(language.styleTips.bold.otherIncorrect.text)}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {language.styleTips.bold.otherIncorrect.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>

          <section
            className="_pt-7 _pb-7"
            id={language.styleTips.numerals.anchor}
          >
            <h2 className="_font-weight-bold _text-4">
              {language.styleTips.numerals.title}
            </h2>
            <p className="_mb-5">{language.styleTips.numerals.text}</p>
            <div>
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.styleTips.numerals.correct.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold">
                          {language.styleTips.numerals.correct.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {language.styleTips.numerals.incorrect.text}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold">
                          {language.styleTips.numerals.incorrect.tip}
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>

          <section
            className="_pt-7 _pb-7"
            id={language.styleTips.foreignWords.anchor}
          >
            <h2 className="_font-weight-bold _text-4">
              {language.styleTips.foreignWords.title}
            </h2>
            <p className="_mb-5">{language.styleTips.foreignWords.text}</p>
          </section>
          <section
            className="_pt-7 _pb-7"
            id={language.styleTips.technologicalTerms.anchor}
          >
            <h2 className="_font-weight-bold _text-4">
              {language.styleTips.technologicalTerms.title}
            </h2>
            <p className="_mb-5">
              {language.styleTips.technologicalTerms.text}
            </p>
          </section>
          <section
            className="_pt-7 _pb-7"
            id={language.styleTips.businessTerms.anchor}
          >
            <h2 className="_font-weight-bold _text-4">
              {language.styleTips.businessTerms.title}
            </h2>
            <p className="_mb-5">{language.styleTips.businessTerms.text}</p>
          </section>
          <section
            className="_pt-7 _pb-7"
            id={language.styleTips.abbreviations.anchor}
          >
            <h2 className="_font-weight-bold _text-4">
              {language.styleTips.abbreviations.title}
            </h2>
            <p className="_mb-0">{language.styleTips.abbreviations.text}</p>
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations
            documentations={language.styleTips.otherDocumentations}
          />
        </div>
        <div className="hot-col-sm-2">
          <FoundationMenu content={titles} />
        </div>
      </div>
    </Container>
  );
}
