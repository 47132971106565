import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  statusImage,
  toggleImage,
  dismissabledImage,
  linkImage,
  smallImage,
  mediumImage,
  largeImage,
} from './tag-images';

import {
  Banner,
  SandBox,
  FoundationMenu,
  OtherDocumentations,
} from 'components';

const Content = ({ data }) => {
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const [language, setLanguage] = useState({});
  const [component, setComponent] = useState({});
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (data) {
      setLanguage(data.components.tag);
      setComponent(data.components.tag);
      setMenu([
        data.components.tag.types.title,
        data.components.tag.sizes.title,
      ]);
    }
  }, [data]);

  return (
    <div className="hot-container _p-5 _p-lg-8">
      {!!language && (
        <>
          <div className="hot-row">
            <div className="hot-col">
              <h1 className="_font-weight-bold _text-8">
                {data.components.tag.title}
              </h1>
              <p className="_pb-7 _mb-0">{data.components.tag.subtitle}</p>
            </div>
          </div>

          <div className="hot-row _pt-7 _pb-7">
            <div className="hot-col">
              <SandBox slug="tag" translates={component} />
            </div>
          </div>

          <div className="hot-row _justify-content-between">
            <div className="hot-col-12 hot-col-sm-9">
              <section
                className="_pt-7 _pb-7"
                id={data.components.tag.types.title}
              >
                <h2 className="_font-weight-bold _mb-3 _text-4">
                  {data.components.tag.types.title}
                </h2>
                <p className="_mb-5">{data.components.tag.types.text}</p>
                <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                  {data.components.tag.types.status.title}
                </h3>
                <p>{data.components.tag.types.toggle.text}</p>
                <img
                  className="_w-full _mb-5"
                  src={statusImage}
                  alt="Tag Status Exmple"
                />
                <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                  {data.components.tag.types.toggle.title}
                </h3>
                <p>{data.components.tag.types.toggle.text}</p>
                <img
                  className="_w-full _mb-5"
                  src={toggleImage}
                  alt="Tag Toggle Exmple"
                />
                <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                  {data.components.tag.types.dismissabled.title}
                </h3>
                <p>{data.components.tag.types.toggle.text}</p>
                <img
                  className="_w-full _mb-5"
                  src={dismissabledImage}
                  alt="Tag Dismissabled Exmple"
                />
                <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                  {data.components.tag.types.link.title}
                </h3>
                <p>{data.components.tag.types.toggle.text}</p>
                <img
                  className="_w-full"
                  src={linkImage}
                  alt="Tag Link Exmple"
                />
              </section>

              <section
                className="_pt-7 _pb-7"
                id={data.components.tag.sizes.title}
              >
                <h2 className="_font-weight-bold _mb-3 _text-4">
                  {data.components.tag.sizes.title}
                </h2>
                <p className="_mb-8">{data.components.tag.sizes.text}</p>
                <div className="hot-row _mb-8">
                  <div className="hot-col-md-6">
                    <img
                      className="_w-full"
                      src={smallImage}
                      alt="Small Tag Exmple"
                    />
                  </div>
                  <div className="hot-col-md-6">
                    <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                      {data.components.tag.sizes.small.title}
                    </h3>
                    <p>{data.components.tag.sizes.small.text}</p>
                  </div>
                </div>
                <div className="hot-row _mb-8">
                  <div className="hot-col-md-6">
                    <img
                      className="_w-full"
                      src={mediumImage}
                      alt="Medium Tag Exmple"
                    />
                  </div>
                  <div className="hot-col-md-6">
                    <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                      {data.components.tag.sizes.medium.title}
                    </h3>
                    <p>{data.components.tag.sizes.medium.text}</p>
                  </div>
                </div>
                <div className="hot-row">
                  <div className="hot-col-md-6">
                    <img
                      className="_w-full"
                      src={largeImage}
                      alt="Large Tag Exmple"
                    />
                  </div>
                  <div className="hot-col-md-6">
                    <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                      {data.components.tag.sizes.large.title}
                    </h3>
                    <p>{data.components.tag.sizes.large.text}</p>
                  </div>
                </div>
              </section>

              <Banner link={bannerLink} />

              <OtherDocumentations
                documentations={data.components.tag.otherDocumentations}
              />
            </div>
            <aside className="hot-col-md-2 _d-none _d-md-block">
              <FoundationMenu content={menu} />
            </aside>
          </div>
        </>
      )}
    </div>
  );
};

const TagPage = (data) => {
  return <Content data={data} />;
};

export default TagPage;
