import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  SandBox,
  FoundationMenu,
  OtherDocumentations,
  Banner,
} from 'components';

import {
  popoverUsageImage,
  popoverPositionTopImage,
  popoverPositionRightImage,
  popoverPositionBottomImage,
  popoverPositionLeftImage,
} from './popover-images';

const positionImages = {
  popoverPositionTopImage,
  popoverPositionRightImage,
  popoverPositionBottomImage,
  popoverPositionLeftImage,
};

const PopoverPage = (data) => {
  const component = data.components.popover;
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const titles = [
    component.usage.title,
    component.positions.title,
    component.writing.title,
  ];

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8"> {component.title} </h1>
          <p className="_pb-7 _mb-0">{component.subhead}</p>
        </div>
      </div>

      <div className="hot-row _pt-7 _pb-7">
        {component.characteristics.map((characteristic, index) => (
          <div className="hot-col-3" key={index}>
            <h3 className="_font-weight-bold _text-2 _mb-4">
              {characteristic.label}
            </h3>
            <p className="_pr-8 _mb-0">{characteristic.text}</p>
          </div>
        ))}
      </div>

      <div className="hot-row _pt-7 _pb-7">
        <div className="hot-col">
          <SandBox slug="popover" translates={component} />
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section id={component.usage.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.usage.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.usage.text}</p>
            <img
              src={popoverUsageImage}
              alt={component.usage.title}
              className="_max-w-full _mt-7 _mb-6"
            />
            <hot-alert class="hot-alert--warning" role="alert">
              {component.usage.alert}{' '}
              <a href="/" className="hot-alert__link">
                Tooltip.
              </a>
            </hot-alert>
          </section>

          <section id={component.positions.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.positions.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.positions.text}</p>

            {component.positions.items.map((position, index) => (
              <article className="_mb-7" key={index}>
                <div className="hot-row">
                  <div className="hot-col-6">
                    <img
                      src={positionImages[position.image]}
                      alt={position.title}
                      className="_max-w-full"
                    />
                  </div>
                  <div className="hot-col-6">
                    <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                      {position.title}
                    </h3>
                    <p className="_mb-6">{position.text}</p>
                  </div>
                </div>
              </article>
            ))}
          </section>

          <section id={component.writing.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.writing.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.writing.text}</p>
            <ul className="_pl-4 _mb-0">
              {component.writing.rules.map((rule, index) => (
                <li key={index}> {rule} </li>
              ))}
            </ul>
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={component.otherDocumentations} />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </div>
  );
};

export default PopoverPage;
