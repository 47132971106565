import React, { useEffect, useState } from 'react';
import { CopyPasteContainer } from 'components';
import { groupBy, removeSpecialChar } from 'utils';

import * as S from './sandbox-specs-mobile-styles';

export default function SandboxSpecsMobileStyles({
  expandedSpecs,
  setExpandedSpecs,
  variations,
  title,
}) {
  const [tokens, setTokens] = useState([]);

  useEffect(() => {
    function arrangeTokens() {
      const keys = Object.keys(variations);

      if (keys.length) {
        const sortedTokens = keys
          .map((key) => variations[key])
          .sort((a, b) => a.priority - b.priority);

        const tokensFromSort = sortedTokens.reduce((acc, cur) => {
          return [...acc, ...cur.tokens];
        }, []);

        return setTokens(groupBy(tokensFromSort, 'tag'));
      }

      return setTokens([]);
    }

    arrangeTokens();
  }, [variations]);

  const sidebarProps = {};

  const hotSidebar = document.querySelector('#sandboxSpecsMobileSidebar');

  if (expandedSpecs) {
    sidebarProps.open = true;
  }

  useEffect(() => {
    if (!hotSidebar) {
      return;
    }

    const shadowRoot = hotSidebar.shadowRoot;

    if (!shadowRoot) {
      return;
    }

    const style = document.createElement('style');

    style.innerHTML = `
          :host hot-sidebar-concrete {
            width: 200px;
          }
        `;

    shadowRoot.appendChild(style);

    return () => {};
  }, [hotSidebar]);

  useEffect(() => {
    if (hotSidebar) {
      hotSidebar.addEventListener('close', () => {
        setExpandedSpecs(false);
      });
    }
  }, [hotSidebar, setExpandedSpecs]);

  return (
    <hot-sidebar id="sandboxSpecsMobileSidebar" {...sidebarProps}>
      <hot-sidebar-header>
        <S.LabelWrapper>
          <S.Label> {title} </S.Label>
        </S.LabelWrapper>
      </hot-sidebar-header>
      <hot-sidebar-body style={{ overflowY: 'auto' }}>
        <S.BodyWrapper>
          {Object.keys(tokens).map((key) => {
            const element = tokens[key];

            return (
              <S.Item key={key}>
                <S.Title>{removeSpecialChar(key, '-')}</S.Title>
                <S.List>
                  {element.map((item) => (
                    <S.ListItem key={item.name}>
                      <S.ListItemName>{item.name}</S.ListItemName>
                      {item.value.map((string, index) => (
                        <S.ListItemWrapper key={index}>
                          <CopyPasteContainer
                            iconInlinePosition
                            content={string}
                          >
                            {string}
                          </CopyPasteContainer>
                        </S.ListItemWrapper>
                      ))}
                    </S.ListItem>
                  ))}
                </S.List>
              </S.Item>
            );
          })}
        </S.BodyWrapper>
      </hot-sidebar-body>
    </hot-sidebar>
  );
}
