import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { Banner, OtherDocumentations } from 'components';
import {
  Grid,
  Card,
  CardLink,
  Content,
  ImageWrapper,
  Text,
  Icon,
} from './internationalization-style';

export default function InternationalizationPage() {
  const { data: language } = useSelector((state) => state.language);

  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8">
            {language.internationalization.title}
          </h1>
          <p className="_pb-7 _mb-0">{language.internationalization.subhead}</p>
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section className="_pt-7 _pb-7">
            <p>{language.internationalization.firstText}</p>
            <p className="_mb-0">{language.internationalization.secondText}</p>
          </section>

          <section className="_pt-7 _pb-7">
            <div className="_mb-8">
              <h2 className="_font-weight-bold _text-4">
                {language.internationalization.localizationProject.title}
              </h2>
              <p className="_mb-8">
                {language.internationalization.localizationProject.text}
              </p>
            </div>
            <div className="_mb-0">
              <Grid>
                {language.internationalization.localizationProject.languages.map(
                  (item, index) => (
                    <CardLink href={item.link} key={index} target="_blank">
                      <Card>
                        <Content>
                          <ImageWrapper>
                            <FontAwesomeIcon icon={faGlobe} />
                          </ImageWrapper>
                          <Text> {item.text} </Text>
                        </Content>
                        <Icon />
                      </Card>
                    </CardLink>
                  ),
                )}
              </Grid>
            </div>
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations
            documentations={language.internationalization.otherDocumentations}
          />
        </div>
      </div>
    </div>
  );
}
