import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  Banner,
  FoundationMenu,
  OtherDocumentations,
  SandBox,
} from 'components';

import {
  DropdownDefaultImage,
  DropdownDockedMenuImage,
  DropdownScrollImage,
  DropdownSizesImage,
  DropdownSplitImage,
  DropdownUsageImage,
} from './dropdown-images';

export default function ButtonGroupPage(data) {
  const component = data.components.dropdown;
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const titles = [
    component.usage.title,
    component.defaultVsSplit.title,
    component.dockedMenu.title,
    component.scroll.title,
    component.sizes.title,
    component.writing.title,
  ];

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8"> {component.title} </h1>
          <p className="_pb-7 _mb-0">{component.subhead}</p>
        </div>
      </div>

      <div className="hot-row _pt-7 _pb-7">
        <div className="hot-col">
          <SandBox slug="dropdown" translates={component} />
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section id={component.usage.title} className="_pt-7 _pb-7">
            <h2 className=" _font-weight-bold _mb-3 _text-4">
              {component.usage.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.usage.text}</p>
            <img src={DropdownUsageImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.defaultVsSplit.title} className="_pt-7 _pb-7">
            <h2 className=" _font-weight-bold _mb-3 _text-4">
              {component.defaultVsSplit.title}
            </h2>
            <p className="_mb-0 _pb-8">{component.defaultVsSplit.text}</p>

            <article>
              <div className="hot-row">
                <div className="hot-col-6">
                  <img
                    src={DropdownDefaultImage}
                    alt=""
                    className="_max-w-full _mb-6"
                  />
                </div>
                <div className="hot-col-6">
                  <h3 className="_font-weight-bold _text-2 _mb-3">
                    {component.defaultVsSplit.default.title}
                  </h3>
                  <p>{component.defaultVsSplit.default.text}</p>
                </div>
              </div>
            </article>

            <article>
              <div className="hot-row">
                <div className="hot-col-6">
                  <img
                    src={DropdownSplitImage}
                    alt=""
                    className="_max-w-full _mb-0"
                  />
                </div>
                <div className="hot-col-6">
                  <h3 className="_font-weight-bold _text-2 _mb-3">
                    {component.defaultVsSplit.split.title}
                  </h3>
                  <p>{component.defaultVsSplit.split.text}</p>
                </div>
              </div>
            </article>
          </section>

          <section id={component.dockedMenu.title} className="_pt-7 _pb-7">
            <h2 className=" _font-weight-bold _mb-3 _text-4">
              {component.dockedMenu.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.dockedMenu.text}</p>
            <img
              src={DropdownDockedMenuImage}
              alt=""
              className="_max-w-full _mb-6"
            />
            <p className="_mb-0">{component.dockedMenu.tip}</p>
          </section>

          <section id={component.scroll.title} className="_pt-7 _pb-7">
            <h2 className=" _font-weight-bold _mb-3 _text-4">
              {component.scroll.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.scroll.text}</p>
            <img src={DropdownScrollImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.sizes.title} className="_pt-7 _pb-7">
            <h2 className=" _font-weight-bold _mb-3 _text-4">
              {component.sizes.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.sizes.text}</p>
            <img src={DropdownSizesImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.writing.title} className="_pt-7 _pb-7">
            <h2 className=" _font-weight-bold _mb-3 _text-4">
              {component.writing.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.writing.text}</p>
            <ul className="_pl-4 _mb-5">
              {component.writing.rules.map((rule, index) => (
                <li key={index}> {rule} </li>
              ))}
            </ul>
            <p className="_mb-0">{component.writing.tip}</p>
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={component.otherDocumentations} />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </div>
  );
}
