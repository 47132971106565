import React from 'react';
import { WebCheckbox } from 'web-components';

export default function SandboxCheckboxVariation({
  component,
  variation,
  handleChange,
}) {
  return (
    <>
      {variation.items.map((item, index) => {
        return (
          <WebCheckbox
            key={index}
            className="hot-form hot-form--custom hot-form--checkbox"
            inputProps={{
              onChange: (e) =>
                handleChange(
                  { ...item, isChecked: e.target.checked },
                  'checkbox',
                  variation.priority,
                ),
              className: 'hot-form__input',
              id: `${variation.slug}-${item.slug}`,
              name: `${component.slug}-${variation.slug}`,
            }}
            labelProps={{
              className: 'hot-form__label',
              htmlFor: `${variation.slug}-${item.slug}`,
              title: item.name,
            }}
          />
        );
      })}
    </>
  );
}
