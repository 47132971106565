import React, { Fragment } from 'react';
import { CopyPasteContainer } from 'components';

import * as S from './sandbox-code-styles';

export default function SandboxCode({
  renderedCode,
  imports,
  renderedComponent,
}) {
  return (
    <CopyPasteContainer
      iconAbsolutePosition
      content={renderedCode}
      renderToastCopiedText={false}
    >
      <S.Code>
        {imports.map((item, index) => (
          <Fragment key={index}>
            {item}
            <br />
          </Fragment>
        ))}
        <br />
        {renderedComponent}
      </S.Code>
    </CopyPasteContainer>
  );
}
