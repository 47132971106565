import React, { useEffect, useState } from 'react';
import { groupBy, removeSpecialChar } from 'utils';
import { CopyPasteContainer } from 'components';

import * as S from './sandbox-specs-styles';

export default function SandboxSpecs({ variations }) {
  const [tokens, setTokens] = useState([]);

  useEffect(() => {
    function arrangeTokens() {
      const keys = Object.keys(variations);

      if (keys.length) {
        const sortedTokens = keys
          .map((key) => variations[key])
          .sort((a, b) => a.priority - b.priority);

        const tokensFromSort = sortedTokens.reduce((acc, cur) => {
          return [...acc, ...cur.tokens];
        }, []);

        return setTokens(groupBy(tokensFromSort, 'tag'));
      }

      return setTokens([]);
    }

    arrangeTokens();
  }, [variations]);

  return (
    <>
      {Object.keys(tokens).map((key) => {
        const element = tokens[key];

        return (
          <S.Item key={key}>
            <S.Title>{removeSpecialChar(key, '-')}</S.Title>
            <S.List>
              {element.map((item) => (
                <S.ListItem key={item.name}>
                  <S.ListItemName>{item.name}</S.ListItemName>
                  {item.value.map((string, index) => (
                    <S.ListItemWrapper key={index}>
                      <CopyPasteContainer iconInlinePosition content={string}>
                        {string}
                      </CopyPasteContainer>
                    </S.ListItemWrapper>
                  ))}
                </S.ListItem>
              ))}
            </S.List>
          </S.Item>
        );
      })}
    </>
  );
}
