import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  SandBox,
  FoundationMenu,
  OtherDocumentations,
  Banner,
} from 'components';

import {
  textFieldUsageImage,
  textFieldTextAreaImage,
  textFieldHelperTextImage,
  textFieldGroupingImage,
  textFieldSizesImage,
  textFieldValidFeedbackImage,
  textFieldInvalidFeedbackImage,
} from './text-field-images';

export default function TextFieldPage(data) {
  const component = data.components.textfield;
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const titles = [
    component.usage.title,
    component.textarea.title,
    component.helper.title,
    component.grouping.title,
    component.sizes.title,
    component.feedbacks.title,
  ];

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8"> {component.title} </h1>
          <p className="_pb-7 _mb-0">{component.subhead}</p>
        </div>
      </div>

      <div className="hot-row _pt-7 _pb-7">
        <div className="hot-col">
          <SandBox slug="text-field" translates={component} />
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section id={component.usage.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.usage.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.usage.text}</p>
            <img src={textFieldUsageImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.textarea.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.textarea.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.textarea.text}</p>
            <img src={textFieldTextAreaImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.helper.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.helper.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.helper.text}</p>
            <img
              src={textFieldHelperTextImage}
              alt=""
              className="_max-w-full"
            />
          </section>

          <section id={component.grouping.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.grouping.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.grouping.text}</p>
            <img src={textFieldGroupingImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.sizes.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.sizes.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.sizes.text}</p>
            <img src={textFieldSizesImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.feedbacks.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.feedbacks.title}
            </h2>
            <p className="_mb-0 _pb-0">{component.feedbacks.text}</p>
            <article>
              <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                {component.feedbacks.invalid.title}
              </h3>
              <p className="_mb-6">{component.feedbacks.invalid.text}</p>
              <img
                src={textFieldInvalidFeedbackImage}
                alt={component.feedbacks.invalid.title}
                className="_max-w-full _mb-5"
              />
            </article>

            <article>
              <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                {component.feedbacks.valid.title}
              </h3>
              <p className="_mb-6">{component.feedbacks.valid.text}</p>
              <img
                src={textFieldValidFeedbackImage}
                alt={component.feedbacks.valid.title}
                className="_max-w-full _mb-5"
              />
            </article>
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={component.otherDocumentations} />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </div>
  );
}
