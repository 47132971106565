import React, { useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextWrapper, Section } from './intro-principles-style';
import { principleImage } from '../intro-images';
import parse from 'html-react-parser';

import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-light-svg-icons';

export default function IntroPrinciples({ content }) {
  const numberOfItems = useMemo(() => content.items.length, [content]);
  const [index, setIndex] = useState(0);

  const handleLeftClick = () => {
    setIndex((oldIndex) => {
      if (oldIndex === 0) {
        return oldIndex;
      }

      return oldIndex - 1;
    });
  };

  const handleRightClick = () => {
    setIndex((oldIndex) => {
      if (oldIndex + 1 === numberOfItems) {
        return oldIndex;
      }

      return oldIndex + 1;
    });
  };

  return (
    <>
      <Section className="_pt-7 _pb-7">
        <div className="hot-container _px-5 _px-lg-8">
          <div className="hot-row">
            <div className="hot-col-12">
              <hot-card>
                <hot-card-body class="_p-4 _p-lg-8">
                  <div className="hot-row _justify-content-between">
                    <div className="hot-col-md-5">
                      <div className="_d-flex _pb-3">
                        {content.items.map((item, i) => (
                          <div key={i}>
                            {i === index && (
                              <>
                                <h4 className="_text-1"> {item.label} </h4>
                                <h3 className="_font-weight-bold _mb-7">
                                  {item.title}
                                </h3>
                                <TextWrapper>
                                  <p className="_mb-0">{parse(item.content)}</p>
                                </TextWrapper>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="_d-flex _mt-7">
                        <button
                          className="hot-button hot-button--tertiary _mr-5"
                          onClick={handleLeftClick}
                          disabled={index === 0}
                        >
                          <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <button
                          className="hot-button hot-button--tertiary"
                          onClick={handleRightClick}
                          disabled={index === numberOfItems - 1}
                        >
                          <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                      </div>
                    </div>
                    <div className="hot-col-md-6">
                      <div className="_d-flex _align-items-center _justify-content-center">
                        <img src={principleImage} alt="" />
                      </div>
                    </div>
                  </div>
                </hot-card-body>
              </hot-card>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}
