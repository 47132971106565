import styled from 'styled-components';

export const LabelWrapper = styled.div`
  padding: 8px;
`;

export const Label = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #32363b;
  margin: 0;
`;

export const Item = styled.div`
  padding: 32px 24px 20px 24px;

  &:first-child {
    border-top: 1px solid #e6e9ed;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #e6e9ed;
  }
`;
