import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  whenUseImage,
  topImage,
  bottomImage,
  leftImage,
  rightImage,
  sizeImage,
} from './tooltip-images';

import {
  Banner,
  SandBox,
  FoundationMenu,
  OtherDocumentations,
} from 'components';

const Content = ({ data }) => {
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const [language, setLanguage] = useState({});
  const [component, setComponent] = useState({});
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (data) {
      setLanguage(data.components.tooltip);
      setComponent(data.components.tooltip);
      setMenu([
        data.components.tooltip.info.whenUse.title,
        data.components.tooltip.info.position.title,
        data.components.tooltip.info.size.title,
      ]);
    }
  }, [data]);

  return (
    <div className="hot-container _p-5 _p-lg-8">
      {!!language.card && (
        <>
          <div>
            <h1 className="_font-weight-bold _text-8"> {language.title} </h1>
            <p className="_pb-7 _mb-0">{language.subtitle}</p>
          </div>

          <div className="hot-row _pt-7 _pb-7">
            <div className="hot-col-md-3">
              <h3 className="_font-weight-bold _text-2 _mb-4">
                {language.card.interaction.title}
              </h3>
              <p className="_pr-8 _mb-0">{language.card.interaction.text}</p>
            </div>
            <div className="hot-col-md-3">
              <h3 className="_font-weight-bold _text-2 _mb-4">
                {language.card.communication.title}
              </h3>
              <p className="_pr-8 _mb-0">{language.card.communication.text}</p>
            </div>
            <div className="hot-col-md-3">
              <h3 className="_font-weight-bold _text-2 _mb-4">
                {language.card.position.title}
              </h3>
              <p className="_pr-8 _mb-0">{language.card.position.text}</p>
            </div>
          </div>

          <div className="hot-row _pt-7 _pb-7">
            <div className="hot-col">
              <SandBox slug="tooltip" translates={component} />
            </div>
          </div>

          <div className="hot-row _justify-content-between">
            <div className="hot-col-12 hot-col-sm-9">
              <section
                className="_pt-7 _pb-7"
                id={data.components.tooltip.info.whenUse.title}
              >
                <h2 className="_font-weight-bold _mb-3 _text-4">
                  {data.components.tooltip.info.whenUse.title}
                </h2>
                <p>{data.components.tooltip.info.whenUse.text}</p>
                <img
                  className="_w-full _mb-5"
                  src={whenUseImage}
                  alt="When is Use"
                />

                <hot-alert class="hot-alert--warning" role="alert">
                  <p>
                    {data.components.tooltip.info.whenUse.label}{' '}
                    <Link
                      to="/cosmos/documentation/components/popover"
                      className="hot-alert__link"
                    >
                      Popover.
                    </Link>
                  </p>
                </hot-alert>
              </section>

              <section
                className="_pt-7 _pb-7"
                id={data.components.tooltip.info.position.title}
              >
                <h2 className="_font-weight-bold _mb-3 _text-4">
                  {data.components.tooltip.info.position.title}
                </h2>
                <p className="_mb-7">
                  Temos algumas variações para ser usadas de acordo com a
                  posição do elemento alvo e o limite de espaço visível da tela
                  onde o tooltip será exibido.
                </p>
                <div className="hot-row _mt-5">
                  <div className="hot-col-md-6">
                    <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                      Top
                    </h3>
                    <p>{data.components.tooltip.info.position.top}</p>
                    <img className="_mb-5" src={topImage} alt="Position Top" />
                  </div>
                  <div className="hot-col-md-6">
                    <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                      Bottom
                    </h3>
                    <p>{data.components.tooltip.info.position.bottom}</p>
                    <img
                      className="_mb-5"
                      src={bottomImage}
                      alt="Position Bottom"
                    />
                  </div>
                </div>
                <div className="hot-row">
                  <div className="hot-col-md-6">
                    <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                      Left
                    </h3>
                    <p>{data.components.tooltip.info.position.left}</p>
                    <img
                      className="_mb-5"
                      src={leftImage}
                      alt="Position LEft"
                    />
                  </div>
                  <div className="hot-col-md-6">
                    <h3 className="_font-weight-bold _text-2 _mb-3 _pt-7">
                      Right
                    </h3>
                    <p>{data.components.tooltip.info.position.right}</p>
                    <img
                      className="_mb-5"
                      src={rightImage}
                      alt="Position Right"
                    />
                  </div>
                </div>
              </section>

              <section
                className="_pt-7 _pb-7"
                id={data.components.tooltip.info.size.title}
              >
                <h2 className="_font-weight-bold _mb-3 _text-4">
                  {data.components.tooltip.info.size.title}
                </h2>
                <p>{data.components.tooltip.info.size.text}</p>
                <img className="_w-full" src={sizeImage} alt="Size" />
              </section>

              <Banner link={bannerLink} />

              <OtherDocumentations
                documentations={language.otherDocumentations}
              />
            </div>
            <aside className="hot-col-md-2 _d-none _d-md-block">
              <FoundationMenu content={menu} />
            </aside>
          </div>
        </>
      )}
    </div>
  );
};

const TooltipPage = (data) => {
  return <Content data={data} />;
};

export default TooltipPage;
