import JsonData from './JsonSidebar';

import Alert from './Alert';
import Avatar from './Avatar';
import Breadcrumb from './Breadcrumb';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import Card from './Card';
import Checkbox from './Checkbox';
import Collapse from './Collapse';
import ContentLoader from './ContentLoader';
import DateTimePicker from './DateTimePicker';
import Dropdown from './Dropdown';
// import Embed from './Embed';
import FileUpload from './FileUpload';
import ListGroup from './ListGroup';
import Loading from './Loading';
import Modal from './Modal';
import Navigation from './Navigation';
import Pagination from './Pagination';
import Popover from './Popover';
import ProgressBar from './ProgressBar';
import Radio from './Radio';
import Range from './Range';
import Select from './Select';
import Sidebar from './Sidebar';
import Switch from './Switch';
import Table from './Table';
import Tag from './Tag';
import TextField from './TextField';
import Tooltip from './Tooltip';

const JsonComponents = [
  Alert,
  Avatar,
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Collapse,
  ContentLoader,
  DateTimePicker,
  Dropdown,
  FileUpload,
  ListGroup,
  Loading,
  Modal,
  Navigation,
  Pagination,
  Popover,
  ProgressBar,
  Radio,
  Range,
  Select,
  Sidebar,
  Switch,
  Table,
  Tag,
  TextField,
  Tooltip,
];

const JsonSidebar = JsonData;
JsonSidebar.find((data) => data['name'] === 'componentsOverview.title').items =
  JsonComponents;

export { JsonComponents, JsonSidebar };
