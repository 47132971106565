function shadowRoot(element, styles) {
  styles.forEach((style) => {
    const styled = style.path.reduce((cur, acc) => {
      return cur.shadowRoot.querySelector(acc);
    }, element);

    styled.setAttribute('style', style.style);
  });
}

export default shadowRoot;
