import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  Banner,
  FoundationMenu,
  OtherDocumentations,
  SandBox,
} from 'components';

import {
  fileUploadUsageImage,
  fileUploadSizesImage,
  fileUploadErrorImage,
  fileUploadHelperImage,
} from './file-upload-images';

export default function FileUploadPage(data) {
  const component = data.components.fileUpload;
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const titles = [
    component.usage.title,
    component.sizes.title,
    component.error.title,
    component.helper.title,
  ];

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8"> {component.title} </h1>
          <p className="_pb-7 _mb-0">{component.subhead}</p>
        </div>
      </div>

      <div className="hot-row _pt-7 _pb-7">
        <div className="hot-col">
          <SandBox slug="file-upload" translates={component} />
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section id={component.usage.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.usage.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.usage.text}</p>
            <img src={fileUploadUsageImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.sizes.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.sizes.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.sizes.text}</p>
            <img src={fileUploadSizesImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.error.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.error.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.error.text}</p>
            <img src={fileUploadErrorImage} alt="" className="_max-w-full" />
          </section>

          <section id={component.helper.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.helper.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.helper.text}</p>
            <img src={fileUploadHelperImage} alt="" className="_max-w-full" />
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={component.otherDocumentations} />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </div>
  );
}
