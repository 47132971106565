import React, { useState } from 'react';
import DeveloperPage from './iconography-use';
import DesignPage from './iconography-library';

import { useSelector } from 'react-redux';

export default function IconographyPage() {
  const { data: language } = useSelector((state) => state.language);

  const [selected, setSelected] = useState('uso');

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <h1 className="_font-weight-bold _mb-4">{language.iconography.title}</h1>
      <p className="_mb-8">{language.iconography.subtitle}</p>
      <div className="hot-row">
        <div className="hot-col-12 hot-col-sm-9">
          <hot-nav class="hot-nav--tabs _mb-8 _mt-6">
            <hot-nav-item
              class={`${selected === 'uso' ? 'hot-nav__item--active' : ''}`}
              onClick={() => setSelected('uso')}
            >
              {language.iconography.use.title}
            </hot-nav-item>
            <hot-nav-item
              class={`${selected === 'library' ? 'hot-nav__item--active' : ''}`}
              onClick={() => setSelected('library')}
            >
              {language.iconography.library.title}
            </hot-nav-item>
          </hot-nav>
        </div>
      </div>

      {selected === 'uso' && (
        <DeveloperPage developer={language.iconography.use}></DeveloperPage>
      )}

      {selected === 'library' && (
        <DesignPage design={language.iconography.library}></DesignPage>
      )}
    </div>
  );
}
