import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  Banner,
  FoundationMenu,
  OtherDocumentations,
  SandBox,
} from 'components';

import {
  contentLoaderUsageImage,
  contentLoaderVsLoadingImage,
  contentLoaderCompositionImage,
} from './content-loader-images';

export default function ContentLoaderPage(data) {
  const component = data.components.contentLoader;
  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const titles = [
    component.usage.title,
    component.contentLoaderVsLoading.title,
    component.composition.title,
  ];

  return (
    <div className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8"> {component.title} </h1>
          <p className="_pb-7 _mb-0">{component.subhead}</p>
        </div>
      </div>

      <div className="hot-row _pt-7 _pb-7">
        <div className="hot-col">
          <SandBox slug="content-loader" translates={component} />
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section id={component.usage.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.usage.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.usage.text}</p>
            <img src={contentLoaderUsageImage} alt="" className="_max-w-full" />
          </section>

          <section
            id={component.contentLoaderVsLoading.title}
            className="_pt-7 _pb-7"
          >
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.contentLoaderVsLoading.title}
            </h2>
            <p className="_mb-0 _pb-5">
              {component.contentLoaderVsLoading.text}
            </p>
            <img
              src={contentLoaderVsLoadingImage}
              alt=""
              className="_max-w-full"
            />
          </section>

          <section id={component.composition.title} className="_pt-7 _pb-7">
            <h2 className="_font-weight-bold _mb-3 _text-4">
              {component.composition.title}
            </h2>
            <p className="_mb-0 _pb-5">{component.composition.text}</p>
            <img
              src={contentLoaderCompositionImage}
              alt=""
              className="_max-w-full _mb-5"
            />
            <p className="_mb-0 _pb-5">{component.composition.secondText}</p>
            <p className="_mb-0">{component.composition.thirdText}</p>
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations documentations={component.otherDocumentations} />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </div>
  );
}
