import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Redirect, Switch, useLocation } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import * as AuthActions from 'store/modules/auth/actions';

import { bootstrap } from 'service/auth';

import { Loader } from 'components/loader';
import PrivateRoute from './private-route-component';
import PublicRoute from './public-route-component';

import HomePage from 'screens/home';
import CosmosPage from 'screens/documentation/overview/cosmos';
import DocumentationPage from 'screens/documentation/documentation';
import Error404Page from 'screens/errors/404';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function Routes() {
  const dispatch = useDispatch();

  useEffect(() => {
    bootstrap.subscribe({
      next: (auth) => {
        dispatch(AuthActions.setAuth(auth));
      },
    });
  }, [dispatch]);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Suspense fallback={<Loader />}>
        <ScrollToTop />
        <Switch>
          <PublicRoute exact path="/" component={HomePage} />
          <PublicRoute exact path="/cosmos" component={CosmosPage} />
          <PublicRoute
            exact
            path="/auth/logout"
            component={() => <Redirect to="/cosmos" />}
          />
          <PrivateRoute exact path="/auth/renew" component={Loader} />
          <PrivateRoute exact path="/auth/login" component={Loader} />
          <PrivateRoute
            path="/cosmos/documentation"
            component={DocumentationPage}
          />

          <PublicRoute path="" component={Error404Page} />
          <PublicRoute exact path="/404" component={Error404Page} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default Routes;
