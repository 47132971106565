import styled from 'styled-components';

export const Container = styled.div`
  hot-card {
    &.correct,
    &.incorrect {
      border-bottom-width: 8px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &.correct {
      border-bottom-color: var(--green);

      .tip-icon {
        background-color: var(--green);
      }
    }

    &.incorrect {
      border-bottom-color: var(--red);

      .tip-icon {
        background-color: var(--red);
      }
    }

    .card-text {
      text-align: center;
    }

    hot-card-footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .tip-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    .tip-icon {
      height: 20px;
      width: 20px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 2px;

      svg {
        width: 12px;
      }
    }

    .card-tip {
      position: relative;
      padding-left: 16px;
    }

    hot-card-body,
    hot-card-footer {
      padding: var(--spacer-5);
      text-align: center;
    }
  }
`;
