import React from 'react';
import parser from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import { Banner, FoundationMenu, OtherDocumentations } from 'components';
import { WebCard } from 'web-components';
import { Container } from './coverage-style';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';

export default function CoveragePage() {
  const { data: language } = useSelector((state) => state.language);

  const location = useLocation();
  const bannerLink = `https://hotmart1.typeform.com/to/OFJerMRf#url=${location.pathname}`;

  const titles = [
    language.coverage.practicalTips.avoidDirectionalLanguage.anchor,
    language.coverage.practicalTips.useDescriptiveLinks.anchor,
    language.coverage.practicalTips.informationHierarchy.anchor,
  ];

  return (
    <Container className="hot-container _p-5 _p-lg-8">
      <div className="hot-row">
        <div className="hot-col">
          <h1 className="_font-weight-bold _text-8">
            {' '}
            {language.coverage.title}{' '}
          </h1>
          <p className="_pb-7 _mb-0">{language.coverage.subhead}</p>
        </div>
      </div>

      <div className="hot-row _justify-content-between">
        <div className="hot-col-12 hot-col-sm-9">
          <section className="_pt-7 _pb-7">
            <div className="_mb-5 _font-weight-bold">
              {language.coverage.beforeEverything.title}
            </div>
            <p className="_mb-0">
              {parser(language.coverage.beforeEverything.text)}
            </p>
          </section>

          <section className="_pt-7 _pb-7">
            <div className="_mb-6">
              <h2 className="_font-weight-bold _mb-8 _text-4">
                {language.coverage.practicalTips.title}
              </h2>
            </div>
            <p
              className="_mb-5 _font-weight-bold"
              id={
                language.coverage.practicalTips.avoidDirectionalLanguage.anchor
              }
            >
              {language.coverage.practicalTips.avoidDirectionalLanguage.title}
            </p>
            <p className="_mb-5">
              {
                language.coverage.practicalTips.avoidDirectionalLanguage
                  .firstText
              }
            </p>
            <p className="_mb-5">
              {
                language.coverage.practicalTips.avoidDirectionalLanguage
                  .secondText
              }
            </p>
            <p className="_mb-5">
              {
                language.coverage.practicalTips.avoidDirectionalLanguage
                  .thirdText
              }
            </p>
            <div className="_mb-8">
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {parser(
                          language.coverage.practicalTips
                            .avoidDirectionalLanguage.correct.text,
                        )}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold _mb-0">
                          {
                            language.coverage.practicalTips
                              .avoidDirectionalLanguage.correct.tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {parser(
                          language.coverage.practicalTips
                            .avoidDirectionalLanguage.incorrect.text,
                        )}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold _mb-0">
                          {
                            language.coverage.practicalTips
                              .avoidDirectionalLanguage.incorrect.tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
            <p
              className="_mb-5 _font-weight-bold"
              id={language.coverage.practicalTips.useDescriptiveLinks.anchor}
            >
              {language.coverage.practicalTips.useDescriptiveLinks.title}
            </p>
            <p className="_mb-5">
              {language.coverage.practicalTips.useDescriptiveLinks.firstText}
            </p>
            <div className="_mb-8">
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {parser(
                          language.coverage.practicalTips.useDescriptiveLinks
                            .correct.text,
                        )}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold _mb-0">
                          {
                            language.coverage.practicalTips.useDescriptiveLinks
                              .correct.tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {parser(
                          language.coverage.practicalTips.useDescriptiveLinks
                            .incorrect.text,
                        )}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold _mb-0">
                          {
                            language.coverage.practicalTips.useDescriptiveLinks
                              .incorrect.tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
            <p
              className="_mb-5 _font-weight-bold"
              id={language.coverage.practicalTips.informationHierarchy.anchor}
            >
              {language.coverage.practicalTips.informationHierarchy.title}
            </p>
            <p className="_mb-5">
              {language.coverage.practicalTips.informationHierarchy.firstText}
            </p>
            <p className="_mb-5">
              {language.coverage.practicalTips.informationHierarchy.secondText}
            </p>
            <p className="_mb-5">
              {language.coverage.practicalTips.informationHierarchy.thirdText}
            </p>
            <div className="_mb-0">
              <div className="hot-row">
                <div className="hot-col-lg-6">
                  <WebCard className="correct _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {parser(
                          language.coverage.practicalTips.informationHierarchy
                            .correct.text,
                        )}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-green-dark _font-weight-bold _mb-0">
                          {
                            language.coverage.practicalTips.informationHierarchy
                              .correct.tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
                <div className="hot-col-lg-6">
                  <WebCard className="incorrect _h-full">
                    <hot-card-header />
                    <hot-card-body>
                      <p className="_text-2 _text-center">
                        {parser(
                          language.coverage.practicalTips.informationHierarchy
                            .incorrect.text,
                        )}
                      </p>
                    </hot-card-body>
                    <hot-card-footer>
                      <div className="tip-wrapper">
                        <div className="tip-icon">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="_text-white"
                          />
                        </div>
                        <p className="card-tip _text-2 _text-red-dark _font-weight-bold _mb-0">
                          {
                            language.coverage.practicalTips.informationHierarchy
                              .incorrect.tip
                          }
                        </p>
                      </div>
                    </hot-card-footer>
                  </WebCard>
                </div>
              </div>
            </div>
          </section>

          <Banner link={bannerLink} />

          <OtherDocumentations
            documentations={language.coverage.otherDocumentations}
          />
        </div>
        <aside className="hot-col-md-2 _d-none _d-md-block">
          <FoundationMenu content={titles} />
        </aside>
      </div>
    </Container>
  );
}
