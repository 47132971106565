import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faConstruction } from '@fortawesome/pro-solid-svg-icons';

function Construction({ className = '' }) {
  return (
    <div
      className={`_d-flex _flex-column _align-items-center _justify-content-between _mt-8 ${className}`}
    >
      <div style={{ maxWidth: '320px', textAlign: 'center' }}>
        <FontAwesomeIcon
          icon={faConstruction}
          className="hot-display-1 _text-gray-300 _mb-5"
        />
        <h4 className="_text-center">
          Sem documentação de design, por enquanto
        </h4>
        <p className="_text-center _text-gray-500 _mb-8">
          Estamos trabalhando para disponibilizar esta documentação o mais breve
          possível{' '}
          <span aria-label="emoji-hang-loose" role="img">
            🤙
          </span>
        </p>
      </div>
    </div>
  );
}

export default Construction;
