import React from 'react';
import { useSelector } from 'react-redux';
import { ImageWrapper } from './404-style';

import image404 from 'assets/images/errors/404/404.svg';

export default function Error404Page() {
  const { data: language } = useSelector((state) => state.language);

  return (
    <>
      <div className="_d-flex _min-vh-full _w-full _flex-column _bg-black">
        <div className="_p-7">
          <div className="hot-container-fluid _px-0 _px-lg-7">
            <div className="hot-row _d-flex _align-items-center">
              <div className="hot-col">
                <p className="_mb-8">
                  <svg
                    width="35"
                    height="47"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.424 37.882c-4.48-.036-8.082-3.596-8.045-7.953.037-4.356 3.699-7.859 8.179-7.823 4.48.036 8.082 3.597 8.046 7.953-.037 4.356-3.7 7.859-8.18 7.823zm17.555-7.9c.22-4.19-1.257-11.046-4.873-16.651-.096-.146-.26-.088-.224.066.24.854.299 2.398-1.098 2.306-2.475-.161.086-5.311-5.35-8.884-.108-.072-.238.024-.179.138.373.689.594 2.837-.267 3.569-.69.587-1.959.43-3.178-1.505-2.032-3.224-1.26-6.67.12-8.823.104-.161-.038-.225-.157-.187-7.477 2.4-8.97 10.968-10.561 14.093-.267.524-.499.798-.955.768-1.362-.089-.38-2.914.09-3.897.067-.139-.097-.215-.185-.126C4.312 14.761.828 21.421.165 27.61c.022-.146-.061.512-.102.993v.002A17.11 17.11 0 000 29.855c-.079 9.39 7.688 17.067 17.347 17.144 9.66.078 17.555-7.473 17.635-16.865 0-.051-.003-.102-.003-.152z"
                      fill="#ffffff"
                    />
                  </svg>
                </p>
              </div>
            </div>
          </div>
          <div className="hot-container _flex-1 _px-0 _px-lg-7">
            <div className="_d-flex _flex-1 _align-items-center _justify-content-center">
              <div className="_p-5 _p-lg-8">
                <div className="hot-row">
                  <div className="hot-col-12">
                    <div className="_d-flex _align-items-cente _justify-content-center">
                      <ImageWrapper>
                        <img
                          src={image404}
                          alt="404"
                          className="_max-w-full _mb-8"
                        />
                      </ImageWrapper>
                    </div>
                  </div>
                  <div className="hot-col-12">
                    <h1 className="_text-4 _text-white _text-center">
                      {language.errors.notFound.title}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
